import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CommonDialog from "./CommonDialog";
import { format } from "date-fns";
import { deleteLab } from "../services/labService";

function LabDataTable({ rows, fromLabPage, labs, setLabs }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const dropdownRef = useRef(null); // Ref for detecting clicks outside
  const [rowsPerPage] = useState(rows);
  const [page, setPage] = useState(0);
  const [activeRow, setActiveRow] = useState(null); // Track the active row for the dropdown
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null); // Track hovered row index for the hover effect
  const [selectedLabId, setSelectedLabId] = useState(null);

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
  };

  const totalPages = Math.ceil(labs?.length / rowsPerPage);

  const handleActionSelect = (labId) => {
    setActiveRow(null); // Close the dropdown after selection
    setSelectedLabId(labId);
    handleOpenDialog();
  };

  const toggleActionDropdown = (rowIndex) => {
    setActiveRow((prevActiveRow) =>
      prevActiveRow === rowIndex ? null : rowIndex
    ); // Toggle dropdown for the clicked row
  };

  const getStatusStyle = (subscription) => {
    if (subscription === "Advance") {
      return {
        backgroundColor: theme.palette.mode === "light" ? "#E5FFEB" : "#2A2D3E",
        // backgroundColor: "#E5FFEB",
        color: "#28C76F",
        padding: "7px 15px",
        borderRadius: "6px",
      };
    } else if (subscription === "Premium") {
      return {
        backgroundColor: theme.palette.mode === "light" ? "#FFEDED" : "#2A2D3E",
        color: "#FF4C51",
        padding: "7px 15px",
        borderRadius: "6px",
      };
    } else {
      return {
        backgroundColor: theme.palette.mode === "light" ? "#DFFFFF" : "#2A2D3E",
        color: "#00BAD1",
        padding: "7px 15px",
        borderRadius: "6px",
      };
    }
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setActiveRow(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleRowClick = (lab) => {
    navigate(`/lab-details/${lab.lab_id}`, { state: { lab } });
  };

  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonLabel, setButtonLabel] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    // Set the dialog title and content dynamically based on form data
    setDialogTitle("Sure You Want To Delete Account?");
    setDialogContent(
      "Et leo, enim in non sed quis sed. Auctor natoque auctor risus amet quis mauris. Interdum et nisi, pellentesque id lectus. Ut bibendum pellentesque arcu luctus sapien."
    );
    setButtonLabel("Yes");
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = async () => {
    try {
      const response = await deleteLab(selectedLabId);
      if (response.message === "Lab deleted successfully.") {
        setLabs((prevLabs) =>
          prevLabs.filter((lab) => lab.lab_id !== selectedLabId)
        );
        setSelectedLabId(null);
      }
    } catch (error) {
      console.error("Error deleting report:", error);
    }
    setDialogOpen(false);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd MMM yyyy");
  };

  return (
    <div className="rounded-2xl">
      <TableContainer
        sx={{
          backgroundColor: theme.palette.mode === "light" ? "white" : "#1A1A1A",
          paddingTop: fromLabPage ? "15px" : undefined,
          borderTopLeftRadius: fromLabPage ? "16px" : undefined,
          borderTopRightRadius: fromLabPage ? "16px" : undefined,
          borderBottomLeftRadius: fromLabPage ? undefined : "10px",
          borderBottomRightRadius: fromLabPage ? undefined : "10px",
          minHeight: fromLabPage ? "450px" : "200px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>LAB ID</TableCell>
              <TableCell>LABORATORY NAME</TableCell>
              <TableCell>REPORTS</TableCell>
              <TableCell>USERS</TableCell>
              <TableCell>DOJ</TableCell>
              <TableCell>SUBSCRIPTION</TableCell>
              <TableCell>LOCATION</TableCell>
              <TableCell>ACTION</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(labs) && labs.length > 0 ? (
              labs
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((lab, index) => (
                  <TableRow
                    key={lab.lab_id}
                    onClick={() => handleRowClick(lab)}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell>{lab.lab_id}</TableCell>
                    <TableCell
                      sx={{
                        fontWeight: "500",
                        fontSize: { xxs: "14px", md: "16px" },
                      }}
                    >
                      {lab.lab_name}
                    </TableCell>
                    <TableCell>{lab.report_count}</TableCell>
                    <TableCell>{lab.user_count}</TableCell>
                    <TableCell>
                      {lab.created_on ? formatDate(lab.created_on) : "-"}
                    </TableCell>
                    <TableCell>
                      <span style={getStatusStyle(lab.subscription_plan)}>
                        {lab.subscription_plan}
                      </span>
                    </TableCell>
                    <TableCell>{lab.lab_location}</TableCell>
                    <TableCell
                      onClick={(event) => {
                        event.stopPropagation(); // Prevent row click event
                      }}
                    >
                      <div className="relative m-0 p-0">
                        <button
                          className="p-2 ml-2 w-10 rounded-full"
                          onClick={(event) => {
                            event.stopPropagation(); // Prevent row click event
                            toggleActionDropdown(index);
                          }}
                          onMouseEnter={() => setHoveredRowIndex(index)}
                          onMouseLeave={() => setHoveredRowIndex(null)}
                          style={{
                            backgroundColor:
                              hoveredRowIndex === index
                                ? theme.palette.mode === "light"
                                  ? "#F5F5F5"
                                  : "#2A2D3E"
                                : "transparent",
                          }}
                        >
                          <MoreVertIcon
                            sx={{ fontSize: "18px", color: "#666666" }}
                          />
                        </button>

                        {activeRow === index && ( // Render dropdown only for active row
                          <div
                            ref={dropdownRef} // Reference for outside click detection
                            className="absolute top-full -left-10 z-10 mt-0.5 bg-[var(--color-white)] w-20 rounded-[10px]"
                            style={{
                              border: "1px solid #E7E7E7",
                              boxShadow: "0px 4px 16px 0px #00000029",
                            }}
                          >
                            <p
                              className={`cursor-pointer small-text m-0 p-2 hover:bg-grey2 dark:hover:bg-white-light text-center rounded-[10px]`}
                              onClick={(event) => {
                                event.stopPropagation(); // Prevent row click
                                handleActionSelect(lab.lab_id);
                              }}
                            >
                              Delete
                            </p>
                          </div>
                        )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell colSpan={8} style={{ textAlign: "center" }}>
                  No labs available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* MUI Pagination */}
      {fromLabPage && labs?.length > 10 && (
        <div className="flex xxs:flex-col md:flex-row xxs:space-y-4 md:space-y-0 items-center justify-between bg-[var(--color-white)] mt-4 py-5 px-4">
          <span className="text-[var(--color-dark-grey)] text-sm font-medium">
            Showing {page * rowsPerPage + 1} to{" "}
            {Math.min((page + 1) * rowsPerPage, labs?.length)} of {labs?.length}{" "}
            entries
          </span>
          <Pagination
            count={totalPages}
            page={Math.min(page + 1, totalPages || 1)}
            onChange={handlePageChange}
            siblingCount={1}
            sx={{
              "& .MuiPaginationItem-root": {
                backgroundColor:
                  theme.palette.mode === "light"
                    ? "#D9D9D94D" // Light mode background
                    : "#2A2D3E", // Dark mode background
                borderRadius: "4px",
                color:
                  theme.palette.mode === "light"
                    ? "#5F6177" // Light mode text color
                    : "#A1A1B1", // Dark mode text color
                fontWeight: "600",
                width: "36px", // Set a fixed width for consistency
                height: "36px", // Set a fixed height for consistency
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "4px 4px",
              },
              "& .MuiPaginationItem-root.Mui-selected": {
                backgroundColor: "#513ADC",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? "#513ADC" // Light mode selected hover
                      : "#836FFF", // Dark mode selected hover
                },
              },
              "& .MuiPaginationItem-root.Mui-disabled": {
                backgroundColor:
                  theme.palette.mode === "light"
                    ? "#D9D9D94D" // Light mode disabled background
                    : "#1E1E2F", // Dark mode disabled background
                color:
                  theme.palette.mode === "light"
                    ? "#B9B9B9" // Light mode disabled text
                    : "#5F6177", // Dark mode disabled text
              },
            }}
          />
        </div>
      )}

      <CommonDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={dialogTitle}
        content={dialogContent}
        buttonLabel={buttonLabel}
        showDeleteIcon={true}
        onConfirm={handleConfirm}
      />
    </div>
  );
}

export default LabDataTable;
