import React from "react";
import MailIcon from "@mui/icons-material/Mail";

const NewReports = ({ latest_reports }) => {
  const handleMailClick = (email) => {
    window.location.href = `mailto:${email}`;
  };

  return (
    <div
      className="bg-[var(--color-white)] p-4 rounded-[10px] w-full h-full"
      style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
    >
      {/* Header and Dropdown */}
      <div className="mb-4 p-2">
        <span className="text-lg font-medium text-blue-text">
          New Reports Generated
        </span>
      </div>

      <div className="flex flex-col space-y-4">
        {latest_reports && latest_reports.length > 0 ? (
          latest_reports.map((report, index) => (
            <div
              key={index}
              className="flex justify-between items-center space-x-2"
            >
              <div className="flex space-x-2">
                <div className="ml-2">
                  <span className="text-lg text-[var(--color-text1)]">
                    {report.patient_name}
                  </span>
                  <p className="text-sm text-blue-text">{report.report_type}</p>
                </div>
              </div>
              <div
                className="space-x-2 p-2 rounded-full bg-[#F5F5F5] dark:bg-white-light cursor-pointer"
                onClick={() => handleMailClick(report.email)}
              >
                <MailIcon sx={{ color: "#FCAA5C" }} />
              </div>
            </div>
          ))
        ) : (
          <p>No new reports available</p>
        )}
      </div>
    </div>
  );
};

export default NewReports;
