import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Select, MenuItem, FormControl } from "@mui/material";
import {
  ChevronDownIcon,
  CheckIcon,
} from "@heroicons/react/24/outline";
import { TextField } from "@mui/material";

const Dropdown = ({
  id,
  label,
  showLabel,
  required,
  options,
  selectedOption,
  onChange,
  showFilter,
}) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false); // Local state to manage dropdown state
  const [filterText, setFilterText] = useState(""); // State for filtering options

  // Filtered options based on filter text
  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleFilterChange = (e) => {
    const input = e.target.value;
    setFilterText(input);
  };

  // Custom IconComponent to handle props correctly
  const CustomIcon = ({ isOpen, className, ...props }) => {
    return (
      <ChevronDownIcon
        {...props}
        className={`${className} h-5 w-5 mr-1 text-[var(--color-dark-grey)] transition-transform duration-200 ${
          isOpen ? "transform rotate-180" : ""
        }`}
        strokeWidth={2.5}
      />
    );
  };

  return (
    <FormControl variant="outlined" fullWidth>
      {showLabel && (
        <label
          className="block text-[var(--color-dark-grey)] mb-1 small-text"
          htmlFor={id}
        >
          {label} {required && <span style={{ color: "#FF1F00" }}>*</span>}
        </label>
      )}
      <Select
        id={id}
        value={selectedOption ? selectedOption.value : ""}
        onChange={(event) => {
          const selected = options.find(
            (option) => option.value === event.target.value
          );
          onChange(selected);
        }}
        onOpen={() => setIsOpen(true)}
        onClose={() => setIsOpen(false)}
        size="small"
        IconComponent={(props) => <CustomIcon {...props} isOpen={isOpen} className={props.className} />}
        displayEmpty
        renderValue={(value) =>
          value ? (
            <span className="medium-text text-[var(--color-text1)]">
              {selectedOption.label}
            </span>
          ) : (
            <span className="medium-text text-grey">Select {label}</span>
          )
        }
        sx={{
          backgroundColor:
            theme.palette.mode === "dark" ? "transparent" : "white",
          borderRadius: "8px",
          "& .MuiOutlinedInput-notchedOutline": {
            border: isOpen ? "none" : "1px solid #5F6177",
            borderRadius: "8px",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline":
            theme.palette.mode === "light"
              ? {
                  border: isOpen ? "none" : "1px solid #5F6177",
                  borderBottom: isOpen
                    ? "1px solid #5F6177"
                    : "1px solid #000000",
                  transition: "border 0.15s ease-out",
                }
              : {
                  border: isOpen ? "none" : "1px solid #5F6177 !important",
                  borderBottom: isOpen
                    ? "1px solid #5F6177"
                    : "1px solid #B9B9B9",
                  transition: "border 0.15s ease-out",
                },
        }}
        MenuProps={{
          PaperProps: {
            className: isOpen ? "dropdown-menu-open" : "dropdown-menu-closed",
            style: {
              border: "1px solid #737373",
              borderRadius: "8px",
              marginTop: "8px",
              boxShadow: "none",
            },
          },
        }}
      >
        {/* Filter text field */}
        {showFilter && (
          <div className="flex">
            <TextField
              placeholder="Find a Report"
              value={filterText}
              onChange={handleFilterChange}
              onClick={(event) => event.stopPropagation()}
              onKeyDown={(event) => event.stopPropagation()}
              size="small"
              fullWidth
              sx={{
                p: 0.5,
                mt: 0,
                borderRadius: "8px !important",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "none",
                    borderBottom: "1px solid #E6E6E6",
                    borderRadius: "8px !important",
                  },
                  "&:hover fieldset": {
                    background: "transparent", // Keep the background transparent on hover
                    border: "none",
                    borderBottom: "1px solid #E6E6E6",
                    borderRadius: "8px !important",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid #999999",
                    borderRadius: "8px !important",
                  },
                  "& input": {
                    background: "transparent", // Ensure input background stays transparent
                  },
                },
                "& .MuiInputBase-root": theme.palette.mode === "light" && {
                  "&:hover": {
                    backgroundColor: "#F9F9F9", // Background color on hover
                  },
                  "&.Mui-focused": theme.palette.mode === "light" && {
                    backgroundColor: "#F9F9F9", // Background color on focus
                    borderRadius: "8px !important",
                  },
                },
              }}
            />
          </div>
        )}

        {/* Render filtered options */}
        {filteredOptions.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            className="small-text"
            sx={{
              display: "flex",
              justifyContent: "space-between",
              m: 0.5,
              mb: 0,
              borderRadius: "8px",
              backgroundColor: "transparent !important",
              "&:hover": {
                backgroundColor:
                  theme.palette.mode === "light"
                    ? "#F6F2FF !important" // Light mode hover color
                    : "#333649 !important", // Dark mode hover color: muted dark background
              },
              "&.Mui-selected": {
                fontWeight: "bold",
                backgroundColor:
                  theme.palette.mode === "light"
                    ? "#EAEEFF !important" // Light mode selected color
                    : "#494D66 !important", // Dark mode selected color: slightly highlighted dark
                outline:
                  theme.palette.mode === "light"
                    ? "1px solid #513ADC" // Light mode outline
                    : "1px solid #7C81A3", // Dark mode outline: subdued highlight
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? "#EAEEFF !important" // Keep selected hover color in light mode
                      : "#494D66 !important", // Dark mode selected hover color: richer highlight
                  outline: "none",
                },
              },
            }}
          >
            {option.label}
            {selectedOption?.value === option.value && (
              <CheckIcon className="w-5 h-5 text-[#2F1073]" />
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default Dropdown;
