import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import ProtectedRoute from "./components/auth/ProtectedRoute";
import AuthPage from "./pages/AuthPage";
import LoginForm from "./components/auth/LoginForm";
import RegisterForm from "./components/auth/RegisterForm";
import OtpForm from "./components/auth/OtpForm";
import ForgotPasswordForm from "./components/auth/ForgotPasswordForm";
import ResetPasswordForm from "./components/auth/ResetPasswordForm";
import SubscriptionPage from "./pages/SubscriptionPage";
import DashboardPage from "./pages/DashboardPage";
import DashboardPageAdmin from "./pages/DashboardPageAdmin";
import Layout from "./layouts/Layout";
import NotificationsPage from "./pages/NotificationPage";
import CreateReportPage from "./pages/CreateReportPage";
import EditReportPage from "./pages/EditReportPage";
import ReportManagementPage from "./pages/ReportManagementPage";
import ReportDetailsPage from "./pages/ReportDetailsPage";
import UserManagementPage from "./pages/UserManagementPage";
import UserDetailsPage from "./pages/UserDetailsPage";
import EditUserPage from "./pages/EditUserPage";
import SupportPage from "./pages/SupportPage";
import SettingsPage from "./pages/SettingsPage";
import PersonalDetails from "./components/settings/PersonalDetails";
import Security from "./components/settings/Security";
import BillingPlans from "./components/settings/BillingPlans";
import General from "./components/settings/General";
import Notification from "./components/settings/Notifications";
import ReportTemplates from "./components/settings/ReportTemplates";
import LabManagementPage from "./pages/LabManagementPage";
import LabDetailsPage from "./pages/LabDetailsPage";
import { UserContext } from "./contexts/UserContext";
import { LabContext } from "./contexts/LabContext";
import { useSettings, SettingsContext } from "./contexts/SettingsContext";
import { getProfile, getSettings } from "./services/userService";
import { logoutUser } from "./services/authService";
import { toast } from "react-toastify";

function App() {
  const { setUser } = useContext(UserContext);
  const { setLab } = useContext(LabContext);
  const { settings } = useSettings();
  const { setSettings } = useContext(SettingsContext);
  // State to manage dark mode
  const [isDarkMode, setIsDarkMode] = useState(false);

  const lightTheme = createTheme({
    palette: {
      mode: "light",
      primary: { main: "#513ADC" },
      error: { main: "#F13C3C" },
    },
    breakpoints: {
      values: {
        xxs: 340,
        xs: 480,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        xxl: 1536,
      },
    },
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: "#E7EAF5",
            "& .MuiTableCell-root": {
              color: "#333333",
              fontWeight: "medium",
              fontSize: "16px",
            },
          },
        },
      },
    },
  });

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: { main: "#513adc" },
      error: { main: "#F13C3C" },
    },
    breakpoints: {
      values: {
        xxs: 340,
        xs: 480,
        sm: 640,
        md: 768,
        lg: 1024,
        xl: 1280,
        xxl: 1536,
      },
    },
    components: {
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: "#2A2D3E",
            "& .MuiTableCell-root": {
              fontWeight: "medium",
              fontSize: "16px",
            },
          },
        },
      },
      MuiTableBody: {
        styleOverrides: {
          root: {
            "& .MuiTableCell-root": {
              fontSize: "16px",
            },
          },
        },
      },
    },
  });

  // Function to toggle dark mode
  const toggleDarkMode = () => {
    const newDarkMode = !isDarkMode;
    setIsDarkMode(newDarkMode);
    document.documentElement.classList.toggle("dark", newDarkMode); // Tailwind dark class
  };

  useEffect(() => {
    const fetchUser = async () => {
      const authToken =
        localStorage.getItem("authToken") ||
        sessionStorage.getItem("authToken");

      if (!authToken) return; // Don't fetch user if no authToken

      try {
        const userData = await getProfile();
        setUser(userData.user);
        setLab(userData.lab);
      } catch (error) {
        console.error(error);
        // Handle token expiration or errors
        if (error?.message === "Invalid or expired token.") {
          localStorage.removeItem("authToken");
          sessionStorage.removeItem("authToken");
          toast.error("Session expired. Please log in again.");
        }
      }
    };

    fetchUser();
  }, [setUser, setLab]);

  const fetchSettings = useCallback(async () => {
    const authToken =
      localStorage.getItem("authToken") || sessionStorage.getItem("authToken");

    if (!authToken) return;

    try {
      const response = await getSettings();
      setSettings(response.settings);
    } catch (error) {
      throw error.response.data;
    }
  }, [setSettings]);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  useEffect(() => {
    if (settings) {
      setIsDarkMode(settings?.theme === "dark");
      document.documentElement.classList.toggle(
        "dark",
        settings?.theme === "dark"
      );
      localStorage.setItem("isDarkMode", settings?.theme === "dark");
    }
  }, [settings]);

  const handleLogout = () => {
    logoutUser(); // Clears tokens and localStorage
    setSettings(null); // Reset settings to prevent re-triggering useEffect
    setIsDarkMode(false); // Reset dark mode state
    document.documentElement.classList.remove("dark");
  };

  return (
    <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        closeOnClick
        pauseOnHover
      />
      <Router>
        <Routes>
          {/* Redirect from root to login */}
          <Route path="/" element={<Navigate to="/login" />} />

          {/* Auth Routes */}
          <Route path="/" element={<AuthPage />}>
            <Route path="login" element={<LoginForm />} />
            <Route path="register" element={<RegisterForm />} />
            <Route path="forgot-password" element={<ForgotPasswordForm />} />
            <Route element={<ProtectedRoute />}>
              <Route path="verify-otp" element={<OtpForm />} />
              <Route path="reset-password" element={<ResetPasswordForm />} />
            </Route>
          </Route>

          {/* Subscription Route */}
          <Route
            path="/subscription/*"
            element={
              // <ProtectedRoute>
              <SubscriptionPage />
              // </ProtectedRoute>
            }
          />

          <Route element={<ProtectedRoute />}>
            {/* Dashboard Route wrapped inside Layout */}
            <Route
              path="/"
              element={
                <Layout
                  toggleDarkMode={toggleDarkMode}
                  isDarkMode={isDarkMode}
                  handleLogout={handleLogout}
                />
              }
            >
              <Route path="dashboard" element={<DashboardPage />} />
              <Route path="admin-dashboard" element={<DashboardPageAdmin />} />
              <Route path="notifications" element={<NotificationsPage />} />
              <Route
                path="edit-report/:reportId"
                element={<EditReportPage />}
              />

              {/* Stepper Routes inside Create Report */}
              <Route path="create-report/*" element={<CreateReportPage />} />

              <Route
                path="report-management"
                element={<ReportManagementPage />}
              />

              <Route
                path="report-details/:reportId"
                element={<ReportDetailsPage />}
              />

              <Route path="user-management" element={<UserManagementPage />} />
              <Route
                path="user-details/:userId"
                element={<UserDetailsPage />}
              />
              <Route path="edit-user/:userId" element={<EditUserPage />} />

              <Route path="support" element={<SupportPage />} />

              <Route path="settings/*" element={<SettingsPage />}>
                <Route path="personal-details" element={<PersonalDetails />} />
                <Route path="security" element={<Security />} />
                <Route path="billing-plans" element={<BillingPlans />} />
                <Route
                  path="general"
                  element={
                    <General
                      toggleDarkMode={toggleDarkMode}
                      isDarkMode={isDarkMode}
                      settings={settings}
                    />
                  }
                />
                <Route
                  path="notification"
                  element={<Notification settings={settings} />}
                />
                <Route path="report-templates" element={<ReportTemplates />} />
              </Route>

              <Route path="lab-management" element={<LabManagementPage />} />
              <Route path="lab-details/:labId" element={<LabDetailsPage />} />
            </Route>
          </Route>
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
