import React, { useState, useEffect, useContext } from "react";
import TextInput from "../../shared/TextInput";
import PrimaryButton from "../../shared/PrimaryButton";
import Checkbox from "../../shared/Checkbox";
import { ReactComponent as ClappingHands } from "../../assets/noto_clapping-hands.svg";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../../services/authService"; // Import the loginUser function
import { UserContext } from "../../contexts/UserContext";
import { LabContext } from "../../contexts/LabContext";
import { SettingsContext } from "../../contexts/SettingsContext";
import { getProfile, getSettings } from "../../services/userService";

const LoginForm = () => {
  const { setUser } = useContext(UserContext);
  const { setLab } = useContext(LabContext);
  const { setSettings } = useContext(SettingsContext);
  const navigate = useNavigate();

  // State for email, password, and rememberMe
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  // On component mount, check for saved email in localStorage
  useEffect(() => {
    const savedEmail = localStorage.getItem("rememberedEmail");
    if (savedEmail) {
      setEmail(savedEmail);
      setRememberMe(true);
    }
  }, []);

  // Handle login form submission
  const handleLogin = async (e) => {
    e.preventDefault();

    // Prepare login data
    const credentials = {
      email,
      password,
    };

    try {
      // Call the loginUser API function
      const data = await loginUser(credentials);

      if (data) {
        // Successful login
        const { user, authToken } = data;

        if (user.role === "Admin") {
          navigate("/admin-dashboard");
        } else {
          navigate("/dashboard");
        }

        // Save token and email if "Remember Me" is checked
        if (rememberMe) {
          localStorage.setItem("authToken", authToken);
          localStorage.setItem("rememberedEmail", email);
        } else {
          localStorage.setItem("authToken", authToken);
          sessionStorage.setItem("authToken", authToken);
        }

        const userData = await getProfile();
        setUser(userData.user);
        setLab(userData.lab);

        const settingsData = await getSettings();
        setSettings(settingsData.settings);
      }
    } catch (error) {
      if (error.message === "Subscription required.") {
        // Redirect to subscription page if the user lacks a subscription
        const authToken = error.authToken;
        localStorage.setItem("authToken", authToken);
        navigate("/subscription/plans");
      } else {
        console.error(error);
      }
    }
  };

  // Handle checkbox change for remember me
  const handleCheckboxChange = () => {
    setRememberMe(!rememberMe);
  };

  return (
    <div
      className="bg-[var(--color-white)] rounded-[20px] padding-responsive"
      style={{ boxShadow: "0px 9px 34px 0px #0000001A" }}
    >
      <form onSubmit={handleLogin} className="px-2">
        <div className="flex items-center mt-4">
          <span className="pr-2 mb-1 text-[var(--color-dark-grey)] font-heading large-text font-semibold">
            Welcome to Emilio Muse!
          </span>
          <ClappingHands className="-mt-2" />
        </div>
        <div className="text-grey small-text mb-8">
          Please login to your account
        </div>

        {/* Email Input */}
        <TextInput
          id="email"
          type="email"
          label="Email"
          placeholder="Enter Email"
          className="mb-5"
          value={email} // Bind state to input value
          onChange={(e) => setEmail(e.target.value)} // Update email state
        />

        {/* Password Input */}
        <TextInput
          id="password"
          type="password"
          label="Password"
          placeholder="Enter Password"
          className="mb-2"
          value={password} // Bind state to input value
          onChange={(e) => setPassword(e.target.value)} // Update password state
        />

        <div className="flex justify-between items-center mb-10">
          {/* Remember me Checkbox */}
          <Checkbox
            id="rememberMe"
            label="Remember me"
            checked={rememberMe}
            size="small"
            onChange={handleCheckboxChange}
          />
          <button
            type="button"
            className="text-blue small-text"
            onClick={() => navigate("/forgot-password")}
          >
            Forgot Password?
          </button>
        </div>

        {/* Login Button */}
        <PrimaryButton type="submit" label="Login" className="w-full" />

        <div className="mt-10 mb-4 text-center medium-text">
          <span className="text-grey">Don't have an account?</span>{" "}
          <button
            type="button"
            className="font-medium"
            onClick={() => navigate("/register")}
          >
            Create Account
          </button>
        </div>
      </form>
    </div>
  );
};

export default LoginForm;
