import React, { useState, useEffect, useCallback } from "react";
// import { ReactComponent as EditIcon } from "../../assets/edit.svg";
// import { useNavigate } from "react-router-dom";
import ReactDOMServer from "react-dom/server";

const SubmitStep = ({ reportDetails, setReportDetails }) => {
  // const navigate = useNavigate();
  const [editorContent, setEditorContent] = useState("");

  // Function to replace placeholders in template with actual data
  const replaceTemplatePlaceholders = useCallback((reportDetails) => {
    const calculateAge = (dob) => {
      const birthDate = new Date(dob);
      const today = new Date();

      // Calculate difference in milliseconds
      const diffInMs = today - birthDate;

      // Convert to respective units
      const minutes = Math.floor(diffInMs / (1000 * 60));
      const hours = Math.floor(diffInMs / (1000 * 60 * 60));
      const days = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
      const months =
        today.getMonth() -
        birthDate.getMonth() +
        12 * (today.getFullYear() - birthDate.getFullYear());
      const years = Math.floor(months / 12);

      // Return the most relevant unit
      if (years > 0) {
        return `${years} year${years > 1 ? "s" : ""}`;
      } else if (months > 0) {
        return `${months} month${months > 1 ? "s" : ""}`;
      } else if (days > 0) {
        return `${days} day${days > 1 ? "s" : ""}`;
      } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? "s" : ""}`;
      } else {
        return `${minutes} minute${minutes > 1 ? "s" : ""}`;
      }
    };

    const age = reportDetails?.dob ? calculateAge(reportDetails.dob) : "N/A";

    let updatedTemplate = reportDetails.templateContent;

    updatedTemplate = updatedTemplate
      .replace(
        'Patient Name:</span> <span class="text-sm font-medium text-text1">----</span>',
        `Patient Name:</span> <span class="text-sm font-medium text-text1">${
          reportDetails.firstName || "N/A"
        } ${reportDetails.lastName || "N/A"}</span>`
      )
      .replace(
        'Contact:</span> <span class="text-sm font-medium text-text1">----</span>',
        `Contact:</span> <span class="text-sm font-medium text-text1">${
          reportDetails.contactNumber || "N/A"
        }</span>`
      )
      .replace(
        'Gender:</span> <span class="text-sm font-medium text-text1">----</span>',
        `Gender:</span> <span class="text-sm font-medium text-text1">${
          reportDetails.gender.value || "N/A"
        }</span>`
      )
      .replace(
        'Age:</span> <span class="text-sm font-medium text-text1">----</span>',
        `Age:</span> <span class="text-sm font-medium text-text1">${
          age || "N/A"
        }</span>`
      )
      .replace(
        'Weight:</span> <span class="text-sm font-medium text-text1">----</span>',
        `Weight:</span> <span class="text-sm font-medium text-text1">${
          reportDetails.weight || "N/A"
        }</span>`
      )
      .replace(
        'Height:</span> <span class="text-sm font-medium text-text1">----</span>',
        `Height:</span> <span class="text-sm font-medium text-text1">${
          reportDetails.height || "N/A"
        }</span>`
      );

    return updatedTemplate;
  }, []);

  // Helper function to format formatted_report into HTML
  const generateReportContent = useCallback((reportDetails) => {
    if (!reportDetails?.formattedReport) return null;

    const formattedReport = reportDetails.formattedReport;

    return (
      <div>
        {/* Body Parts Section */}
        {formattedReport["Body Parts"]?.map((part, index) => (
          <div key={index} className="border-b border-light-grey">
            <div className="pb-4 ml-10 mr-10">
              <p className="font-semibold text-lg underline">
                DIAGNOSTIC IMPRESSION:
              </p>
              <p className="ml-6">
                {part["Diagnostic Impression"] || "Not provided"}
              </p>
              <p className="font-semibold text-lg mt-2 ml-10">
                {part["Body Part Name"]}
              </p>
              <p className="mt-2 ml-16">
                <span>Medical History: </span>
                {part["Medical History"] || "Not provided"}
              </p>

              {/* Findings */}
              {part["Findings"]?.length > 0 && (
                <div className="ml-20 mt-2">
                  <p>Findings:</p>
                  <ul className="list-disc ml-6">
                    {part["Findings"].map((finding, idx) => (
                      <li key={idx}>{finding}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        ))}

        {/* Conclusion */}
        {formattedReport.Conclusion?.length > 0 ? (
          <div className="mt-4 ml-10 pb-10 mr-10">
            <p className="font-semibold text-lg mt-4">CONCLUSION:</p>
            <p>{formattedReport.Conclusion.join(" ")}</p>
          </div>
        ) : (
          <div className="mt-4 ml-10 pb-10 mr-10">
            <p className="font-semibold text-lg mt-4">CONCLUSION:</p>
            <p>No conclusion provided.</p>
          </div>
        )}
      </div>
    );
  }, []);

  // Combine editor content and formatted report into one final HTML string
  const generateFinalReportHTML = useCallback(() => {
    const reportContentJSX = generateReportContent(reportDetails);
    const formattedReportHTML =
      ReactDOMServer.renderToStaticMarkup(reportContentJSX);

    return `${editorContent} ${formattedReportHTML}`;
  }, [editorContent, reportDetails, generateReportContent]);

  useEffect(() => {
    if (reportDetails) {
      const updatedTemplate = replaceTemplatePlaceholders(reportDetails);
      setEditorContent(updatedTemplate);
      const finalReportHTML = generateFinalReportHTML();
      if (reportDetails.finalReport !== finalReportHTML) {
        setReportDetails({ ...reportDetails, finalReport: finalReportHTML });
      }
    }
  }, [
    reportDetails,
    replaceTemplatePlaceholders,
    generateFinalReportHTML,
    setReportDetails,
  ]);

  return (
    <div>
      <div className="flex items-center justify-between px-6 py-4 mt-4 bg-[var(--color-white)] text-lg text-[var(--color-primary)]">
        Report
        {/* <button
          type="button"
          className="flex bg-blue p-2 px-3 text-sm font-medium text-white rounded-lg"
          onClick={() =>
            navigate(`/edit-report/${reportDetails.report_id}`, {
              state: {
                fromSubmitStep: false,
                reportData: reportDetails.finalReport,
              },
            })
          }
        >
          <EditIcon className="mt-0.5 mr-2" />
          Edit
        </button> */}
      </div>

      <div
        className="m-6 mb-0 bg-white"
        dangerouslySetInnerHTML={{ __html: editorContent }}
      />
      <div className="m-6 mt-0 bg-white">
        {generateReportContent(reportDetails)}
      </div>
    </div>
  );
};

export default SubmitStep;
