import apiClient from "../constants/apiConfig";
import { toast } from "react-toastify";

export const saveDraftReport = async (transcribedText) => {
  try {
    const response = await apiClient.post(
      "/report/save-draft-report",
      transcribedText
    );
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const processDraftReport = async (darftId) => {
  try {
    const response = await apiClient.post(`/report/process-draft/${darftId}`);
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
};

export const createReport = async (reportData) => {
  try {
    const response = await apiClient.post("/report/create-report", reportData);
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.error || "Failed to create report!"); // Error toast
    throw error.response.data;
  }
};

export const getReports = async () => {
  try {
    const response = await apiClient.get("/report/get-all-reports");
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.error || "Failed to fetch reports!"); // Error toast
    throw error.response.data;
  }
};

export const getReportById = async (reportId) => {
  try {
    const response = await apiClient.get(`/report/get-report/${reportId}`);
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.error || "Failed to fetch report!"); // Error toast
    throw error.response.data;
  }
};

export const updateReport = async (reportId, reportData) => {
  try {
    const response = await apiClient.put(
      `/report/edit-report/${reportId}`,
      reportData
    );
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.error || "Failed to update report!"); // Error toast
    throw error.response.data;
  }
};

export const deleteReport = async (reportId) => {
  try {
    const response = await apiClient.delete(
      `/report/delete-report/${reportId}`
    );
    toast.success("Report deleted successfully!");
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.error || "Failed to delete report!"); // Error toast
    throw error.response.data;
  }
};

export const shareReport = async (formData) => {
  try {
    const response = await apiClient.post(`/report/share-report`, formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Ensure the correct content type
      },
    });
    toast.success("Report shared successfully!");
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.error || "Failed to share report!"); // Error toast
    throw error.response.data;
  }
};
