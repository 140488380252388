import React, { useState, useEffect, useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import PrimaryButton from "../shared/PrimaryButton";
import CommonDialog from "../shared/CommonDialog";
import { ReactComponent as EditIcon } from "../assets/edit.svg";
import { ReactComponent as ShareIcon } from "../assets/share.svg";
import { ReactComponent as DeleteIcon } from "../assets/delete.svg";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ReactComponent as GmailIcon } from "../assets/gmail.svg";
// import { ReactComponent as WatsappIcon } from "../assets/watsapp.svg";
import {
  getReportById,
  updateReport,
  deleteReport,
  shareReport,
} from "../services/reportService";
import html2pdf from "html2pdf.js";

function ReportDetailsPage() {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const reportId = location.state?.reportId;
  const [reportData, setReportData] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonLabel, setButtonLabel] = useState("");
  const [showRightIcon, setShowRightIcon] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(false);
  const [open, setOpen] = useState(false);
  const [pdf, setPdf] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDialog = () => {
    // Set the dialog title and content dynamically based on form data
    setDialogTitle("Sure You Want To Delete Report?");
    setDialogContent(
      "The report would be deleted permanently. Are you sure you want to proceed?"
    );
    setButtonLabel("Yes");
    setDialogOpen(true);
    setShowDeleteIcon(true);
    setShowRightIcon(false);
  };

  const handleOpenDialog1 = () => {
    setDialogTitle("Report Finalized Successfully!");
    setDialogContent("Report has been finalized successfully.");
    setButtonLabel("Okay");
    setDialogOpen(true);
    setShowRightIcon(true);
    setShowDeleteIcon(false);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = async () => {
    // Add your confirm logic here
    if (showDeleteIcon === true) {
      try {
        const response = await deleteReport(reportId);
        if (response.message === "Report was successfully deleted.") {
          navigate("/report-management");
        }
      } catch (error) {
        console.error("Error deleting report:", error);
      }
    } else {
      try {
        const response = await updateReport(reportId, { status: "Finalized" });
        if (response.success) {
          navigate("/report-management");
        }
      } catch (error) {
        console.error("Error deleting report:", error);
      }
    }
    setDialogOpen(false);
  };

  const fetchReportData = useCallback(async () => {
    try {
      const data = await getReportById(reportId); // Pass filter to the API
      setReportData(data.report);
    } catch (err) {
      console.error("Failed to fetch dashboard data:", err);
    }
  }, [reportId]);

  // Fetch data when component mounts or time filter changes
  useEffect(() => {
    fetchReportData();
  }, [fetchReportData]);

  const handleClickOpen = async () => {
    if (!reportData || !reportData.final_report_html) {
      console.error("Report data or final_report_html is missing");
      return;
    }

    // Step 1: Generate PDF
    const options = {
      filename: `${reportData.first_name}_${reportData.last_name}_Report.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      margin: [0.2, 0, 0.2, 0],
    };

    // Create a temporary element for rendering HTML content
    const tempElement = document.createElement("div");
    tempElement.innerHTML = reportData.final_report_html;
    document.body.appendChild(tempElement);

    const response = await html2pdf()
      .set(options)
      .from(tempElement)
      .outputPdf("blob");
    setPdf(response);

    document.body.removeChild(tempElement);

    setOpen(true);
  };

  const handleShare = async (method) => {
    try {
      const formData = new FormData();
      formData.append("method", method); // Add the method (email or whatsapp)
      formData.append("report_id", reportId); // Add the report ID
      formData.append(
        "pdf",
        pdf,
        `${reportData.first_name}_${reportData.last_name}_Report.pdf`
      ); // Add the PDF blob

      // Call the API to share the report
      await shareReport(formData);
    } catch (error) {
      console.error("Error sharing report:", error);
    }
    handleClose();
  };

  return (
    <div className="flex flex-col">
      <div className="flex xxs:flex-col sm:flex-row xxs:items-start xxs:space-y-4 sm:space-y-0 sm:items-center justify-between px-6 py-4 mt-4 bg-[var(--color-white)]">
        <span className="text-lg text-[var(--color-primary)]">Report</span>
        <div className="flex space-x-4">
          {reportData && reportData?.status === "Draft" && (
            <button
              type="button"
              className="flex bg-blue p-2 px-3 text-sm font-semibold text-white rounded-lg"
              onClick={() =>
                navigate(`/edit-report/${reportId}`, {
                  state: { fromSubmitStep: false, reportData: reportData },
                })
              }
            >
              <EditIcon className="mt-0.5 mr-2" />
              Edit
            </button>
          )}
          <button
            type="button"
            className="flex bg-green p-2 px-3 text-sm font-semibold text-white rounded-lg"
            onClick={handleClickOpen}
          >
            <ShareIcon className="mt-0.5 mr-2" />
            Share
          </button>
          <button
            type="button"
            className="flex bg-error p-2 px-3 text-sm font-semibold text-white rounded-lg"
            onClick={handleOpenDialog}
          >
            <DeleteIcon className="mt-0.5 mr-2 text-white" />
            Delete
          </button>
        </div>
      </div>
      <div className="m-6 bg-white">
        {reportData && reportData?.final_report_html ? (
          <div
            className="report-content"
            dangerouslySetInnerHTML={{ __html: reportData.final_report_html }}
          />
        ) : (
          <p>Loading report...</p>
        )}
      </div>

      {reportData && reportData?.status === "Draft" && (
        <div
          className="md:sticky bottom-0 right-0 z-10 flex bg-[var(--color-white)]"
          style={{ boxShadow: "0px -2px 6px 0px #00000024" }}
        >
          <div className="flex justify-end xxs:p-2 md:p-5 w-full">
            <PrimaryButton
              type="button"
              label="Finalize"
              className="md:w-32"
              onClick={handleOpenDialog1}
            />
          </div>
        </div>
      )}

      <CommonDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={dialogTitle}
        content={dialogContent}
        buttonLabel={buttonLabel}
        showRightIcon={showRightIcon}
        showDeleteIcon={showDeleteIcon}
        onConfirm={handleConfirm}
      />

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            borderRadius: "10px",
            padding: "5px",
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            pt: 1,
            color: theme.palette.mode === "light" ? "#333333" : "#F3F3F3",
          }}
          id="customized-dialog-title"
        >
          Share this report
          <p className="text-sm text-[var(--color-dark-grey)]">
            Share this report
          </p>
        </DialogTitle>
        <button
          onClick={handleClose}
          type="button"
          className="absolute top-0 right-0 m-4 p-1 bg-grey2 rounded-full"
        >
          <XMarkIcon
            className="h-5 w-5"
            strokeWidth={2}
            style={{ color: "#4F4F4F" }}
          />
        </button>

        <DialogContent>
          <div className="flex w-64 gap-x-10 pb-6 items-center justify-center">
            <div className="flex flex-col items-center">
              <div
                className="rounded-full w-16 h-16 flex justify-center items-center cursor-pointer"
                style={{ backgroundColor: "#FF45001A" }}
                onClick={() => handleShare("email")}
              >
                <GmailIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                  strokeWidth={2}
                />
              </div>
              <p className="text-xs mt-1" style={{ color: "#FF4500" }}>
                G-mail
              </p>
            </div>
            {/* <div className="flex flex-col items-center">
              <div
                className="rounded-full w-16 h-16 flex justify-center items-center cursor-pointer"
                style={{ backgroundColor: "#25D3661A" }}
                onClick={() => handleShare("whatsapp")}
              >
                <WatsappIcon
                  className="h-6 w-6"
                  aria-hidden="true"
                  strokeWidth={2}
                  style={{ color: "#25D366" }}
                />
              </div>
              <p className="text-xs mt-1" style={{ color: "#25D366" }}>
                Whatsapp
              </p>
            </div> */}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ReportDetailsPage;
