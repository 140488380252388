import React, { useState, useEffect, useCallback, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { TextField, InputAdornment } from "@mui/material";
import { DateRangePicker } from "react-date-range";
import { startOfDay, endOfDay } from "date-fns";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { enGB } from "date-fns/locale";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import Dropdown from "../shared/DropDown";
import DataTable from "../shared/DataTable";
import CircularProgress from "@mui/material/CircularProgress";
import PrimaryButton from "../shared/PrimaryButton";
import { getReports } from "../services/reportService";

function ReportManagementPage() {
  const theme = useTheme();
  const dateRangeRef = useRef(null);
  const [showDatePickerDropdown, setShowDatePickerDropdown] = useState(false);

  const initialDateRange = [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      color: "#513ADC",
    },
  ];

  const [dateRange, setDateRange] = useState(initialDateRange);
  const [isDateFilterActive, setIsDateFilterActive] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState({
    value: "All",
    label: "All",
  });
  const [reports, setReports] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [filtersToApply, setFiltersToApply] = useState({
    reportType: null,
    status: null,
    query: "",
    startDate: null,
    endDate: null,
  });
  const [filteredReports, setFilteredReports] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const reportOptions = [
    { value: "x_ray", label: "X-Ray" },
    { value: "mri", label: "MRI" },
    { value: "ecg", label: "ECG" },
    { value: "ct_scan", label: "CT-Scan" },
    { value: "sonography", label: "Sonography" },
  ];

  const statusOptions = [
    { value: "Draft", label: "Draft" },
    { value: "Finalized", label: "Finalized" },
    { value: "All", label: "All" },
  ];

  const fetchReports = async () => {
    setIsLoading(true);
    try {
      const response = await getReports();
      setReports(response.reports);
      setFilteredReports(response.reports);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchReports();
  }, []);

  const applyFilters = useCallback(() => {
    const { reportType, status, query, startDate, endDate } = filtersToApply;

    const filtered = reports.filter((report) => {
      const matchesReportType =
        !reportType || report.report_type === reportType.label;
      const matchesStatus =
        !status || status.value === "All" || report.status === status.value;
      const matchesSearchQuery = query
        ? report.patient_name?.toLowerCase().includes(query.toLowerCase())
        : true;

      let inDateRange = true;
      if (isDateFilterActive) {
        const normalizedStartDate = startOfDay(startDate);
        const normalizedEndDate = endOfDay(endDate);
        const reportDate = new Date(report.created_on);
        inDateRange =
          reportDate >= normalizedStartDate && reportDate <= normalizedEndDate;
      }

      return (
        matchesReportType && matchesStatus && matchesSearchQuery && inDateRange
      );
    });

    setFilteredReports(filtered);
  }, [filtersToApply, reports, isDateFilterActive]);

  const handleSearchClick = () => {
    setFiltersToApply((prev) => ({
      ...prev,
      reportType: selectedReport,
      status: selectedStatus,
      query: searchQuery,
    }));
  };

  const handleStatusChange = (option) => {
    setSelectedStatus(option);
    setFiltersToApply((prev) => ({
      ...prev,
      status: option,
    }));
  };

  useEffect(() => {
    applyFilters();
  }, [filtersToApply, applyFilters]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dateRangeRef.current &&
        !dateRangeRef.current.contains(event.target)
      ) {
        setShowDatePickerDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const dateRangeText =
    dateRange[0].startDate && dateRange[0].endDate
      ? `${dateRange[0].startDate.toDateString()} - ${dateRange[0].endDate.toDateString()}`
      : "All Dates";

  const handleClearDateRange = () => {
    setDateRange(initialDateRange);
    setIsDateFilterActive(false);
    setShowDatePickerDropdown(false);
  };

  return (
    <div className="p-6">
      <div className="flex xxs:flex-col sm:flex-row xxs:space-y-4 md:space-y-0 items-center justify-between">
        <div className="flex xxs:flex-col lg:flex-row xxs:space-y-4 lg:space-y-0 w-full lg:items-center justify-start lg:space-x-4">
          <div className="relative cursor-pointer" ref={dateRangeRef}>
            <div
              className="p-2 text-sm border bg-white dark:bg-transparent border-dark-grey rounded-md w-max"
              onClick={() => setShowDatePickerDropdown(!showDatePickerDropdown)}
            >
              <span className="text-[#283037B2] dark:text-grey">
                Date Range:
              </span>
              <span className="text-[var(--color-text1)]">
                {" "}
                {dateRangeText}
              </span>
              <CalendarMonthOutlinedIcon
                sx={{ color: "#A5A5A5", ml: 1, mb: 0.5, fontSize: "18px" }}
              />
            </div>

            {showDatePickerDropdown && (
              <div
                className="absolute z-10 bg-white top-full mt-1 md:left-0 rounded-lg"
                style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
              >
                <DateRangePicker
                  ranges={dateRange}
                  onChange={(item) => {
                    setDateRange([item.selection]);
                    setIsDateFilterActive(true);
                    setFiltersToApply((prev) => ({
                      ...prev,
                      startDate: item.selection.startDate,
                      endDate: item.selection.endDate,
                    }));
                  }}
                  locale={enGB}
                />

                <div className="flex justify-end p-2">
                  <PrimaryButton
                    label="Clear"
                    className="w-32"
                    onClick={handleClearDateRange}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="flex space-x-4 w-full">
            <div className="md:w-2/5">
              <Dropdown
                id="report-type"
                label="report type"
                options={reportOptions}
                selectedOption={selectedReport}
                onChange={(option) => {
                  setSelectedReport(option);
                  setFiltersToApply((prev) => ({
                    ...prev,
                    reportType: option,
                  }));
                }}
                showFilter={true}
              />
            </div>
            <div className="md:w-1/3">
              <Dropdown
                id="status"
                label="option"
                options={statusOptions}
                selectedOption={selectedStatus}
                onChange={handleStatusChange}
              />
            </div>
          </div>
        </div>
        <div className="flex xxs:w-full lg:w-2/6 sm:justify-end">
          <TextField
            variant="outlined"
            placeholder="Search patient name..."
            className="w-full"
            value={searchQuery}
            onChange={(e) => {
              const newQuery = e.target.value;
              setSearchQuery(newQuery);
              setFiltersToApply((prev) => ({
                ...prev,
                reportType: selectedReport,
                status: selectedStatus,
                query: newQuery,
              }));
            }}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1px solid #5F6177",
                  borderRadius: "8px !important",
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid #5F6177",
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{
                      color:
                        theme.palette.mode === "light" ? "#11171999" : "grey",
                    }}
                  />
                </InputAdornment>
              ),
              style: {
                backgroundColor:
                  theme.palette.mode === "dark" ? "transparent" : "white",
                borderRadius: "8px",
                height: "45px",
                fontSize: "14px",
              },
            }}
          />
          <button
            className="ml-2 p-2.5 rounded-lg bg-primary"
            onClick={handleSearchClick}
          >
            <SearchIcon sx={{ color: "white" }} />
          </button>
        </div>
      </div>
      <div className="mt-6">
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <CircularProgress />
          </div>
        ) : (
          <DataTable
            rows={10}
            showRow={true}
            reports={filteredReports}
            setReports={setReports}
          />
        )}
      </div>
    </div>
  );
}

export default ReportManagementPage;
