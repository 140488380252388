import React from "react";
import { ReactComponent as DbIcon } from "../../assets/db.svg";
import { ReactComponent as CrIcon } from "../../assets/cr.svg";
import { ReactComponent as RmIcon } from "../../assets/rm.svg";
import { ReactComponent as UmIcon } from "../../assets/um.svg";
import { ReactComponent as LmIcon } from "../../assets/lm.svg";
import { ReactComponent as SupportIcon } from "../../assets/support.svg";
import { ReactComponent as SettingsIcon } from "../../assets/settings.svg";
import { useNavigate } from "react-router-dom"; // Import useNavigate for routing
import { useUser } from "../../contexts/UserContext";

const Sidebar = ({
  selectedItem,
  setSelectedItem,
  isSidebarOpen,
  toggleSidebar,
}) => {
  const navigate = useNavigate(); // Initialize navigate
  const { user } = useUser();

  const menuItems = [
    {
      name: "Dashboard",
      icon: <DbIcon className="w-6 h-6" />,
      route: "/dashboard",
      role: ["Lab Admin", "Radiologist"],
    },
    {
      name: "Dashboard",
      icon: <DbIcon className="w-6 h-6" />,
      route: "/admin-dashboard",
      role: ["Admin"],
    },
    {
      name: "Create Report",
      icon: <CrIcon className="w-6 h-6" />,
      route: "/create-report/report-type",
      role: ["Lab Admin", "Radiologist"],
    },
    {
      name: "Report Management",
      icon: <RmIcon className="w-6 h-6" />,
      route: "/report-management",
      role: ["Lab Admin", "Radiologist"],
    },
    {
      name: "User Management",
      icon: <UmIcon className="w-6 h-6" />,
      route: "/user-management",
      role: ["Lab Admin"],
    },
    {
      name: "Lab Management",
      icon: <LmIcon className="w-6 h-6" />,
      route: "/lab-management",
      role: ["Admin"],
    },
    {
      name: "Support",
      icon: <SupportIcon className="w-6 h-6" />,
      route: "/support",
      role: ["Lab Admin", "Radiologist"],
    },
    {
      name: "Settings",
      icon: <SettingsIcon className="w-6 h-6" />,
      route: "/settings/personal-details",
    },
  ];

  // Filter the menu items based on user role
  const filteredMenuItems = menuItems.filter((item) => {
    return (
      !item.role ||
      (user && user?.role && Array.isArray(item.role)
        ? item.role.includes(user?.role)
        : user?.role === item.role)
    );
  });

  const handleItemClick = (item) => {
    setSelectedItem(item.name);
    navigate(item.route); // Navigate to the selected route
    toggleSidebar(); // Close sidebar after selecting an item
  };

  return (
    <>
      {/* Sidebar overlay on small screens */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-[#373737] bg-opacity-50 z-40 lg:hidden"
          onClick={toggleSidebar}
        ></div>
      )}

      {/* Sidebar container */}
      <aside
        className={`fixed lg:relative bg-[var(--color-overlay)] z-50 xxs:w-[250px] md:w-72 h-full p-4 text-white transform transition-transform duration-300 ease-in-out ${
          isSidebarOpen ? "translate-x-0" : "-translate-x-full"
        } lg:translate-x-0`}
        style={{
          boxShadow: "0px 0px 8px 0px #0000001F",
        }}
      >
        <div className="flex flex-row mb-8 mt-8">
          <div className="rounded-full w-10 h-10 ml-3 bg-grey4"></div>
          <div className="ml-4 pt-1 text-xl font-extrabold">Emilio Muse</div>
        </div>
        <ul className="space-y-1">
          {filteredMenuItems.map((item) => (
            <li
              key={item.name}
              className={`flex items-center p-4 rounded-lg cursor-pointer hover:font-semibold ${
                selectedItem === item.name
                  ? "bg-primary font-semibold"
                  : "hover:bg-grey3"
              }`}
              onClick={() => handleItemClick(item)} // Navigate to route when clicked
            >
              {item.icon}
              <span className="ml-3 medium-text">{item.name}</span>
            </li>
          ))}
        </ul>
      </aside>

      {/* Overlay when sidebar is open on small screens */}
      {isSidebarOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50 z-40 lg:hidden"
          onClick={toggleSidebar} // Close sidebar when clicking outside
        ></div>
      )}
    </>
  );
};

export default Sidebar;
