import apiClient from "../constants/apiConfig";
import { toast } from "react-toastify";

export const registerUser = async (userData) => {
  try {
    const response = await apiClient.post("/auth/register", userData);
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.error || "Registration failed!"); // Error toast
    throw error.response.data;
  }
};

export const loginUser = async (credentials) => {
  try {
    const response = await apiClient.post("/auth/login", credentials);
    toast.success("Login successful!"); // Success toast
    return response.data;
  } catch (error) {
    if (error.response?.data?.message === "Subscription required.") {
      throw error.response.data;
    } else {
      toast.error(error.response?.data?.error || "Login failed!"); // Error toast
      throw error.response.data;
    }
  }
};

export const forgotPassword = async (email) => {
  try {
    const response = await apiClient.post("/auth/forgotpassword", { email });
    toast.success(response.data.message); // Success toast
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.error || "Failed to send OTP!"); // Error toast
    throw error.response.data;
  }
};

export const verifyOtp = async (email, otp) => {
  try {
    const response = await apiClient.post("/auth/verifyotp", { email, otp });
    toast.success("OTP verified successfully!"); // Success toast
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.error || "Invalid OTP!"); // Error toast
    throw error.response.data;
  }
};

export const resetPassword = async (email, otp, newPassword) => {
  try {
    const response = await apiClient.put("/auth/resetpassword", {
      email,
      otp,
      password: newPassword,
    });
    toast.success("Password reset successful!"); // Success toast
    return response.data;
  } catch (error) {
    toast.error(error.response?.data?.error || "Password reset failed!"); // Error toast
    throw error.response.data;
  }
};

export const logoutUser = async () => {
  const currentUser = localStorage.getItem("currentUser");
  if (currentUser) {
    localStorage.removeItem(`authToken_${currentUser}`);
    localStorage.removeItem("currentUser");
  }
  sessionStorage.removeItem("authToken");
};
