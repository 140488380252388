import React, { useState, useEffect, useMemo, useContext } from "react";
import TextInput from "../../shared/TextInput";
import Dropdown from "../../shared/DropDown";
import PrimaryButton from "../../shared/PrimaryButton";
import CancelButton from "../../shared/CancelButton";
import { ReactComponent as UserFocusIcon } from "../../assets/UserFocus.svg";
import LoopOutlinedIcon from "@mui/icons-material/LoopOutlined";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { useUser, UserContext } from "../../contexts/UserContext";
import { useLab, LabContext } from "../../contexts/LabContext";
import { updateUserProfile } from "../../services/userService";
import { getProfile } from "../../services/userService";
import dayjs from "dayjs";
import TimeRangePicker from "@wojtekmaj/react-timerange-picker";
import "@wojtekmaj/react-timerange-picker/dist/TimeRangePicker.css";
import "react-clock/dist/Clock.css";
import femaleImg from "../../assets/female-avatar.svg";
import maleImg from "../../assets/male-avatar.svg";
import apiClient from "../../constants/apiConfig";
import { toast } from "react-toastify";

function PersonalDetails() {
  const { user } = useUser();
  const { lab } = useLab();
  const { setUser } = useContext(UserContext);
  const { setLab } = useContext(LabContext);
  const [open, setOpen] = useState(false);

  const splitName = (name) => {
    if (!name) return { firstName: "", lastName: "" };
    const parts = name.trim().split(" ");
    if (parts.length === 2) {
      return { firstName: parts[0], lastName: parts[1] };
    } else if (parts.length > 2) {
      return {
        firstName: parts[0],
        lastName: parts.slice(1).join(" "),
      };
    } else {
      return { firstName: parts[0] || "", lastName: "" };
    }
  };

  const [userDetails, setUserDetails] = useState({
    ...splitName(user?.name),
    gender: user?.gender,
    dateOfBirth: user?.dob ? dayjs(user.dob) : null,
    email: user?.email,
    role: user?.role,
    contactNumber: user?.contact_no,
    labName: lab?.lab_name,
    labLocation: lab?.lab_location,
    labId: lab?.lab_id,
    timings: lab?.timings || [],
    emailID: lab?.lab_email_id,
    phoneNumber: lab?.lab_contact_no,
  });

  const genderOptions = useMemo(
    () => [
      { value: "Male", label: "Male" },
      { value: "Female", label: "Female" },
      { value: "Other", label: "Other" },
    ],
    []
  );

  const [selectedGender, setSelectedGender] = useState(
    user?.gender
      ? genderOptions.find((option) => option.value === user.gender)
      : null
  );

  const [imagePreview, setImagePreview] = useState(user?.profile_photo || null);
  const [errorMessage, setErrorMessage] = useState("");

  // Introduce 'touched' state
  const [touched, setTouched] = useState({});

  const handleInputChange = (field, value) => {
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));

    // Mark the field as touched when user modifies it
    setTouched((prevTouched) => ({
      ...prevTouched,
      [field]: true,
    }));
  };

  // Allowed file types and max file size (800kB)
  const allowedFileTypes = ["image/jpeg", "image/png"];
  const maxFileSize = 800 * 1024; // 800kB in bytes
  const [selectedFile, setSelectedFile] = useState(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];

    // Reset error state
    setErrorMessage("");

    if (file) {
      if (!allowedFileTypes.includes(file.type)) {
        setErrorMessage("Only JPG or PNG files are allowed.");
        return;
      }
      if (file.size > maxFileSize) {
        setErrorMessage("File size must not exceed 800kB.");
        return;
      }

      // Store file and preview URL
      setSelectedFile(file);
      setImagePreview(URL.createObjectURL(file)); // Simplify to display image
    }
  };

  const handleGenderChange = (gender) => {
    setSelectedGender(gender);
    handleInputChange("gender", gender.value);
  };

  const handleTimingsChange = (newTimings) => {
    handleInputChange("timings", newTimings);
  };

  const handleEditProfile = async () => {
    try {
      const fullName = `${userDetails.firstName} ${userDetails.lastName}`;

      const formattedDOB = userDetails.dateOfBirth
        ? userDetails.dateOfBirth.format("DD/MM/YYYY")
        : null;

      const userDetailsPayload = {
        name: fullName,
        gender: userDetails.gender || "",
        dob: formattedDOB || "",
        contact_no: userDetails.contactNumber || "",
        lab_name: userDetails.labName || "",
        lab_location: userDetails.labLocation || "",
        timings: userDetails.timings || [],
        lab_email_id: userDetails.emailID || "",
        lab_contact_no: userDetails.phoneNumber || "",
      };

      // Make API call for JSON payload
      await updateUserProfile(userDetailsPayload);

      if (selectedFile) {
        const formData = new FormData();
        formData.append("profile_photo", selectedFile, selectedFile.name);

        // API call for profile photo upload
        const response = await apiClient.post("/user/profile/photo", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        if (response.success) {
          toast.success("Profile updated successfully!");
        }
      }

      const updatedProfile = await getProfile();
      setUser(updatedProfile.user);
      setLab(updatedProfile.lab);
      setTouched({}); // Reset touched state after successful update
    } catch (error) {
      console.error("Error updating profile:", error);
      // Optionally, set a global error message here
    }
  };

  useEffect(() => {
    if (user && lab) {
      setSelectedGender(
        genderOptions.find((option) => option.value === user.gender) || null
      );
      setUserDetails({
        ...splitName(user.name),
        gender: user.gender,
        dateOfBirth: user.dob ? dayjs(user.dob) : null,
        email: user.email,
        role: user.role,
        contactNumber: user.contact_no,
        labName: lab.lab_name,
        labLocation: lab.lab_location,
        labId: lab.lab_id,
        timings: lab.timings,
        emailID: lab.lab_email_id,
        phoneNumber: lab.lab_contact_no,
        profile_photo: user.profile_photo,
      });
      setImagePreview(user.profile_photo || null);
      setTouched({}); // Reset touched state when user data is loaded
    }
  }, [user, lab, genderOptions]);

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z\s]*$/;
    return nameRegex.test(name);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const isFormValid = () => {
    return (
      validateName(userDetails.firstName) &&
      validateName(userDetails.lastName) &&
      validateEmail(userDetails.email) &&
      validatePhoneNumber(userDetails.contactNumber) &&
      (userDetails.role !== "Lab Admin" ||
        (validateEmail(userDetails.emailID) &&
          validatePhoneNumber(userDetails.phoneNumber))) &&
      errorMessage === ""
    );
  };

  return (
    <div>
      <div className="flex items-center px-6 py-4 mt-6 bg-[var(--color-white)] text-lg text-[var(--color-primary)]">
        Personal Information
      </div>

      <div
        className="m-6 bg-[var(--color-white)] py-6 px-4 rounded-[10px]"
        style={{ border: "1px solid #CFD8DC" }}
      >
        <div className="flex xxs:flex-col md:flex-row xxs:space-y-4 md:space-y-0 items-center">
          <img
            src={
              imagePreview
                ? imagePreview // If the user has an avatar, use it
                : userDetails.gender === "Female" // If gender is female, use a default female avatar
                ? femaleImg
                : maleImg // Otherwise, use a default male avatar
            }
            alt="User"
            className="w-[150px] h-[150px] object-cover object-center rounded-[24px]"
          />
          <div className="flex flex-col md:ml-6 space-y-4">
            <div className="flex xxs:flex-col md:flex-row xxs:space-y-4 md:space-y-0 md:space-x-4">
              <input
                type="file"
                accept="image/png, image/jpeg"
                id="file-input"
                style={{ display: "none" }}
                onChange={handleImageUpload}
              />
              <PrimaryButton
                type="button"
                label="Upload Image"
                leftIcon={<UserFocusIcon />}
                className="w-30 mt-4"
                onClick={() => document.getElementById("file-input").click()}
              />

              <CancelButton
                type="button"
                label="Reset"
                leftIcon={<LoopOutlinedIcon sx={{ color: "#7786A3" }} />}
                className="md:w-40"
                onClick={() => {
                  setImagePreview(user?.profile_photo || null);
                  setErrorMessage("");
                  setSelectedFile(null);
                  document.getElementById("file-input").value = "";
                  setTouched((prevTouched) => ({
                    ...prevTouched,
                    profile_photo: true, // Optionally mark profile_photo as touched
                  }));
                }}
              />
            </div>
            <p className="md:ml-6 text-[var(--color-dark-grey)]">
              Allowed JPG or PNG file size of 800kB
            </p>
            {errorMessage && (
              <p className="text-error text-sm mt-1">{errorMessage}</p>
            )}
          </div>
        </div>

        <div className="mt-10 grid grid-cols-1 sm:grid-cols-2 gap-y-6 gap-x-16">
          <TextInput
            id="first-name"
            label="First Name"
            value={userDetails.firstName || ""}
            onChange={(e) => handleInputChange("firstName", e.target.value)}
            onBlur={() => setTouched((prev) => ({ ...prev, firstName: true }))}
            error={!validateName(userDetails.firstName) && touched.firstName}
            helperText={
              !validateName(userDetails.firstName) && touched.firstName
                ? "Name can only contain letters."
                : ""
            }
          />

          <TextInput
            id="last-name"
            label="Last Name"
            value={userDetails.lastName || ""}
            onChange={(e) => handleInputChange("lastName", e.target.value)}
            onBlur={() => setTouched((prev) => ({ ...prev, lastName: true }))}
            error={!validateName(userDetails.lastName) && touched.lastName}
            helperText={
              !validateName(userDetails.lastName) && touched.lastName
                ? "Name can only contain letters."
                : ""
            }
          />

          <div>
            <Dropdown
              id="gender"
              label="Gender"
              showLabel={true}
              options={genderOptions}
              selectedOption={selectedGender}
              onChange={handleGenderChange}
            />
          </div>

          <div className="w-full">
            <p className="text-sm mb-1 text-[var(--color-dark-grey)]">
              DOB (DD/MM/YY)
            </p>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={userDetails.dateOfBirth || null}
                onChange={(newValue) => {
                  handleInputChange(
                    "dateOfBirth",
                    newValue ? dayjs(newValue) : null
                  );
                  setOpen(false);
                }}
                maxDate={dayjs()}
                slotProps={{
                  textField: {
                    size: "small",
                    placeholder: "Choose Date",
                    sx: {
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1px solid #5F6177",
                          borderRadius: "8px !important",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1px solid #5F6177",
                          borderRadius: "8px !important",
                        },
                      },
                    },
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setOpen(true)}>
                            <CalendarMonthOutlinedIcon sx={{ ml: 0 }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </div>

          <TextInput
            id="email"
            label="Email"
            value={userDetails.email || ""}
            onChange={(e) => handleInputChange("email", e.target.value)}
            onBlur={() => setTouched((prev) => ({ ...prev, email: true }))}
            error={!validateEmail(userDetails.email) && touched.email}
            helperText={
              !validateEmail(userDetails.email) && touched.email
                ? "Please enter a valid email address."
                : ""
            }
          />

          <TextInput
            id="contact-number"
            label="Contact Number"
            type="number"
            value={userDetails.contactNumber || ""}
            onChange={(e) => handleInputChange("contactNumber", e.target.value)}
            onBlur={() =>
              setTouched((prev) => ({ ...prev, contactNumber: true }))
            }
            error={
              !validatePhoneNumber(userDetails.contactNumber) &&
              touched.contactNumber
            }
            helperText={
              !validatePhoneNumber(userDetails.contactNumber) &&
              touched.contactNumber
                ? "Phone number must be 10 digits."
                : ""
            }
          />
        </div>
      </div>

      {userDetails.role === "Lab Admin" && (
        <div>
          <div className="flex items-center px-6 py-4 mt-6 bg-[var(--color-white)] text-lg text-[var(--color-primary)]">
            Lab Details
          </div>
          <div
            className="m-6 bg-[var(--color-white)] py-6 px-4 rounded-[10px]"
            style={{ border: "1px solid #CFD8DC" }}
          >
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-6 gap-x-16">
              <TextInput
                id="lab-name"
                label="Lab Name"
                value={userDetails.labName || ""}
                onChange={(e) => handleInputChange("labName", e.target.value)}
                onBlur={() =>
                  setTouched((prev) => ({ ...prev, labName: true }))
                }
                error={!validateName(userDetails.labName) && touched.labName}
                helperText={
                  !validateName(userDetails.labName) && touched.labName
                    ? "Lab name can only contain letters."
                    : ""
                }
              />

              <TextInput
                id="lab-id"
                label="Lab Id"
                value={userDetails.labId || ""}
                onChange={(e) => handleInputChange("labId", e.target.value)}
                readOnly={true}
              />

              <TextInput
                id="lab-location"
                label="Lab Location"
                value={userDetails.labLocation || ""}
                onChange={(e) =>
                  handleInputChange("labLocation", e.target.value)
                }
                onBlur={() =>
                  setTouched((prev) => ({ ...prev, labLocation: true }))
                }
                error={
                  !validateName(userDetails.labLocation) && touched.labLocation
                }
                helperText={
                  !validateName(userDetails.labLocation) && touched.labLocation
                    ? "Lab location can only contain letters."
                    : ""
                }
              />

              <div className="flex flex-col w-full">
                <span className="text-sm text-[var(--color-dark-grey)] mb-1">
                  Timings
                </span>
                <TimeRangePicker
                  value={userDetails.timings || []}
                  onChange={handleTimingsChange}
                  disableClock
                />
              </div>

              <TextInput
                id="emailID"
                label="Email ID"
                value={userDetails.emailID || ""}
                onChange={(e) => handleInputChange("emailID", e.target.value)}
                onBlur={() =>
                  setTouched((prev) => ({ ...prev, emailID: true }))
                }
                error={!validateEmail(userDetails.emailID) && touched.emailID}
                helperText={
                  !validateEmail(userDetails.emailID) && touched.emailID
                    ? "Please enter a valid email address."
                    : ""
                }
              />

              <TextInput
                id="phone-number"
                label="Contact Number"
                type="number"
                value={userDetails.phoneNumber || ""}
                onChange={(e) =>
                  handleInputChange("phoneNumber", e.target.value)
                }
                onBlur={() =>
                  setTouched((prev) => ({ ...prev, phoneNumber: true }))
                }
                error={
                  !validatePhoneNumber(userDetails.phoneNumber) &&
                  touched.phoneNumber
                }
                helperText={
                  !validatePhoneNumber(userDetails.phoneNumber) &&
                  touched.phoneNumber
                    ? "Phone number must be 10 digits."
                    : ""
                }
              />
            </div>
          </div>
        </div>
      )}

      <div
        className="bottom-0 flex bg-[var(--color-white)]"
        style={{ boxShadow: "0px -2px 6px 0px #00000024" }}
      >
        <div className="flex flex-row justify-end xxs:p-2 md:p-6 w-full">
          <PrimaryButton
            type="button"
            label="Save Changes"
            className="md:w-40"
            disabled={!isFormValid()}
            onClick={handleEditProfile}
          />
        </div>
      </div>
    </div>
  );
}

export default PersonalDetails;
