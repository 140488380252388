import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./styles/tailwind.css";
import "./i18n";
import "rsuite/dist/rsuite.min.css";
import "ckeditor5/ckeditor5.css";
import reportWebVitals from "./reportWebVitals";
import { UserProvider } from "./contexts/UserContext";
import { LabProvider } from "./contexts/LabContext";
import { SettingsProvider } from "./contexts/SettingsContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <UserProvider>
    <LabProvider>
      <SettingsProvider>
        <App />
      </SettingsProvider>
    </LabProvider>
  </UserProvider>
);

// Register the Service Worker
if ("serviceWorker" in navigator && "PushManager" in window) {
  navigator.serviceWorker
    .register("/service-worker.js")
    .then((registration) => {
      console.log("Service Worker registered successfully:", registration);
    })
    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });
}

// Measure performance
reportWebVitals();
