import React from "react";
import { useTheme } from "@mui/material/styles";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import { ReactComponent as OtherUser } from "../assets/profile-2user.svg";
import { LinearProgress } from "@mui/material";
import { Progress } from "rsuite";

function ReportsCard({ label, weeklyData }) {
  const theme = useTheme();

  const weekdayColorMapping = {
    "Monday": "#28C76F",
    "Tuesday": "#FCAA5C",
    "Wednesday": "#8954A9",
    "Thursday": "#FF4C51",
    "Friday": "#7367F0",
    "Saturday": "#00BAD1",
    "Sunday": "#513ADC",
  };

  const daysOfWeekOrder = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const orderedWeekData = daysOfWeekOrder.map((day) => ({
    day,
    Male: weeklyData[day]?.Male || 0,
    Female: weeklyData[day]?.Female || 0,
    Others: weeklyData[day]?.Others || 0,
    Total: weeklyData[day]?.Total || 0,
  }));

  // Calculate total reports and gender counts
  const totalReports = orderedWeekData.reduce((acc, day) => acc + day.Total, 0);
  const totalMale = orderedWeekData.reduce((acc, day) => acc + day.Male, 0);
  const totalFemale = orderedWeekData.reduce((acc, day) => acc + day.Female, 0);
  const totalOthers = orderedWeekData.reduce((acc, day) => acc + day.Others, 0);

  return (
    <div
      className="bg-[var(--color-white)] rounded-[10px] p-5 flex flex-col w-full"
      style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
    >
      <div className="flex flex-col">
        <p className="xxs:text-base md:text-[20px] text-[var(--color-text1)]">
          {label} Reports
        </p>
        <span className="xxs:text-xs md:text-sm text-blue-text">
          Weekly Report Overview
        </span>
      </div>
      <div className="flex items-end">
        <div className="flex flex-col">
          <div className="flex items-end">
            <span className="xxs:text-xl md:text-4xl font-medium text-[var(--color-text1)]">
              {totalReports}
            </span>
            <span className="xxs:text-sm md:text-base text-blue-text ml-2 mb-1">
              {label}
            </span>
          </div>
          <span className="xxs:text-xs md:text-sm text-blue-text w-2/3">
            Reports generated this week compared to last week
          </span>
        </div>
        <div className="flex items-end">
          {orderedWeekData.map((data, index) => (
            <div key={index} className="flex flex-col items-center">
              <Progress.Line
                vertical
                percent={
                  totalReports > 0 ? (data.Total / totalReports) * 100 : 0
                } // Normalize to percentage
                showInfo={false}
                strokeColor={weekdayColorMapping[data.day] || "#7367F0"}
                style={{ height: "100px" }}
              />
              <span className="text-xs text-[var(--color-dark-grey)]">
                {data.day.slice(0, 3)} {/* Display abbreviated day names */}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-6 flex flex-wrap gap-4 justify-between text-sm border border-light-grey rounded-[10px] p-4">
        <div className="flex items-center space-x-1">
          <div className="bg-[#63D9FF33] rounded-[10px] flex items-center justify-center p-1">
            <MaleIcon style={{ color: "#00BAD1", fontSize: "30px" }} />
          </div>
          <div>
            <span className="medium-text text-[var(--color-text1)]">Male</span>
            <LinearProgress
              variant="determinate"
              value={totalMale > 0 ? (totalMale / totalReports) * 100 : 0}
              sx={{
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#00BAD1",
                  borderRadius: 10,
                },
                height: 4,
                width: 58,
                borderRadius: 10,
                backgroundColor:
                  theme.palette.mode === "light" ? "#B9B9B9" : "#2A2D3E",
              }}
            />
          </div>
        </div>
        <div className="flex items-center space-x-1">
          <div className="bg-[#FF9D8833] rounded-[10px] flex items-center justify-center p-1">
            <FemaleIcon style={{ color: "#FF4C51", fontSize: "30px" }} />
          </div>
          <div>
            <span className="medium-text text-[var(--color-text1)]">
              Female
            </span>
            <LinearProgress
              variant="determinate"
              value={totalFemale > 0 ? (totalFemale / totalReports) * 100 : 0}
              sx={{
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#FF4C51",
                  borderRadius: 10,
                },
                height: 4,
                width: 58,
                borderRadius: 10,
                backgroundColor:
                  theme.palette.mode === "light" ? "#B9B9B9" : "#2A2D3E",
              }}
            />
          </div>
        </div>
        <div className="flex items-center space-x-1">
          <div className="bg-[#45FF4C33] rounded-[10px] flex items-center justify-center p-2">
            <OtherUser className="h-6 w-6" />
          </div>
          <div>
            <span className="medium-text text-[var(--color-text1)]">
              Others
            </span>
            <LinearProgress
              variant="determinate"
              value={totalOthers > 0 ? (totalOthers / totalReports) * 100 : 0}
              sx={{
                "& .MuiLinearProgress-bar": {
                  backgroundColor: "#28C76F",
                  borderRadius: 10,
                },
                height: 4,
                width: 58,
                borderRadius: 10,
                backgroundColor:
                  theme.palette.mode === "light" ? "#B9B9B9" : "#2A2D3E",
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportsCard;
