import React, { useState, useEffect, useMemo } from "react";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import Dropdown from "../../shared/DropDown";
import { updateSettings } from "../../services/userService";
import { useSettings } from "../../contexts/SettingsContext";
import { useUser } from "../../contexts/UserContext";
import { subscribeToNotifications } from "../../services/notificationsService";

function Notifications() {
  const { settings } = useSettings();
  const { user } = useUser();

  const adminNotificationOptions = useMemo(
    () => [
      {
        value: "account_created",
        label: "Account Created",
        description: "Alerts and updates related to new account creation.",
      },
      {
        value: "account_deleted",
        label: "Account Deleted",
        description:
          "Notifications regarding account deletions and their impact.",
      },
      {
        value: "subscription_taken",
        label: "Subscription",
        description:
          "Get insights on how many customers have purchased your subscription.",
      },
      {
        value: "user_updated",
        label: "User Details Update",
        description: "Alerts and information after changes to user details.",
      },
      {
        value: "report_deleted",
        label: "Report Deleted",
        description: "Notifications when a report is deleted.",
      },
      {
        value: "update_version",
        label: "Update Version",
        description:
          "Stay informed with updates when the system version changes.",
      },
    ],
    []
  );
  const userNotificationOptions = useMemo(
    () => [
      {
        value: "report_created",
        label: "Report Created",
        description: "Notify user when a report is created.",
      },
      {
        value: "report_deleted",
        label: "Report Deleted",
        description: "Notify user when a report is deleted.",
      },
      {
        value: "update_version",
        label: "Update Version",
        description:
          "Stay informed with updates when the system version changes.",
      },
    ],
    []
  );
  const notificationOptions = useMemo(
    () => [
      { value: "online", label: "Only When I’m online" },
      { value: "anytime", label: "Anytime" },
    ],
    [] // Empty dependency array ensures it is only computed once
  );

  const [selectedNotification, setSelectedNotification] = useState(
    settings?.notification_frequency
      ? notificationOptions.find(
          (option) => option.value === settings.notification_frequency
        )
      : null
  );

  const [notificationSettings, setNotificationSettings] = useState({
    account_created: settings?.account_created,
    account_deleted: settings?.account_deleted,
    subscription_taken: settings?.subscription_taken,
    user_updated: settings?.user_updated,
    report_created: settings?.report_created,
    report_deleted: settings?.report_deleted,
    update_version: settings?.update_version,
  });

  const notifications = useMemo(() => {
    if (user?.role === "Admin") {
      return adminNotificationOptions;
    } else {
      return userNotificationOptions;
    }
  }, [user?.role, adminNotificationOptions, userNotificationOptions]);

  useEffect(() => {
    if (settings) {
      const selected = notificationOptions.find(
        (option) => option.value === settings.notification_frequency
      );
      setSelectedNotification(selected || null);
      setNotificationSettings((prev) => ({
        ...prev,
        account_created: settings.account_created,
        account_deleted: settings.account_deleted,
        subscription_taken: settings.subscription_taken,
        user_updated: settings.user_updated,
        report_created: settings.report_created,
        report_deleted: settings.report_deleted,
        update_version: settings.update_version,
      }));
    }
  }, [settings, notificationOptions]);

  const handleNotificationChange = (option) => {
    setSelectedNotification(option);
    updateSettings({ notification_frequency: option.value });

    if (option.value === "anytime") {
      handleSubscribe();
    }
  };

  const handleSubscribe = async () => {
    await subscribeToNotifications();
  };

  const handleSwitchChange = (key) => (event) => {
    const newSetting = event.target.checked;
    setNotificationSettings((prev) => {
      const updatedSettings = { ...prev, [key]: newSetting };
      updateSettings(updatedSettings);
      return updatedSettings;
    });
  };

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 24,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: theme.palette.mode === "light" ? "#FFFFFF" : "#E6E6E6",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "light" ? "#1BB13C" : "#1BB13C",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 20,
      height: 20,
      boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.1)",
    },
    "& .MuiSwitch-track": {
      borderRadius: 15,
      backgroundColor: theme.palette.mode === "light" ? "#E6E6E6" : "#333649",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  return (
    <div className="h-[80vh]">
      <div className="flex flex-col m-6 bg-[var(--color-white)] rounded-[10px]">
        {notifications.map(({ label, value, description }) => (
          <React.Fragment key={value}>
            <div className="flex items-center justify-between">
              <div className="px-4 py-2">
                <p className="xxs:text-sm md:text-lg text-[var(--color-text1)]">
                  {label}
                </p>
                <span className="text-[var(--color-dark-grey)] medium-text">
                  {description}
                </span>
              </div>
              <IOSSwitch
                sx={{ mr: 2 }}
                checked={notificationSettings[value]}
                onChange={handleSwitchChange(value)}
              />
            </div>
            <Divider
              orientation="horizontal"
              sx={{
                border: "1px solid #B9B9B9",
              }}
              flexItem
            />
          </React.Fragment>
        ))}
        <div className="mt-6 mb-6 px-4 w-1/3">
          <p className="xxs:text-sm md:text-lg text-[var(--color-text1)] mb-1">
            When should we send you notification?
          </p>
          <Dropdown
            id="notification"
            options={notificationOptions}
            selectedOption={selectedNotification}
            onChange={handleNotificationChange}
          />
        </div>
      </div>
    </div>
  );
}

export default Notifications;
