import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import CommonDialog from "./CommonDialog";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ReactComponent as GmailIcon } from "../assets/gmail.svg";
// import { ReactComponent as WatsappIcon } from "../assets/watsapp.svg";
import { format } from "date-fns";
import {
  deleteReport,
  shareReport,
  getReportById,
} from "../services/reportService";
import html2pdf from "html2pdf.js";

const DataTable = ({
  rows,
  showRow,
  openDeleteDialog,
  reports,
  setReports,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const [rowsPerPage] = useState(rows);
  const [page, setPage] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const actionOptions = ["Share", "Edit", "Delete"];
  const [reportData, setReportData] = useState("");

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonLabel, setButtonLabel] = useState("");
  const [open, setOpen] = useState(false);
  const [selectedReportId, setSelectedReportId] = useState(null);
  const [pdf, setPdf] = useState(null);

  const handleClickOpen = async (reportId) => {
    try {
      const data = await getReportById(reportId); // Pass filter to the API
      setReportData(data.report);

      if (!reportData || !reportData.final_report_html) {
        console.error("Report data or final_report_html is missing");
        return;
      }

      // Step 1: Generate PDF
      const options = {
        filename: `${reportData.first_name}_${reportData.last_name}_Report.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
        margin: [0.2, 0, 0.2, 0],
      };

      // Create a temporary element for rendering HTML content
      const tempElement = document.createElement("div");
      tempElement.innerHTML = reportData.final_report_html;
      document.body.appendChild(tempElement);

      const response = await html2pdf()
        .set(options)
        .from(tempElement)
        .outputPdf("blob");
      setPdf(response);

      document.body.removeChild(tempElement);

      setOpen(true);
    } catch (err) {
      console.error("Failed to fetch dashboard data:", err);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDialog = () => {
    // Set the dialog title and content dynamically based on form data
    setDialogTitle("Sure You Want To Delete Report?");
    setDialogContent(
      "The report would be deleted permanently. Are you sure you want to proceed?"
    );
    setButtonLabel("Yes");
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = async () => {
    // Add your confirm logic here
    try {
      const response = await deleteReport(selectedReportId);
      if (response.message === "Report was successfully deleted.") {
        setReports((prevReports) =>
          prevReports.filter((report) => report.report_id !== selectedReportId)
        );
        setSelectedReportId(null);
      }
    } catch (error) {
      console.error("Error deleting report:", error);
    }
    setDialogOpen(false);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
  };

  const totalPages = Math.max(
    1,
    Math.ceil((reports?.length || 0) / rowsPerPage)
  );

  // Handle action selection from the Menu
  const handleActionSelect = (option) => {
    setAnchorEl(null); // Close the Menu after selection

    if (option === "Share") {
      handleClickOpen(selectedReportId);
    } else if (option === "Edit" && selectedReportId) {
      navigate(`/edit-report/${selectedReportId}`, {
        state: { fromSubmitStep: false, reportId: selectedReportId },
      });
    } else if (option === "Delete" && selectedReportId) {
      handleOpenDialog();
    }
  };

  // Toggle the Menu for a specific row
  const toggleActionMenu = (event, reportId) => {
    event.stopPropagation(); // Prevent triggering row click
    if (anchorEl) {
      setAnchorEl(null);
      setSelectedReportId(null);
    } else {
      setAnchorEl(event.currentTarget);
      setSelectedReportId(reportId);
    }
  };

  const getStatusStyle = (status) => {
    if (status === "Finalized") {
      return {
        backgroundColor: "#00AD3D1A",
        color: "#28C76F",
        padding: "7px 15px",
        borderRadius: "6px",
      };
    } else if (status === "Draft") {
      return {
        backgroundColor: "#AE6CCD26",
        color: "#8954A9",
        padding: "7px 15px",
        borderRadius: "6px",
      };
    }
    return {};
  };

  const handleRowClick = (reportId) => {
    navigate(`/report-details/${reportId}`, { state: { reportId } });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd MMM yyyy");
  };

  const handleShare = async (method) => {
    try {
      const formData = new FormData();
      formData.append("method", method); // Add the method (email or whatsapp)
      formData.append("report_id", selectedReportId); // Add the report ID
      formData.append(
        "pdf",
        pdf,
        `${reportData.first_name}_${reportData.last_name}_Report.pdf`
      ); // Add the PDF blob

      // Call the API to share the report
      await shareReport(formData);
    } catch (error) {
      console.error("Error sharing report:", error);
    }
    handleClose();
  };

  return (
    <div className="rounded-2xl">
      <TableContainer
        sx={{
          backgroundColor: theme.palette.mode === "light" ? "white" : "#1A1A1A",
          paddingTop: showRow ? "15px" : undefined,
          borderTopLeftRadius: showRow ? "16px" : undefined,
          borderTopRightRadius: showRow ? "16px" : undefined,
          minHeight: showRow ? "500px" : "300px",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>REPORT ID</TableCell>
              <TableCell>PATIENT NAME</TableCell>
              <TableCell>REPORT TYPE</TableCell>
              {showRow && <TableCell>DATE</TableCell>}
              {showRow && <TableCell>CREATED BY</TableCell>}
              <TableCell>STATUS</TableCell>
              {showRow && <TableCell>ACTION</TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {Array.isArray(reports) && reports.length > 0 ? (
              reports
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((report, index) => (
                  <TableRow
                    key={report.report_id}
                    onClick={() => handleRowClick(report.report_id)}
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell>{report.report_id}</TableCell>
                    <TableCell>{report.patient_name}</TableCell>
                    <TableCell>{report.report_type}</TableCell>
                    {showRow && (
                      <TableCell>
                        {report.created_on
                          ? formatDate(report.created_on)
                          : "-"}
                      </TableCell>
                    )}
                    {showRow && (
                      <TableCell>{report.created_by || "-"}</TableCell>
                    )}
                    <TableCell>
                      <span style={getStatusStyle(report.status)}>
                        {report.status}
                      </span>
                    </TableCell>
                    {showRow && (
                      <TableCell>
                        <IconButton
                          onClick={(event) =>
                            toggleActionMenu(event, report.report_id)
                          }
                          aria-controls={anchorEl ? "action-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={anchorEl ? "true" : undefined}
                        >
                          <MoreVertIcon
                            sx={{ fontSize: "18px", color: "#666666" }}
                          />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={showRow ? 7 : 6}
                  style={{ textAlign: "center" }}
                >
                  No reports available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* MUI Pagination */}
      {((showRow && reports?.length > 10) ||
        (!showRow && reports?.length > 5)) && (
        <div className="flex xxs:flex-col md:flex-row xxs:space-y-4 md:space-y-0 items-center justify-between bg-[var(--color-white)] mt-4 py-5 px-4">
          <span className="text-[var(--color-dark-grey)] text-sm font-medium">
            Showing {page * rowsPerPage + 1} to{" "}
            {reports?.length > 0
              ? Math.min((page + 1) * rowsPerPage, reports.length)
              : 0}{" "}
            of {reports?.length || 0} entries
          </span>
          <Pagination
            count={totalPages}
            page={Math.min(page + 1, totalPages || 1)}
            onChange={handlePageChange}
            siblingCount={1}
            sx={{
              "& .MuiPaginationItem-root": {
                backgroundColor:
                  theme.palette.mode === "light"
                    ? "#D9D9D94D" // Light mode background
                    : "#2A2D3E", // Dark mode background
                borderRadius: "4px",
                color:
                  theme.palette.mode === "light"
                    ? "#5F6177" // Light mode text color
                    : "#A1A1B1", // Dark mode text color
                fontWeight: "600",
                width: "36px", // Set a fixed width for consistency
                height: "36px", // Set a fixed height for consistency
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                margin: "4px 4px",
              },
              "& .MuiPaginationItem-root.Mui-selected": {
                backgroundColor: "#513ADC",
                color: "#FFFFFF",
                "&:hover": {
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? "#513ADC" // Light mode selected hover
                      : "#836FFF", // Dark mode selected hover
                },
              },
              "& .MuiPaginationItem-root.Mui-disabled": {
                backgroundColor:
                  theme.palette.mode === "light"
                    ? "#D9D9D94D" // Light mode disabled background
                    : "#1E1E2F", // Dark mode disabled background
                color:
                  theme.palette.mode === "light"
                    ? "#B9B9B9" // Light mode disabled text
                    : "#5F6177", // Dark mode disabled text
              },
            }}
          />
        </div>
      )}

      {/* Action Menu */}
      <Menu
        id="action-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => {
          setAnchorEl(null);
          setSelectedReportId(null);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            borderRadius: "10px",
            boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.16)",
          },
        }}
      >
        {actionOptions.map((option) => (
          <MenuItem
            key={option}
            onClick={() => handleActionSelect(option, selectedReportId)}
            disabled={
              option === "Edit" &&
              reports.find((r) => r.report_id === selectedReportId)?.status ===
                "Finalized"
            }
            sx={{
              "&.Mui-disabled": {
                color: theme.palette.mode === "light" ? "#BDBDBD" : "#666666",
                cursor: "not-allowed",
              },
            }}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>

      <CommonDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={dialogTitle}
        content={dialogContent}
        buttonLabel={buttonLabel}
        showDeleteIcon={true}
        onConfirm={handleConfirm}
      />

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          style: {
            borderRadius: "10px",
            padding: "5px",
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            pt: 1,
            color: theme.palette.mode === "dark" ? "#F3F3F3" : "#333333",
          }}
          id="customized-dialog-title"
        >
          Share this report
          <p className="text-sm text-[var(--color-dark-grey)]">
            Share this report
          </p>
        </DialogTitle>
        <button
          onClick={handleClose}
          type="button"
          className="absolute top-0 right-0 m-4 p-1 bg-grey2 rounded-full"
        >
          <XMarkIcon
            className="h-5 w-5"
            strokeWidth={2}
            style={{ color: "#4F4F4F" }}
          />
        </button>

        <DialogContent>
          <div className="flex w-64 gap-x-10 pb-6 items-center justify-center">
            <div className="flex flex-col items-center">
              <div
                className="rounded-full w-16 h-16 flex justify-center items-center cursor-pointer"
                style={{ backgroundColor: "#FF45001A" }}
                onClick={() => handleShare("email")}
              >
                <GmailIcon
                  className="h-5 w-5"
                  aria-hidden="true"
                  strokeWidth={2}
                />
              </div>
              <p className="text-xs mt-1" style={{ color: "#FF4500" }}>
                G-mail
              </p>
            </div>
            {/* <div className="flex flex-col items-center">
              <div
                className="rounded-full w-16 h-16 flex justify-center items-center cursor-pointer"
                style={{ backgroundColor: "#25D3661A" }}
                onClick={() => handleShare("whatsapp")}
              >
                <WatsappIcon
                  className="h-6 w-6"
                  aria-hidden="true"
                  strokeWidth={2}
                  style={{ color: "#25D366" }}
                />
              </div>
              <p className="text-xs mt-1" style={{ color: "#25D366" }}>
                Whatsapp
              </p>
            </div> */}
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default DataTable;
