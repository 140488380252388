import React, { useState, useEffect, useRef, useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { TextField, InputAdornment, Pagination } from "@mui/material";
import CircleIcon from "@mui/icons-material/Circle";
import HistoryIcon from "@mui/icons-material/History";
import Checkbox from "../shared/Checkbox";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { enGB } from "date-fns/locale";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { formatDistanceToNow, format } from "date-fns";
import {
  getNotifications,
  markNotificationAsRead,
  deleteNotification,
  filterNotificationsByDate,
} from "../services/notificationsService";
import PrimaryButton from "../shared/PrimaryButton";

const NotificationsPage = () => {
  const theme = useTheme();

  // Separate refs for Unread and Read dropdowns
  const unreadNotificationRef = useRef(null);
  const readNotificationRef = useRef(null);
  const dateRangeRef = useRef(null);

  // Separate states for dropdown visibility
  const [showDatePickerDropdown, setShowDatePickerDropdown] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [showUnreadDropdown, setShowUnreadDropdown] = useState(false);
  const [showReadDropdown, setShowReadDropdown] = useState(false);

  const unreadNotificationOptions = ["Mark as Read", "Select All", "Delete"];
  const readNotificationOptions = ["Select All", "Delete"];

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // No need for useState since it's constant
  const [selectedUnreadNotifications, setSelectedUnreadNotifications] =
    useState([]);
  const [selectedReadNotifications, setSelectedReadNotifications] = useState(
    []
  );

  const [selectedTab, setSelectedTab] = useState("All");
  const [searchQuery, setSearchQuery] = useState("");

  const [dateRange, setDateRange] = useState([
    {
      startDate: null,
      endDate: null,
      key: "selection",
      color: "#513ADC",
    },
  ]);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    // Reset selections when changing tabs
    setSelectedUnreadNotifications([]);
    setSelectedReadNotifications([]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the unread dropdown
      if (
        unreadNotificationRef.current &&
        !unreadNotificationRef.current.contains(event.target)
      ) {
        setShowUnreadDropdown(false);
      }

      // Check if the click is outside the read dropdown
      if (
        readNotificationRef.current &&
        !readNotificationRef.current.contains(event.target)
      ) {
        setShowReadDropdown(false);
      }

      // Check if the click is outside the date range dropdown
      if (
        dateRangeRef.current &&
        !dateRangeRef.current.contains(event.target)
      ) {
        setShowDatePickerDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNotifications = useCallback(async () => {
    try {
      const response = await getNotifications();
      setNotifications(response.notifications);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  }, []);

  // Call handleNotifications as soon as the component loads
  useEffect(() => {
    handleNotifications();
  }, [handleNotifications]);

  const handleMarkAsRead = async (notificationIds) => {
    try {
      await markNotificationAsRead(notificationIds);
      handleNotifications();
      // Clear only unread selections
      setSelectedUnreadNotifications([]);
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      const notificationIds = notifications.map(
        (notification) => notification.id
      );
      await markNotificationAsRead(notificationIds); // Assuming this API marks notifications as read
      handleNotifications(); // Re-fetch notifications to reflect changes
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  };

  const handleDeleteNotifications = async (notificationIds) => {
    try {
      await deleteNotification(notificationIds); // Assuming this API deletes notifications
      handleNotifications(); // Re-fetch notifications to reflect changes
    } catch (error) {
      console.error("Error deleting notifications:", error);
    }
  };

  const handleDateFilter = useCallback(
    async (startDate, endDate) => {
      try {
        if (!startDate || !endDate) {
          // If either date is missing, fetch all notifications
          handleNotifications();
        } else {
          // Otherwise, filter notifications by the specified date range
          const response = await filterNotificationsByDate(startDate, endDate);
          setNotifications(response.notifications);
        }
      } catch (error) {
        console.error("Error fetching notifications by date:", error);
      }
    },
    [handleNotifications]
  );

  const unreadNotifications = notifications.filter(
    (notification) => !notification.is_read
  );
  const readNotifications = notifications.filter(
    (notification) => notification.is_read
  );

  // Decide which notifications to display based on selectedTab
  let displayedNotifications = [];
  if (selectedTab === "All") {
    // Show all notifications
    displayedNotifications = notifications;
  } else if (selectedTab === "Unread") {
    // Show only unread
    displayedNotifications = unreadNotifications;
  } else if (selectedTab === "Read") {
    // Show only read
    displayedNotifications = readNotifications;
  }

  // Filter by search query (title or message)
  const query = searchQuery.toLowerCase();
  displayedNotifications = displayedNotifications.filter(
    (n) =>
      n.title.toLowerCase().includes(query) ||
      n.message.toLowerCase().includes(query)
  );

  // Now apply pagination to displayedNotifications
  const paginatedNotifications = displayedNotifications.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Split paginated notifications into unread/read (this helps keep the original sectioning)
  const paginatedUnread = paginatedNotifications.filter((n) => !n.is_read);
  const paginatedRead = paginatedNotifications.filter((n) => n.is_read);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Handlers for Unread Dropdown
  const handleUnreadActionSelect = (option) => {
    if (option === "Select All") {
      const unreadIds = paginatedUnread.map((notification) => notification.id);
      setSelectedUnreadNotifications(unreadIds); // Select all on the current page
    } else if (option === "Delete") {
      handleDeleteNotifications(selectedUnreadNotifications);
    } else if (option === "Mark as Read") {
      handleMarkAsRead(selectedUnreadNotifications);
    }

    setShowUnreadDropdown(false);
  };

  // Handlers for Read Dropdown
  const handleReadActionSelect = (option) => {
    if (option === "Select All") {
      const readIds = paginatedRead.map((notification) => notification.id);
      setSelectedReadNotifications(readIds); // Select all on the current page
    } else if (option === "Delete") {
      handleDeleteNotifications(selectedReadNotifications);
    }

    setShowReadDropdown(false);
  };

  const dateRangeText =
    dateRange[0].startDate && dateRange[0].endDate
      ? `${dateRange[0].startDate.toDateString()} - ${dateRange[0].endDate.toDateString()}`
      : "All Dates";

  return (
    <div>
      <div>
        {/* Heading */}
        <div className="flex p-6 xxs:flex-col sm:flex-row xxs:space-y-4 justify-between sm:items-center">
          <div className="flex items-center xxs:w-2/3 lg:w-1/3">
            <TextField
              variant="outlined"
              placeholder="Search title or message..."
              value={searchQuery}
              onChange={(e) => {
                setSearchQuery(e.target.value);
                setCurrentPage(1); // Reset to first page on search change
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1px solid #C9CBCD",
                    borderRadius: "8px !important",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid #5F6177",
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{
                        color:
                          theme.palette.mode === "light" ? "#11171999" : "grey",
                      }}
                    />
                  </InputAdornment>
                ),
                style: {
                  backgroundColor:
                    theme.palette.mode === "dark" ? "transparent" : "white",
                  borderRadius: "8px",
                  height: "50px",
                  fontSize: "14px",
                },
              }}
              className="w-full"
            />
            <button className="ml-4 p-3 rounded-lg bg-primary">
              <SearchIcon sx={{ color: "white" }} />
            </button>
          </div>
          <button
            className="p-2 px-10 w-max rounded-md bg-[#FFFFFF4D] dark:bg-white-light text-primary"
            style={{
              boxShadow: "0px 4px 8px 0px #00000012",
            }}
            onClick={handleMarkAllAsRead}
          >
            Mark all as read
          </button>
        </div>

        <div
          className="flex xxs:flex-col md:flex-row xxs:space-y-4 md:space-y-0 md:items-center justify-between bg-[var(--color-white)] p-4 mb-4"
          style={{ boxShadow: "0px 2px 6px 0px #00000024" }}
        >
          {/* Notification Tabs */}
          <div className="flex flex-wrap xxs:items-start gap-4 md:w-1/2 ml-2">
            {["All", "Unread", "Read"].map((tab) => (
              <button
                key={tab}
                onClick={() => handleTabClick(tab)}
                className={`p-2 px-4 font-medium rounded-md ${
                  selectedTab === tab
                    ? "bg-primary text-white"
                    : "text-blue-text"
                }`}
              >
                {tab}
              </button>
            ))}
          </div>

          {/* Date Range Picker */}
          <div className="mr-2 relative cursor-pointer" ref={dateRangeRef}>
            <div
              className="p-2 text-sm border border-grey rounded-md w-max"
              onClick={() => setShowDatePickerDropdown(!showDatePickerDropdown)}
            >
              <span className="text-[#283037B2] dark:text-grey">
                Date Range:
              </span>
              <span className="text-[var(--color-text1)]">
                {" "}
                {dateRangeText}
              </span>
              <CalendarMonthOutlinedIcon
                sx={{ color: "#A5A5A5", ml: 1, mb: 0.5, fontSize: "18px" }}
              />
            </div>

            {showDatePickerDropdown && (
              <div
                className="absolute z-10 top-full md:right-0 mt-1 bg-[var(--color-white)] rounded-lg"
                style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
              >
                <DateRangePicker
                  ranges={dateRange}
                  onChange={(item) => {
                    const startDate = format(
                      item.selection.startDate,
                      "yyyy-MM-dd"
                    );
                    const endDate = format(
                      item.selection.endDate,
                      "yyyy-MM-dd"
                    );
                    setDateRange([item.selection]);
                    handleDateFilter(startDate, endDate);
                  }}
                  locale={enGB}
                />

                <div className="flex justify-end p-2">
                  <PrimaryButton
                    label="Clear"
                    className="w-32"
                    onClick={() => {
                      // Reset the date range to an empty range
                      setDateRange([
                        {
                          startDate: null,
                          endDate: null,
                          key: "selection",
                          color: "#513ADC",
                        },
                      ]);

                      // Call the filter function with no dates to reset
                      handleDateFilter(null, null);

                      // Close the dropdown
                      setShowDatePickerDropdown(false);
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Notifications List */}
        <div className="rounded-lg bg-[var(--color-white)] xxs:m-4 md:m-6">
          {notifications.length === 0 ? (
            <div className="p-4 flex justify-center items-center text-base">
              No notifications available
            </div>
          ) : (
            <div>
              {/* Show Unread Section only if selectedTab is "All" or "Unread" */}
              {["All", "Unread"].includes(selectedTab) &&
                paginatedUnread.length > 0 && (
                  <>
                    <div className="flex items-center justify-between p-4">
                      <div className="text-blue-text medium-text p-2">
                        Unread
                      </div>

                      <div className="relative" ref={unreadNotificationRef}>
                        <button
                          className="ml-4 text-gray-500"
                          onClick={() =>
                            setShowUnreadDropdown(!showUnreadDropdown)
                          }
                        >
                          <MoreVertIcon sx={{ fontSize: "18px" }} />
                        </button>
                        {showUnreadDropdown && (
                          <div
                            className="absolute top-full right-0 z-10 mt-1 bg-[var(--color-white)] w-28 rounded-[10px]"
                            style={{
                              border: "1px solid #E7E7E7",
                              boxShadow: "0px 4px 16px 0px #00000029",
                            }}
                          >
                            {unreadNotificationOptions.map((option) => (
                              <p
                                key={option}
                                className={`cursor-pointer border-b last:border-b-0 small-text m-0 p-2 hover:bg-grey2 dark:hover:bg-white-light ${
                                  option === "Mark as Read"
                                    ? "rounded-t-[10px]" // Top rounded corners for the first item
                                    : option === "Delete"
                                    ? "rounded-b-[10px]" // Bottom rounded corners for the last item
                                    : ""
                                }`}
                                onClick={() => {
                                  handleUnreadActionSelect(option);
                                }}
                              >
                                {option}
                              </p>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Unread Notifications */}
                    {paginatedUnread.map((notification) => (
                      <div
                        key={notification.id}
                        className="flex items-start p-4 border-b border-light-grey hover:bg-background dark:hover:bg-white-light"
                      >
                        <div className="flex items-center">
                          <Checkbox
                            size="small"
                            checked={selectedUnreadNotifications.includes(
                              notification.id
                            )}
                            onChange={(e) => {
                              if (e.target.checked) {
                                // Add to selectedUnreadNotifications
                                setSelectedUnreadNotifications((prev) => [
                                  ...prev,
                                  notification.id,
                                ]);
                              } else {
                                // Remove from selectedUnreadNotifications
                                setSelectedUnreadNotifications((prev) =>
                                  prev.filter((id) => id !== notification.id)
                                );
                              }
                            }}
                          />
                          <CircleIcon
                            sx={{
                              color: "#007AFF",
                              fontSize: { xxs: "8px", md: "12px" },
                              ml: -1.5,
                              mr: 1.5,
                            }}
                          />
                        </div>
                        <div className="-mt-0.5 flex-1">
                          <h3 className="text-[var(--color-text1)] medium-text">
                            {notification.title}
                          </h3>
                          <p className="text-sm mt-1 text-[var(--color-dark-grey)]">
                            {notification.message}
                          </p>
                          <p className="flex items-center mt-1 text-xs text-[var(--color-dark-grey)]">
                            <HistoryIcon sx={{ mr: 1, fontSize: "14px" }} />
                            {formatDistanceToNow(
                              new Date(notification.created_at),
                              {
                                addSuffix: true,
                              }
                            ).replace(/^about /, "")}
                          </p>
                        </div>
                      </div>
                    ))}
                  </>
                )}

              {["Unread"].includes(selectedTab) &&
                paginatedUnread.length === 0 && (
                  <div className="p-4 flex justify-center items-center text-base">
                    No unread notifications available
                  </div>
                )}

              {/* Show Read Section only if selectedTab is "All" or "Read" */}
              {["All", "Read"].includes(selectedTab) &&
                paginatedRead.length > 0 && (
                  <>
                    <div className="flex items-center justify-between p-4">
                      <div className="text-blue-text medium-text p-2">Read</div>

                      <div className="relative" ref={readNotificationRef}>
                        <button
                          className="ml-4 text-gray-500"
                          onClick={() => setShowReadDropdown(!showReadDropdown)}
                        >
                          <MoreVertIcon sx={{ fontSize: "18px" }} />
                        </button>
                        {showReadDropdown && (
                          <div
                            className="absolute top-full right-0 z-10 mt-1 bg-[var(--color-white)] w-24 rounded-[10px]"
                            style={{
                              border: "1px solid #E7E7E7",
                              boxShadow: "0px 4px 16px 0px #00000029",
                            }}
                          >
                            {readNotificationOptions.map((option) => (
                              <p
                                key={option}
                                className={`cursor-pointer border-b last:border-b-0 small-text m-0 p-2 hover:bg-grey2 dark:hover:bg-white-light ${
                                  option === "Select All"
                                    ? "rounded-t-[10px]" // Top rounded corners for the first item
                                    : option === "Delete"
                                    ? "rounded-b-[10px]" // Bottom rounded corners for the last item
                                    : ""
                                }`}
                                onClick={() => {
                                  handleReadActionSelect(option);
                                }}
                              >
                                {option}
                              </p>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Read Notifications */}
                    {paginatedRead.map((notification) => (
                      <div
                        key={notification.id}
                        className="flex items-start p-4 border-b border-light-grey hover:bg-background dark:hover:bg-white-light"
                      >
                        <div className="flex items-center">
                          <Checkbox
                            size="small"
                            checked={selectedReadNotifications.includes(
                              notification.id
                            )}
                            onChange={(e) => {
                              if (e.target.checked) {
                                // Add to selectedReadNotifications
                                setSelectedReadNotifications((prev) => [
                                  ...prev,
                                  notification.id,
                                ]);
                              } else {
                                // Remove from selectedReadNotifications
                                setSelectedReadNotifications((prev) =>
                                  prev.filter((id) => id !== notification.id)
                                );
                              }
                            }}
                          />
                        </div>
                        <div className="-mt-1 flex justify-between w-full">
                          <div className="flex-1">
                            <h3 className="text-[var(--color-text1)] medium-text">
                              {notification.title}
                            </h3>
                            <p className="text-sm mt-1 text-[var(--color-dark-grey)]">
                              {notification.message}
                            </p>
                            <p className="flex items-center mt-1 text-xs text-[var(--color-dark-grey)]">
                              <HistoryIcon sx={{ mr: 1, fontSize: "14px" }} />
                              {formatDistanceToNow(
                                new Date(notification.created_at),
                                {
                                  addSuffix: true,
                                }
                              ).replace(/^about /, "")}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}

              {["Read"].includes(selectedTab) && paginatedRead.length === 0 && (
                <div className="p-4 flex justify-center items-center text-base">
                  No read notifications available
                </div>
              )}

              <div className="mt-2 flex items-center justify-end">
                {displayedNotifications.length > itemsPerPage && (
                  <Pagination
                    count={Math.ceil(
                      displayedNotifications.length / itemsPerPage
                    )}
                    page={currentPage}
                    onChange={handlePageChange}
                    color="primary"
                    sx={{ marginTop: "16px", marginBottom: "16px" }}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NotificationsPage;
