import React, { useState, useEffect } from "react";
import Navbar from "../components/navigation/Navbar";
import Sidebar from "../components/navigation/Sidebar";
import { Outlet, useLocation } from "react-router-dom"; // Import useLocation

const Layout = ({ toggleDarkMode, isDarkMode, handleLogout }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false); // For toggling sidebar on mobile
  const location = useLocation(); // Get current location

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    // Determine the current path and set the selected item accordingly
    const currentPath = location.pathname;
    if (!currentPath.startsWith("/edit-report")) {
      if (currentPath.startsWith("/create-report")) {
        setSelectedItem("Create Report");
      } else if (currentPath === "/dashboard") {
        setSelectedItem("Dashboard");
      } else if (
        currentPath === "/report-management" ||
        currentPath.startsWith("/report-details")
      ) {
        setSelectedItem("Report Management");
      } else if (
        currentPath === "/user-management" ||
        currentPath.startsWith("/user-details") ||
        currentPath.startsWith("/edit-user")
      ) {
        setSelectedItem("User Management");
      } else if (
        currentPath === "/lab-management" ||
        currentPath.startsWith("/lab-details")
      ) {
        setSelectedItem("Lab Management");
      } else if (currentPath === "/support") {
        setSelectedItem("Support");
      } else if (currentPath.startsWith("/settings")) {
        setSelectedItem("Settings");
      } else {
        setSelectedItem("Dashboard"); // Default fallback
      }
    }
  }, [location.pathname]); // Run this effect when the path changes

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar - Hidden on small screens, visible on larger screens */}
      <Sidebar
        selectedItem={selectedItem}
        setSelectedItem={setSelectedItem}
        isSidebarOpen={isSidebarOpen}
        toggleSidebar={toggleSidebar} // Pass toggle function
      />

      {/* Main content area */}
      <div className="flex-1 flex flex-col overflow-y-auto">
        {/* Navbar */}
        <Navbar
          toggleSidebar={toggleSidebar}
          toggleDarkMode={toggleDarkMode}
          isDarkMode={isDarkMode}
          handleLogout={handleLogout}
        />{" "}
        {/* Pass toggle function */}
        {/* Dynamic content based on route */}
        <div className="flex-1 bg-[var(--color-bg)] overflow-y-auto">
          <Outlet /> {/* This will render the component based on the route */}
        </div>
      </div>
    </div>
  );
};

export default Layout;
