import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { TextField, InputAdornment } from "@mui/material";
import StatsCardAdmin from "../components/dashboard/StatsCardAdmin";
import PrimaryButton from "../shared/PrimaryButton";
import { ReactComponent as LmIcon } from "../assets/lm.svg";
import { ChevronRightIcon } from "@heroicons/react/24/outline";
import LabDataTable from "../shared/LabDataTable";
import { getAdminDashboard } from "../services/userService";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

function DashboardPageAdmin() {
  const theme = useTheme();
  const [rowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [selectedTime, setSelectedTime] = useState("all");
  const [searchInput, setSearchInput] = useState("");
  const [dashboardData, setDashboardData] = useState(null);
  const labs = dashboardData?.recent_labs || {};
  const statistics = dashboardData?.statistics || {};
  const labReports = dashboardData?.lab_report_data || [];
  const genderCounts = dashboardData?.gender_counts || {};

  // Transform gender_counts into an array for the chart
  const usersData = Object.entries(genderCounts).map(([month, counts]) => ({
    month, // Use the month name
    Male: counts.Male,
    Female: counts.Female,
    Other: counts.Other,
  }));

  const totalPages = Math.ceil((labReports?.length || 0) / rowsPerPage);

  const filteredLabReports = labReports.filter((lab) =>
    lab.lab_name.toLowerCase().includes(searchInput.toLowerCase())
  );

  // Function to fetch dashboard data
  const fetchDashboardData = useCallback(async () => {
    try {
      const data = await getAdminDashboard(selectedTime); // Pass filter to the API
      setDashboardData(data);
    } catch (err) {
      console.error("Failed to fetch dashboard data:", err);
    }
  }, [selectedTime]);

  // Fetch data when component mounts or time filter changes
  useEffect(() => {
    fetchDashboardData();
  }, [fetchDashboardData]);

  const handlePageChange = (event, newPage) => {
    setPage(newPage - 1);
  };

  const navigate = useNavigate();
  return (
    <div className="p-6 gap-4">
      <div className="grid md:grid-cols-3 xxs:grid-cols-1 md:gap-x-6 xxs:gap-x-0 gap-y-6">
        <div className="col-span-2">
          <StatsCardAdmin
            statistics={statistics}
            selectedTime={selectedTime}
            onTimeChange={setSelectedTime}
          />
        </div>
        <div
          className="flex flex-col justify-between space-y-6 w-full bg-[var(--color-white)] p-4 rounded-[10px]"
          style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
        >
          <p className="text-[var(--color-dark-grey)] medium-text">
            Generate a detailed radiology report with just a few clicks.
          </p>
          <PrimaryButton
            type="submit"
            label="Manage Laboratory"
            rightIcon={<LmIcon className="w-6 h-6 -mt-1" />}
            className="w-full"
            onClick={() => {
              navigate("/lab-management");
            }}
          />
        </div>
      </div>

      {/* Users Chart */}
      <div
        className="bg-[var(--color-white)] p-4 rounded-[10px] mt-6"
        style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
      >
        <div className="text-lg font-medium text-blue-text mb-4">Users</div>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={usersData}
            barCategoryGap="25%" // Reduce the bar width
            barGap={5} // Add spacing between bars in the same group
          >
            <XAxis dataKey="month" stroke={theme.palette.mode === "dark" ? "#B9B9B9" : "#5F6177"} />
            <Tooltip />
            <Legend
              wrapperStyle={{
                display: "flex",
                justifyContent: "start",
              }}
              content={(props) => {
                const { payload } = props; // The legend items
                return (
                  <div
                    style={{ display: "flex", gap: "20px", marginTop: "10px" }}
                  >
                    {payload.map((entry, index) => (
                      <div
                        key={`item-${index}`}
                        style={{ display: "flex", alignItems: "center", marginTop: "15px" }}
                      >
                        {/* Smaller circle icon */}
                        <div
                          style={{
                            width: 10, // Adjust the size of the circle
                            height: 10, // Adjust the size of the circle
                            backgroundColor: entry.color,
                            borderRadius: "50%",
                            marginRight: 8,
                          }}
                        />
                        <span style={{ color: theme.palette.mode === "dark" ? "#B9B9B9" : "#5F6177", fontSize: 14 }}>
                          {entry.value}
                        </span>
                      </div>
                    ))}
                  </div>
                );
              }}
            />
            <Bar dataKey="Male" fill="#00BAD1" />
            <Bar dataKey="Female" fill="#7786A3" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      <div
        className="w-full mt-6 rounded-[10px]"
        style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
      >
        <div className="bg-[var(--color-white)] rounded-t-[10px] flex items-center justify-between p-3">
          <span className="text-lg font-medium text-blue-text">
            Recently Added Labs
          </span>
          <div
            className="px-6 py-1.5 rounded-md flex space-x-2 items-center border border-dark-grey text-[var(--color-dark-grey)] text-sm cursor-pointer"
            onClick={() => {
              navigate("/lab-management");
            }}
          >
            <div className="flex items-start">
              <p>View All</p>
            </div>
            <ChevronRightIcon className="w-4 h-4" />
          </div>
        </div>
        <LabDataTable rows={5} labs={labs} />
      </div>

      <div className="w-full mt-6 rounded-[10px]">
        <div className="bg-[var(--color-white)] rounded-t-[10px] flex items-center justify-between p-3">
          <span className="text-lg font-medium text-blue-text">
            Overall Reports Overview{" "}
            <span className="rounded-full mx-2 px-3.5 py-1.5 bg-[#E9F4FF] text-[#7367F0] medium-text">
              {statistics?.total_reports}
            </span>
          </span>
          <div className="flex w-2/5 sm:justify-end">
            <TextField
              variant="outlined"
              placeholder="Enter Lab Name..."
              value={searchInput} // Bind to search input state
              onChange={(e) => setSearchInput(e.target.value)}
              className="w-full"
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    border: "1px solid #5F6177",
                    borderRadius: "8px !important",
                  },
                  "&.Mui-focused fieldset": {
                    border: "1px solid #5F6177",
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      sx={{
                        color:
                          theme.palette.mode === "light" ? "#11171999" : "grey",
                      }}
                    />
                  </InputAdornment>
                ),
                style: {
                  backgroundColor:
                    theme.palette.mode === "dark" ? "transparent" : "white",
                  borderRadius: "8px",
                  height: "45px",
                  fontSize: "14px",
                },
              }}
            />
            <button className="ml-2 p-2.5 rounded-lg bg-primary">
              <SearchIcon sx={{ color: "white" }} />
            </button>
          </div>
        </div>
        <TableContainer
          sx={{
            backgroundColor:
              theme.palette.mode === "light" ? "white" : "#1A1A1A",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>LAB ID</TableCell>
                <TableCell>LABORATORY NAME</TableCell>
                <TableCell>ECG</TableCell>
                <TableCell>MRI</TableCell>
                <TableCell>X-RAY</TableCell>
                <TableCell>CT-SCAN</TableCell>
                <TableCell>SONOGRAPHY</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(filteredLabReports) &&
              filteredLabReports.length > 0 ? (
                filteredLabReports
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((lab, index) => (
                    <TableRow key={lab.lab_id} style={{ cursor: "pointer" }}>
                      <TableCell>{lab.lab_id}</TableCell>
                      <TableCell
                        sx={{
                          fontWeight: "500",
                          fontSize: { xxs: "14px", md: "16px" },
                        }}
                      >
                        {lab.lab_name}
                      </TableCell>
                      <TableCell>{lab.ecg}</TableCell>
                      <TableCell>{lab.mri}</TableCell>
                      <TableCell>{lab.x_ray}</TableCell>
                      <TableCell>{lab.ct_scan}</TableCell>
                      <TableCell>{lab.sonography}</TableCell>
                    </TableRow>
                  ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} style={{ textAlign: "center" }}>
                    No lab reports available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {filteredLabReports?.length > 5 && (
          <div className="flex xxs:flex-col md:flex-row xxs:space-y-4 md:space-y-0 items-center justify-between bg-[var(--color-white)] mt-4 py-3 px-4">
            <span className="text-[var(--color-dark-grey)] text-sm font-medium">
              Showing {page * rowsPerPage + 1} to{" "}
              {Math.min((page + 1) * rowsPerPage, filteredLabReports?.length)}{" "}
              of {filteredLabReports?.length} entries
            </span>
            <Pagination
              count={Math.ceil(filteredLabReports.length / rowsPerPage)}
              page={Math.min(page + 1, totalPages || 1)}
              onChange={handlePageChange}
              siblingCount={1}
              sx={{
                "& .MuiPaginationItem-root": {
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? "#D9D9D94D" // Light mode background
                      : "#2A2D3E", // Dark mode background
                  borderRadius: "4px",
                  color:
                    theme.palette.mode === "light"
                      ? "#5F6177" // Light mode text color
                      : "#A1A1B1", // Dark mode text color
                  fontWeight: "600",
                  width: "36px", // Set a fixed width for consistency
                  height: "36px", // Set a fixed height for consistency
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "4px 4px",
                },
                "& .MuiPaginationItem-root.Mui-selected": {
                  backgroundColor: "#513ADC",
                  color: "#FFFFFF",
                  "&:hover": {
                    backgroundColor:
                      theme.palette.mode === "light"
                        ? "#513ADC" // Light mode selected hover
                        : "#836FFF", // Dark mode selected hover
                  },
                },
                "& .MuiPaginationItem-root.Mui-disabled": {
                  backgroundColor:
                    theme.palette.mode === "light"
                      ? "#D9D9D94D" // Light mode disabled background
                      : "#1E1E2F", // Dark mode disabled background
                  color:
                    theme.palette.mode === "light"
                      ? "#B9B9B9" // Light mode disabled text
                      : "#5F6177", // Dark mode disabled text
                },
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default DashboardPageAdmin;
