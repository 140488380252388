import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Dropdown from "../shared/DropDown";
import TextInput from "../shared/TextInput";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PrimaryButton from "../shared/PrimaryButton";
import CancelButton from "../shared/CancelButton";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { updateUser } from "../services/userService";
import dayjs from "dayjs";

function EditUserPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [userDetails, setUserDetails] = useState(location.state?.user || {});
  const roleOptions = [
    { value: "RADIOLOGIST", label: "Radiologist" },
    { value: "LAB_ADMIN", label: "Lab Admin" },
  ];
  const [selectedRole, setSelectedRole] = useState(
    roleOptions.find((option) => option.value === location.state?.user?.role) ||
      null
  );
  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];

  const handleRoleChange = (role) => {
    setSelectedRole(role);
    setUserDetails((prevState) => ({ ...prevState, role: role.value }));
  };

  const handleInputChange = (field, value) => {
    setUserDetails((prevDetails) => ({
      ...prevDetails,
      [field]: value,
    }));
  };

  // Function to split the name into first and last name
  const splitName = (fullName) => {
    const nameParts = fullName.split(" ");
    if (nameParts.length === 1) {
      // If there's only one word, it's the first name
      return { firstName: nameParts[0], lastName: "" };
    }
    if (nameParts.length === 2) {
      // If there are two words, the first is the first name, the second is the last name
      return { firstName: nameParts[0], lastName: nameParts[1] };
    }
    // If there are more than two words, the first is the first name, and the rest are the last name
    return { firstName: nameParts[0], lastName: nameParts.slice(1).join(" ") };
  };

  const handleGenderChange = (selectedGender) => {
    handleInputChange("gender", selectedGender.value); // Update the gender value directly
  };

  const handleUpdateUser = async () => {
    try {
      // Join firstName and lastName before updating the user
      const fullName = `${userDetails.firstName} ${userDetails.lastName}`;

      const formattedDOB = userDetails.dateOfBirth
        ? userDetails.dateOfBirth.format("DD/MM/YYYY")
        : null;

      // Update userDetails with the fullName
      const updatedUserDetails = {
        user_id: userDetails.user_id, // Keep user_id to identify the user
        name: fullName,
        email: userDetails.email,
        role: userDetails.role,
        contact_no: userDetails.contact_no,
        dob: formattedDOB,
        gender: userDetails.gender,
        address: userDetails.address,
        country: userDetails.country,
      };

      if (updatedUserDetails.email === location.state?.user?.email) {
        delete updatedUserDetails.email;
      }

      // Send the updated userDetails to the API
      await updateUser(updatedUserDetails);
    } catch (error) {
      console.error("Error updating user:", error);
    }
    navigate("/user-management", { state: { fromEditUserPage: true } });
  };

  useEffect(() => {
    // On initial load, split the name into first and last name
    if (userDetails.name) {
      const { firstName, lastName } = splitName(userDetails.name);
      setUserDetails((prevDetails) => ({
        ...prevDetails,
        firstName: firstName,
        lastName: lastName,
      }));
    }
  }, [userDetails.name]);

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z\s]*$/;
    return nameRegex.test(name);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(phone);
  };

  const validateCountry = (country) => {
    const countryRegex = /^[a-zA-Z\s]*$/;
    return countryRegex.test(country);
  };

  const isFormValid = () => {
    return (
      validateName(userDetails.firstName) &&
      validateName(userDetails.lastName) &&
      validateEmail(userDetails.email) &&
      validatePhoneNumber(userDetails.contact_no) &&
      validateCountry(userDetails.country)
    );
  };

  return (
    <div>
      <div className="flex items-center px-6 py-4 mt-4 bg-[var(--color-white)] text-lg text-[var(--color-primary)]">
        Select Role
      </div>
      <div className="m-6 p-4 bg-[var(--color-white)] rounded-lg">
        <Dropdown
          id="role"
          label="Role"
          showLabel={true}
          options={roleOptions}
          selectedOption={selectedRole}
          onChange={handleRoleChange}
          required={true}
        />
      </div>

      <div className="flex items-center px-6 py-4 mt-4 bg-[var(--color-white)] text-lg text-[var(--color-primary)]">
        User Details
      </div>

      <div className="m-6 p-6 bg-[var(--color-white)] rounded-lg">
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-y-6 gap-x-12">
          <TextInput
            id="firstName"
            label="First Name"
            value={userDetails.firstName}
            onChange={(e) => handleInputChange("firstName", e.target.value)}
            error={!validateName(userDetails.firstName)}
            helperText={
              validateName(userDetails.firstName)
                ? ""
                : "Name can only contain letters"
            }
          />

          <TextInput
            id="lastName"
            label="Last Name"
            value={userDetails.lastName}
            onChange={(e) => handleInputChange("lastName", e.target.value)}
            error={!validateName(userDetails.lastName)}
            helperText={
              validateName(userDetails.lastName)
                ? ""
                : "Name can only contain letters"
            }
          />

          <div>
            <Dropdown
              id="gender"
              label="Gender"
              showLabel={true}
              options={genderOptions}
              selectedOption={genderOptions.find(
                (option) => option.value === userDetails.gender
              )}
              onChange={handleGenderChange}
            />
          </div>

          <div className="w-full">
            <span className="text-sm text-[var(--color-dark-grey)]">
              DOB (DD/MM/YY)
            </span>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                value={userDetails.dob ? dayjs(userDetails.dob) : null}
                onChange={(newValue) => {
                  handleInputChange(
                    "dateOfBirth",
                    newValue ? dayjs(newValue) : null
                  );
                  setOpen(false);
                }}
                maxDate={dayjs()}
                slotProps={{
                  textField: {
                    size: "small",
                    placeholder: "Choose Date",
                    sx: {
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "1px solid #5F6177",
                          borderRadius: "8px !important",
                        },
                        "&.Mui-focused fieldset": {
                          border: "1px solid #5F6177",
                          borderRadius: "8px !important",
                        },
                      },
                    },
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={() => setOpen(true)}>
                            <CalendarMonthOutlinedIcon sx={{ ml: 0 }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  },
                }}
              />
            </LocalizationProvider>
          </div>

          <TextInput
            id="email"
            label="Email"
            value={userDetails.email}
            onChange={(e) => handleInputChange("email", e.target.value)}
            required={true}
            error={!validateEmail(userDetails.email)}
            helperText={
              validateEmail(userDetails.email) ? "" : "Email is required"
            }
          />

          <TextInput
            id="contactNumber"
            label="Contact Number"
            type="number"
            value={userDetails.contact_no}
            onChange={(e) => handleInputChange("contact_no", e.target.value)}
            required={true}
            error={!validatePhoneNumber(userDetails.contact_no)}
            helperText={
              validatePhoneNumber(userDetails.contact_no)
                ? ""
                : "Contact number must be 10 digits"
            }
          />

          <TextInput
            id="address"
            label="Address"
            value={userDetails.address}
            onChange={(e) => handleInputChange("address", e.target.value)}
          />

          <TextInput
            id="country"
            label="Country"
            value={userDetails.country}
            onChange={(e) => handleInputChange("country", e.target.value)}
            error={!validateCountry(userDetails.country)}
            helperText={
              validateCountry(userDetails.country)
                ? ""
                : "Country can only contain letters"
            }
          />
        </div>
      </div>

      <div
        className="bottom-0 flex bg-[var(--color-white)]"
        style={{ boxShadow: "0px -2px 6px 0px #00000024" }}
      >
        <div className="flex flex-row justify-between xxs:p-2 md:p-6 w-full">
          <div>
            <CancelButton
              type="button"
              label="Cancel"
              className="md:w-40"
              onClick={() => {
                navigate(-1);
              }}
            />
          </div>
          <div>
            <PrimaryButton
              type="button"
              label="Save Changes"
              className="md:w-40"
              disabled={!isFormValid()}
              onClick={handleUpdateUser}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditUserPage;
