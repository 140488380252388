import axios from "axios";

export const BASE_URL = process.env.REACT_APP_BASE_URL || "http://127.0.0.1:8000";

export const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Define the routes where tokens should NOT be sent
const AUTH_ROUTES = [
  "/auth/register",
  "/auth/login",
  "/auth/forgotpassword",
  "/auth/verifyotp",
  "/auth/resetpassword",
];

// Add an interceptor to attach the authToken to every request
apiClient.interceptors.request.use((config) => {
  // const currentUser = localStorage.getItem("currentUser"); // Get the current user
  const token =
    localStorage.getItem("authToken") || sessionStorage.getItem("authToken");

  // Attach token only if the URL is not an auth route
  if (token && !AUTH_ROUTES.some((route) => config.url.startsWith(route))) {
    config.headers.Authorization = `Token ${token}`;
  }

  return config;
});

export default apiClient;
