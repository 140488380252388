import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ReactComponent as MicIcon } from "../assets/mic.svg";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useVoiceVisualizer, VoiceVisualizer } from "react-voice-visualizer";
import ReactDOMServer from "react-dom/server";
import { useLab } from "../contexts/LabContext";
import { getReportById } from "../services/reportService";

import {
  DecoupledEditor,
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoLink,
  Autosave,
  BalloonToolbar,
  BlockQuote,
  Bold,
  Essentials,
  FindAndReplace,
  FontBackgroundColor,
  FontColor,
  FontFamily,
  FontSize,
  GeneralHtmlSupport,
  Heading,
  HorizontalLine,
  Indent,
  IndentBlock,
  Italic,
  Link,
  Paragraph,
  RemoveFormat,
  Strikethrough,
  Style,
  TextTransformation,
  Underline,
  Undo,
} from "ckeditor5";

function RichTextEditor({
  fromSubmitStep,
  reportType,
  reportid,
  onContentChange,
  editorContent,
  fromSettings,
}) {
  const { lab } = useLab();
  const location = useLocation();
  const reportId = location.state?.reportId || reportid || null;
  const [content, setContent] = useState(editorContent);
  const editorToolbarRef = useRef(null);
  const editorRef = useRef(null);
  const [isLayoutReady, setIsLayoutReady] = useState(false);

  const [open, setOpen] = useState(false);
  const recorderControls = useVoiceVisualizer();
  const {
    // ... (Extracted controls and states, if necessary)
    recordedBlob,
    error,
  } = recorderControls;

  // Get the recorded audio blob
  useEffect(() => {
    if (!recordedBlob) return;

    console.log(recordedBlob);
  }, [recordedBlob, error]);

  // Get the error when it occurs
  useEffect(() => {
    if (!error) return;

    console.error(error);
  }, [error]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setIsLayoutReady(true);

    return () => setIsLayoutReady(false);
  }, []);

  const editorConfig = {
    toolbar: {
      items: [
        "heading",
        "|",
        "fontSize",
        "fontColor",
        "|",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "|",
        "alignment",
        "|",
        "outdent",
        "indent",
        "|",
        "horizontalLine",
        "removeFormat",
      ],
      shouldNotGroupWhenFull: false,
    },
    plugins: [
      AccessibilityHelp,
      Alignment,
      Autoformat,
      AutoLink,
      Autosave,
      BalloonToolbar,
      BlockQuote,
      Bold,
      Essentials,
      FindAndReplace,
      FontBackgroundColor,
      FontColor,
      FontFamily,
      FontSize,
      GeneralHtmlSupport,
      Heading,
      HorizontalLine,
      Indent,
      IndentBlock,
      Italic,
      Link,
      Paragraph,
      RemoveFormat,
      Strikethrough,
      Style,
      TextTransformation,
      Underline,
      Undo,
    ],
    balloonToolbar: ["bold", "italic", "underline"],
    fontFamily: {
      supportAllValues: true,
    },
    fontSize: {
      options: [10, 12, 14, "default", 18, 20, 22, 24, 26, 28, 30],
      supportAllValues: true,
    },
    heading: {
      options: [
        {
          model: "paragraph",
          title: "Paragraph",
          class: "ck-heading_paragraph",
        },
        {
          model: "heading1",
          view: "h1",
          title: "Heading 1",
          class: "ck-heading_heading1",
        },
        {
          model: "heading2",
          view: "h2",
          title: "Heading 2",
          class: "ck-heading_heading2",
        },
        {
          model: "heading3",
          view: "h3",
          title: "Heading 3",
          class: "ck-heading_heading3",
        },
        {
          model: "heading4",
          view: "h4",
          title: "Heading 4",
          class: "ck-heading_heading4",
        },
        {
          model: "heading5",
          view: "h5",
          title: "Heading 5",
          class: "ck-heading_heading5",
        },
        {
          model: "heading6",
          view: "h6",
          title: "Heading 6",
          class: "ck-heading_heading6",
        },
      ],
    },
    htmlSupport: {
      allow: [
        {
          name: /^.*$/,
          styles: true,
          attributes: true,
          classes: true,
        },
      ],
    },
    link: {
      addTargetToExternalLinks: true,
      defaultProtocol: "https://",
      decorators: {
        toggleDownloadable: {
          mode: "manual",
          label: "Downloadable",
          attributes: {
            download: "file",
          },
        },
      },
    },
    menuBar: {
      isVisible: true,
    },
    placeholder: "Type or paste your content here!",
    style: {
      definitions: [
        {
          name: "Article category",
          element: "h3",
          classes: ["category"],
        },
        {
          name: "Title",
          element: "h2",
          classes: ["document-title"],
        },
        {
          name: "Subtitle",
          element: "h3",
          classes: ["document-subtitle"],
        },
        {
          name: "Info box",
          element: "p",
          classes: ["info-box"],
        },
        {
          name: "Side quote",
          element: "blockquote",
          classes: ["side-quote"],
        },
        {
          name: "Marker",
          element: "span",
          classes: ["marker"],
        },
        {
          name: "Spoiler",
          element: "span",
          classes: ["spoiler"],
        },
      ],
    },
  };

  const timingsArray = lab?.timings || null;

  // Function to format time (e.g., "10:00" => "10 AM")
  const formatTime = (time) => {
    const [hour, minute] = time.split(":");
    const ampm = hour >= 12 ? "PM" : "AM";
    const formattedHour = hour % 12 || 12; // Converts 24-hour to 12-hour format
    return `${formattedHour}:${minute} ${ampm}`;
  };

  // Format the timings if available
  const formattedTimings =
    timingsArray && timingsArray.length === 2
      ? `${formatTime(timingsArray[0])} to ${formatTime(timingsArray[1])}`
      : "----";

  const getStyledHTML = () => {
    const styledJSX = (
      <div className="bg-white">
        <div className="flex p-4 pt-8 pl-8 pr-8 justify-between">
          <div className="flex flex-col">
            <div className="flex items-top">
              <div className="w-10 h-10 bg-grey4"></div>
              <span className="ml-2 mt-1 text-[26px] font-medium text-text1">
                {lab?.lab_name ? lab?.lab_name : "----"}
              </span>
            </div>
            <div className="mt-2">
              <div>
                <span className="text-xs text-dark-grey">
                  Contact:
                </span>{" "}
                <span className="text-sm font-medium text-text1">
                  {lab?.lab_contact_no ? lab?.lab_contact_no : "----"}
                </span>
              </div>
              <div>
                <span className="text-xs text-dark-grey">
                  Venue:
                </span>{" "}
                <span className="text-sm font-medium text-text1">
                  {lab?.lab_location ? lab?.lab_location : "----"}
                </span>
              </div>
              <div>
                <span className="text-xs text-dark-grey">
                  Timing:
                </span>{" "}
                <span className="text-sm font-medium text-text1">
                  {formattedTimings}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start">
            <div>
              <span className="text-xs text-dark-grey">
                Patient Name:
              </span>{" "}
              <span className="text-sm font-medium text-text1">----</span>
            </div>
            <div>
              <span className="text-xs text-dark-grey">
                Contact:
              </span>{" "}
              <span className="text-sm font-medium text-text1">----</span>
            </div>
            <div>
              <span className="text-xs text-dark-grey">
                Gender:
              </span>{" "}
              <span className="text-sm font-medium text-text1">----</span>
            </div>
            <div>
              <span className="text-xs text-dark-grey">
                Age:
              </span>{" "}
              <span className="text-sm font-medium text-text1">----</span>
            </div>
            <div>
              <span className="text-xs text-dark-grey">
                Weight:
              </span>{" "}
              <span className="text-sm font-medium text-text1">----</span>
            </div>
            <div>
              <span className="text-xs text-dark-grey">
                Height:
              </span>{" "}
              <span className="text-sm font-medium text-text1">----</span>
            </div>
          </div>
        </div>
        <div className="text-center py-2">
          <div className="text-[20px] text-text1 border-y border-dark-grey py-2">
            {reportType} Report
          </div>
        </div>
        <div className="mt-4 mx-4 text-text1"></div>
      </div>
    );

    return ReactDOMServer.renderToStaticMarkup(styledJSX);
  };

  const fetchReportData = useCallback(async () => {
    try {
      const data = await getReportById(reportId); // Pass filter to the API
      setContent(data.report.final_report_html);
    } catch (err) {
      console.error("Failed to fetch dashboard data:", err);
    }
  }, [reportId]);

  // Fetch data when component mounts or time filter changes
  useEffect(() => {
    if (!fromSettings) {
      fetchReportData();
    }
  }, [fetchReportData, fromSettings]);

  return (
    <div>
      <div className="flex items-center justify-between p-1 m-6 bg-[var(--color-white)] rounded-full">
        <div ref={editorToolbarRef} className="rounded-full"></div>
        {fromSubmitStep && (
          <div className="mr-2">
            <button
              type="submit"
              className="flex items-center px-4 py-1.5 text-base bg-primary text-white font-semibold rounded-3xl"
              onClick={handleClickOpen}
            >
              Add Voice <MicIcon className="ml-2 w-5 h-5" />
            </button>
          </div>
        )}
      </div>

      <div
        ref={editorRef}
        className="bg-white m-6 min-h-screen editor-container__editor"
      >
        {isLayoutReady && (
          <CKEditor
            onReady={(editor) => {
              editorToolbarRef.current.appendChild(
                editor.ui.view.toolbar.element
              );
            }}
            onAfterDestroy={() => {
              if (editorToolbarRef.current) {
                Array.from(editorToolbarRef.current.children).forEach((child) =>
                  child.remove()
                );
              }
            }}
            editor={DecoupledEditor}
            config={editorConfig}
            data={content || getStyledHTML()}
            onChange={(event, editor) => {
              const data = editor.getData();
              setContent(data); // Update state with editor content
              onContentChange(data);
            }}
          />
        )}
      </div>

      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        sx={{
          "& .MuiDialog-paper": { backgroundColor: "#373737", width: "450px" },
          "& .MuiDialogContent-dividers": {
            borderColor: "#B9B9B9",
          },
        }}
      >
        <button
          onClick={handleClose}
          type="button"
          className="absolute top-0 right-0 m-4 p-1 rounded-full"
          style={{ backgroundColor: "#FFFFFF1A" }}
        >
          <XMarkIcon
            className="h-5 w-5 text-white"
            aria-hidden="true"
            strokeWidth={2}
          />
        </button>
        <DialogContent>
          <div className="flex flex-col w-full items-center justify-center">
            <VoiceVisualizer
              controls={recorderControls}
              mainBarColor="#FF4C51"
              secondaryBarColor="transparent"
              backgroundColor="transparent"
              barWidth={8}
              width={400}
              height={100}
            />
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default RichTextEditor;
