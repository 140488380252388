import React, { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import { PlusIcon } from "@heroicons/react/24/outline";
import ReportImg from "../../assets/report.png";
import CreateIcon from "@mui/icons-material/Create";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { XMarkIcon } from "@heroicons/react/24/outline";
import TextInput from "../../shared/TextInput";
import Dropdown from "../../shared/DropDown";
import PrimaryButton from "../../shared/PrimaryButton";
import CancelButton from "../../shared/CancelButton";
import RichTextEditor from "../../shared/RichTextEditor";
import CommonDialog from "../../shared/CommonDialog";
import { getReportTemplates } from "../../services/reportTemplateService";
import { getReportTemplateById } from "../../services/reportTemplateService";
import { createReportTemplates } from "../../services/reportTemplateService";
import { editReportTemplate } from "../../services/reportTemplateService";

function ReportTemplates() {
  const theme = useTheme();
  const [reportTemplates, setReportTemplates] = useState([]);
  const [open, setOpen] = useState(false);
  const [showEditor, setShowEditor] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);
  const [refreshReportTemplates, setRefreshReportTemplates] = useState(false);

  const reportOptions = [
    { value: "x_ray", label: "X-Ray" },
    { value: "mri", label: "MRI" },
    { value: "ecg", label: "ECG" },
    { value: "ct_scan", label: "CT-Scan" },
    { value: "sonography", label: "Sonography" },
  ];

  const [formData, setFormData] = useState({
    reportName: "",
    reportType: "",
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonLabel, setButtonLabel] = useState("");
  const [editorContent, setEditorContent] = useState("");

  // Callback to update the state
  const handleEditorChange = (data) => {
    setEditorContent(data);
  };

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [id]: value }));
  };

  const handleReportChange = (report) => {
    if (report) {
      setSelectedReport(report);
      setFormData((prevState) => ({
        ...prevState,
        reportType: report.value, // Set the report value correctly
      }));
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenDialog1 = () => {
    setDialogTitle("Report Finalized Successfully!");
    setDialogContent(
      "Et leo, enim in non sed quis sed. Auctor natoque auctor risus amet quis mauris. Interdum et nisi, pellentesque id lectus. Ut bibendum pellentesque arcu luctus sapien."
    );
    setButtonLabel("Okay");
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = async (e) => {
    handleOpenDialog1();
    setShowEditor(false);
    setRefreshReportTemplates((prev) => !prev);
  };

  const handleEditReport = (id) => {
    if (id) {
      getReportTemplateById(id).then((res) => {
        const matchingOption = reportOptions.find(
          (option) => option.value === res.report_type
        );

        setSelectedReport({
          ...res,
          label: matchingOption?.label || res.report_type, // Fallback to report_type if no match
        });
        setEditorContent(res.template_content);
        setShowEditor(true);
        setOpen(false);
      });
    } else {
      setShowEditor(true);
      setOpen(false);
    }
  };

  const handleEditorClose = () => {
    setSelectedReport(null);
    setEditorContent("");
    setShowEditor(false); // Close the editor and return to main view
  };

  const handleSaveTemplate = async (e) => {
    e.preventDefault();
    try {
      if (selectedReport.id) {
        const payload = {
          template_content: editorContent,
        };
        await editReportTemplate(selectedReport.id, payload);
      } else {
        const payload = {
          report_name: formData.reportName,
          report_type: formData.reportType,
          template_content: editorContent,
        };
        await createReportTemplates(payload);
      }
      handleOpenDialog1();
    } catch (error) {
      console.error("Error registering user:", error);
    }
  };

  useEffect(() => {
    const fetchReportTemplates = async () => {
      try {
        const response = await getReportTemplates(); // Call the getUser API
        setReportTemplates(response.reports); // Assume response.data contains the array of users
      } catch (error) {}
    };
    fetchReportTemplates();
  }, [refreshReportTemplates]);

  // Group reports by report_type
  const groupedReports = reportTemplates.reduce((acc, report) => {
    if (!acc[report.report_type]) acc[report.report_type] = [];
    acc[report.report_type].push(report);
    return acc;
  }, {});

  return (
    <>
      {!showEditor ? (
        <div>
          <div className="flex items-center px-6 py-4 mt-6 bg-[var(--color-white)] text-lg text-[var(--color-primary)]">
            Create report templates
          </div>
          <div className="flex xxs:flex-col sm:flex-row xxs:space-y-6 sm:space-y-0 m-6 p-6 items-center bg-[var(--color-white)] rounded-[10px]">
            <button
              className="flex items-center justify-center rounded-full bg-[#EEF7FF] text-primary p-10 mr-6 hover:bg-primary hover:text-white ease-in duration-300"
              onClick={handleClickOpen}
            >
              <PlusIcon className="h-8 w-8" strokeWidth={2.5} />
            </button>
            <div className="text-[var(--color-dark-grey)] pr-4">
              <p className="xxs:text-base md:text-[20px]">Create Report</p>
              <p className="medium-text">
                Create a detailed, user-friendly report template for
                radiologists that streamlines data entry, highlights key
                findings, and supports customizable sections for various imaging
                studies.
              </p>
            </div>
          </div>

          {Object.entries(groupedReports).map(([reportType, reportList]) => (
            <div key={reportType}>
              {/* Report Type Section Header */}
              <div className="flex items-center px-6 py-4 mt-6 bg-[var(--color-white)] text-lg text-[var(--color-primary)]">
                {reportOptions.find((option) => option.value === reportType)
                  ?.label || reportType}{" "}
                Reports All Templates
              </div>

              {/* Report Cards */}
              <div className="m-6 p-6 bg-[var(--color-white)] rounded-[10px] overflow-x-auto flex items-start gap-4">
                <div
                  className="flex flex-col space-y-6 cursor-pointer items-center justify-center border w-[150px] h-[200px] border-light-grey"
                  style={{
                    width: "150px",
                    height: "200px",
                    minWidth: "150px",
                    minHeight: "200px",
                    maxWidth: "150px",
                    maxHeight: "200px",
                  }}
                  onClick={handleClickOpen}
                >
                  <button className="flex items-center justify-center rounded-full bg-[#EEF7FF] text-primary p-2">
                    <PlusIcon className="h-9 w-9" />
                  </button>
                  <span className="text-[var(--color-text1)] medium-text">
                    Add More
                  </span>
                </div>

                {reportList.map((report) => (
                  <div key={report.id} className="cursor-pointer">
                    <div className="relative w-max border border-light-grey">
                      <div
                        className="flex items-center justify-center w-5 h-5 absolute -top-2 -right-2 hover:bg-[#CDDEF2] text-white bg-primary rounded-full"
                        onClick={() => handleEditReport(report.id)}
                      >
                        <CreateIcon sx={{ fontSize: 14 }} />
                      </div>
                      <img src={ReportImg} alt="report" />
                    </div>
                    <p className="text-center mt-1 text-[var(--color-dark-grey)]">
                      {report.report_name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}

          <Dialog
            onClose={handleClose}
            aria-labelledby="customized-dialog-title"
            open={open}
            PaperProps={{
              style: {
                borderRadius: "10px",
                padding: "5px",
                width: "500px",
              },
            }}
          >
            <DialogTitle
              sx={{
                m: 0,
                pt: 1,
                color: theme.palette.mode === "dark" ? "#F3F3F3" : "#333333",
                fontWeight: "500",
                fontSize: 24,
              }}
              id="customized-dialog-title"
            >
              Create Report
            </DialogTitle>
            <button
              onClick={handleClose}
              type="button"
              className="absolute top-0 right-0 m-4 p-1 bg-grey2 rounded-full"
            >
              <XMarkIcon
                className="h-5 w-5"
                strokeWidth={2}
                style={{ color: "#4F4F4F" }}
              />
            </button>

            <DialogContent>
              <div className="flex flex-col space-y-6 p-0 w-full">
                <TextInput
                  id="reportName"
                  type="text"
                  label="Report Name"
                  placeholder="Enter User Name"
                  value={formData.reportName}
                  onChange={handleInputChange}
                  required={true}
                />

                <Dropdown
                  id="reportType"
                  label="Report Type"
                  showLabel={true}
                  options={reportOptions}
                  selectedOption={selectedReport}
                  onChange={(selected) => handleReportChange(selected)}
                  required={true}
                  showFilter={true}
                />

                <PrimaryButton
                  type="submit"
                  label="Next"
                  className="w-full"
                  onClick={() => handleEditReport(null)}
                />
              </div>
            </DialogContent>
          </Dialog>
        </div>
      ) : (
        <div>
          <div className="flex-grow overflow-auto">
            <RichTextEditor
              fromSubmitStep={false}
              reportType={selectedReport.label}
              editorContent={editorContent}
              onContentChange={handleEditorChange}
              fromSettings={true}
            />
          </div>

          <div
            className="md:sticky bottom-0 right-0 z-10 flex bg-[var(--color-white)]"
            style={{ boxShadow: "0px -2px 6px 0px #00000024" }}
          >
            <div className="flex flex-row justify-between xxs:p-2 md:p-6 space-x-4 w-full">
              <div>
                <CancelButton
                  type="button"
                  label="Cancel"
                  className="md:w-32"
                  onClick={handleEditorClose}
                />
              </div>
              <div className="flex flex-row space-x-4">
                <PrimaryButton
                  type="button"
                  label={"Save Changes"}
                  className="md:w-32"
                  onClick={handleSaveTemplate}
                  showRightIcon={true}
                />
              </div>
            </div>
          </div>

          <CommonDialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            title={dialogTitle}
            content={dialogContent}
            buttonLabel={buttonLabel}
            showRightIcon={true}
            onConfirm={handleConfirm}
          />
        </div>
      )}
    </>
  );
}

export default ReportTemplates;
