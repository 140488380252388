import React, { useState, useEffect, useCallback, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import { ReactComponent as WavesIcon } from "../assets/waves.svg";
import { ReactComponent as DoctorIcon } from "../assets/doctor.svg";
import { ReactComponent as ReportsIcon } from "../assets/report-data.svg";
import { TextField, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { enGB } from "date-fns/locale";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import ReportsCard from "../shared/ReportsCard";
import { getLabDetails } from "../services/labService";

function LabDetailsPage() {
  const theme = useTheme();
  const location = useLocation();
  const lab = location.state?.lab;
  const dateRangeRef = useRef(null);
  const [labData, setLabData] = useState({});
  const [cardData, setCardData] = useState([]);

  const getStartOfWeek = (date) => {
    const day = date.getDay(); // Sunday - Saturday : 0 - 6
    const diff = date.getDate() - day + (day === 0 ? -6 : 1); // Adjust when day is Sunday
    return new Date(date.setDate(diff));
  };

  const getEndOfWeek = (date) => {
    const start = getStartOfWeek(new Date(date));
    const end = new Date(start);
    end.setDate(start.getDate() + 6);
    return end;
  };

  const [showDatePickerDropdown, setShowDatePickerDropdown] = useState(false);
  const [dateRange, setDateRange] = useState([
    {
      startDate: getStartOfWeek(new Date()),
      endDate: getEndOfWeek(new Date()),
      key: "selection",
      color: "#513ADC",
    },
  ]);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCardData, setFilteredCardData] = useState([]);

  const fetchLabData = useCallback(async () => {
    try {
      const startDate = dateRange[0].startDate.toISOString().split("T")[0];
      const endDate = dateRange[0].endDate.toISOString().split("T")[0];
      const data = await getLabDetails(lab?.lab_id, startDate, endDate);
      setLabData(data);
    } catch (err) {
      console.error("Failed to fetch dashboard data:", err);
    }
  }, [lab?.lab_id, dateRange]);

  // Fetch data when component mounts or time filter changes
  useEffect(() => {
    fetchLabData();
  }, [fetchLabData]);

  useEffect(() => {
    if (labData?.weekly_report_overview) {
      const transformedCardData = Object.entries(
        labData.weekly_report_overview
      ).map(([reportType, days]) => ({
        label: reportType,
        weeklyData: days,
      }));
      setCardData(transformedCardData);
      setFilteredCardData(transformedCardData);
    }
  }, [labData]);

  // Handle search input changes
  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredCardData(cardData); // Reset to original data
    } else {
      const lowerCaseQuery = searchQuery.toLowerCase();
      const filteredData = cardData.filter((card) =>
        card.label.toLowerCase().includes(lowerCaseQuery)
      );
      setFilteredCardData(filteredData);
    }
  }, [searchQuery, cardData]);

  // Optional: Handle search on button click (if you prefer this over live search)
  const handleSearch = () => {
    const lowerCaseQuery = searchQuery.toLowerCase();
    if (lowerCaseQuery === "") {
      setFilteredCardData(cardData);
    } else {
      const filteredData = cardData.filter((card) =>
        card.label.toLowerCase().includes(lowerCaseQuery)
      );
      setFilteredCardData(filteredData);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dateRangeRef.current &&
        !dateRangeRef.current.contains(event.target)
      ) {
        setShowDatePickerDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="m-6">
      <div className="flex xxs:flex-col md:flex-row xxs:space-x-0 md:space-x-6 md:space-y-0 xxs:space-y-6">
        <div
          className="bg-primary rounded-[10px] text-white p-5 xxs:w-full md:w-4/5"
          style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
        >
          <div className="flex items-center justify-between">
            <div>
              <p className="md:text-[20px] xxs:text-sm font-semibold">
                {labData?.lab_name ?? "----"}
              </p>
              <span className="small-text font-medium">Overview</span>
            </div>
            <div className="bg-purple rounded-md px-4 py-1">
              <span className="font-medium medium-text">
                {lab?.subscription_plan ?? "----"} Subscription
              </span>
            </div>
          </div>
          <div className="mt-6 flex flex-row items-center justify-between">
            <div>
              <span className="text-sm font-medium">Reports</span>
              <div className="flex flex-col justify-between space-y-4 mt-2">
                <div className="flex space-x-12 font-medium">
                  <div className="flex items-center">
                    <div className="bg-[#4608CA61] rounded-lg w-14 py-1 text-center">
                      {labData?.finalized_reports ?? 0}
                    </div>
                    <span className="medium-text ml-2">Finalized</span>
                  </div>
                  <div className="flex items-center">
                    <div className="bg-[#4608CA61] rounded-lg w-14 py-1 text-center">
                      {labData?.draft_reports ?? 0}
                    </div>
                    <span className="medium-text ml-2">Drafts</span>
                  </div>
                </div>
                <div className="flex space-x-14 font-medium">
                  <div className="flex items-center">
                    <div className="bg-[#4608CA61] rounded-lg w-14 py-1 text-center">
                      {labData?.deleted_reports ?? 0}
                    </div>
                    <span className="medium-text ml-2">Deleted</span>
                  </div>
                  <div className="flex items-center">
                    <div className="bg-[#4608CA61] rounded-lg w-14 py-1 text-center">
                      {(labData?.finalized_reports ?? 0) +
                        (labData?.draft_reports ?? 0) +
                        (labData?.deleted_reports ?? 0)}
                    </div>
                    <span className="medium-text ml-2">Total Reports</span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="bg-white rounded-full">
                <WavesIcon className="xxs:hidden sm:block w-24 h-24" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex space-x-6">
          <div
            className="bg-[var(--color-white)] xxs:p-3 md:p-5 rounded-[10px] flex flex-col space-y-3 w-1/2"
            style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
          >
            <div className="bg-[#AD76F333] rounded-[10px] flex items-center justify-center h-[50px] w-[50px]">
              <DoctorIcon className="w-[30px] h-[30px]" />
            </div>
            <span className="font-medium xxs:text-[20px] md:text-[26px] text-[var(--color-text1)]">
              {labData?.radiologists ?? 0}
            </span>
            <span className="medium-text text-[var(--color-text1)]">Radiologists</span>
            <span className="xxs:text-xs md:text-sm text-blue-text">
              Real-time data on the number of radiologists in the lab.
            </span>
          </div>
          <div
            className="bg-[var(--color-white)] xxs:p-3 md:p-5 rounded-[10px] flex flex-col space-y-3 w-1/2"
            style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
          >
            <div className="bg-[#6DFD6A33] rounded-[10px] flex items-center justify-center h-[50px] w-[50px]">
              <ReportsIcon className="w-[30px] h-[30px]" />
            </div>
            <span className="font-medium xxs:text-[20px] md:text-[26px] text-[var(--color-text1)]">
              {labData?.total_reports ?? 0}
            </span>
            <span className="medium-text text-[var(--color-text1)]">Reports</span>
            <span className="xxs:text-xs md:text-sm text-blue-text">
              Real-time data on the number of reports created, pending, and
              finalized.
            </span>
          </div>
        </div>
      </div>

      <div className="mt-6 flex xxs:flex-col md:flex-row items-center justify-between md:space-y-0 xxs:space-y-4">
        <span className="font-medium xxs:text-s md:text-lg text-[var(--color-text1)]">ALL REPORTS</span>
        <div className="flex xxs:w-full sm:w-3/5 md:w-2/5">
          <TextField
            variant="outlined"
            placeholder="Enter Report Type..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1px solid #C9CBCD",
                  borderRadius: "8px !important",
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid #5F6177",
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{
                      color:
                        theme.palette.mode === "light" ? "#11171999" : "grey",
                    }}
                  />
                </InputAdornment>
              ),
              style: {
                backgroundColor:
                  theme.palette.mode === "dark" ? "transparent" : "white",
                borderRadius: "8px",
                height: "40px",
                fontSize: "14px",
              },
            }}
            className="w-full"
          />
          <button
            className="ml-4 p-1.5 rounded-[10px] bg-primary"
            onClick={handleSearch}
          >
            <SearchIcon sx={{ color: "white" }} />
          </button>
        </div>
        <div className="relative cursor-pointer" ref={dateRangeRef}>
          <div
            className="p-2 text-sm border bg-white dark:bg-transparent border-dark-grey rounded-md w-max"
            onClick={() => setShowDatePickerDropdown(!showDatePickerDropdown)}
          >
            <span className="text-[#283037B2] dark:text-grey">Date Range:</span>
            <span className="text-[var(--color-text1)]">
              {" "}
              {dateRange[0].startDate.toDateString()} -{" "}
              {dateRange[0].endDate.toDateString()}
            </span>
            <CalendarMonthOutlinedIcon
              sx={{ color: "#A5A5A5", ml: 1, mb: 0.5, fontSize: "18px" }}
            />
          </div>

          {showDatePickerDropdown && (
            <div
              className="absolute z-10 top-full mt-1 md:right-0 rounded-lg"
              style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
            >
              <DateRangePicker
                ranges={dateRange}
                onChange={(item) =>
                  setDateRange([
                    {
                      ...item.selection,
                      startDate: new Date(item.selection.startDate),
                      endDate: new Date(item.selection.endDate),
                    },
                  ])
                }
                locale={enGB}
              />
            </div>
          )}
        </div>
      </div>

      <div className="mt-6 grid xxs:grid-cols-1 md:grid-cols-2 gap-6">
        {filteredCardData.map((card, index) => (
          <ReportsCard
            key={index}
            label={card.label}
            weeklyData={card.weeklyData}
          />
        ))}
      </div>
    </div>
  );
}

export default LabDetailsPage;
