import React from "react";
import Dropdown from "../../shared/DropDown";
import ProgressBar from "../../shared/ProgressBar";

const ReportType = ({ metrics, summaries, selectedTime, onTimeChange }) => {
  const timeOptions = [
    { value: "weekly", label: "Weekly" },
    { value: "monthly", label: "Monthly" },
    { value: "quarterly", label: "Quarterly" },
    { value: "yearly", label: "Yearly" },
    { value: "all", label: "All" },
  ];

  return (
    <div
      className="bg-[var(--color-white)] p-4 rounded-[10px] w-full"
      style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
    >
      {/* Header and Dropdown */}
      <div className="flex flex-wrap space-y-4 justify-between items-center mb-4">
        <span className="flex text-lg font-medium text-blue-text">
          Report Type
          <div className="bg-primary-light2 dark:bg-white-light -mt-1 ml-2 px-3 py-0.5 rounded-md">
            <span className="text-purple text-sm font-medium">{metrics?.total_reports} Reports</span>
          </div>
        </span>
        <div className="w-40">
          <Dropdown
            id="time"
            label="option"
            options={timeOptions}
            selectedOption={timeOptions.find(
              (option) => option.value === selectedTime
            )}
            onChange={(option) => onTimeChange(option.value)}
          />
        </div>
      </div>

      {/* Customizable Progress Bars */}
      <ProgressBar
        label="MRI"
        percentage={summaries?.report_type_summary?.mri?.percentage || 0}
        count={summaries?.report_type_summary?.mri?.count || 0}
        barColor="#F13C3C"
      />
      <ProgressBar
        label="ECG"
        percentage={summaries?.report_type_summary?.ecg?.percentage || 0}
        count={summaries?.report_type_summary?.ecg?.count || 0}
        barColor="#07BCD2"
      />
      <ProgressBar
        label="CT Scan"
        percentage={summaries?.report_type_summary?.ct_scan?.percentage || 0}
        count={summaries?.report_type_summary?.ct_scan?.count || 0}
        barColor="#FF9F43"
      />
      <ProgressBar
        label="X-Ray"
        percentage={summaries?.report_type_summary?.x_ray?.percentage || 0}
        count={summaries?.report_type_summary?.x_ray?.count || 0}
        barColor="#513ADC"
      />
    </div>
  );
};

export default ReportType;
