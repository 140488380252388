import React, { createContext, useState, useContext } from "react";

// Create a context
export const LabContext = createContext();

export const LabProvider = ({ children }) => {
  const [lab, setLab] = useState(null);

  return (
    <LabContext.Provider value={{ lab, setLab }}>
      {children}
    </LabContext.Provider>
  );
};

// Custom hook for accessing the context
export const useLab = () => useContext(LabContext);
