import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  BellIcon,
  SunIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowRightOnRectangleIcon,
  XMarkIcon,
  Bars3Icon,
} from "@heroicons/react/24/outline";
import { useUser } from "../../contexts/UserContext";
import TranslateOutlinedIcon from "@mui/icons-material/TranslateOutlined";
import { ReactComponent as MoonIcon } from "../../assets/moon-icon.svg";
import { ReactComponent as User } from "../../assets/User.svg";
import { ReactComponent as Gear } from "../../assets/Gear.svg";
import { ReactComponent as Info } from "../../assets/Info.svg";
import { ReactComponent as Faq } from "../../assets/FAQ.svg";
import ConfirmDialog from "../../shared/ConfirmDialog";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Avatar from "@mui/material/Avatar";
import { formatDistanceToNow } from "date-fns";
import {
  getNotifications,
  markNotificationAsRead,
} from "../../services/notificationsService";
import femaleImg from "../../assets/female-avatar.svg";
import maleImg from "../../assets/male-avatar.svg";

const Navbar = ({
  toggleSidebar,
  toggleDarkMode,
  isDarkMode,
  handleLogout,
}) => {
  const { user } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const { userId } = useParams();
  const { labId } = useParams();
  const { reportId } = useParams();
  const { t, i18n } = useTranslation();
  // State to control the visibility of each dropdown
  const [showNotificationDropdown, setShowNotificationDropdown] =
    useState(false);
  const [showThemeDropdown, setShowThemeDropdown] = useState(false);
  const [showLanguageDropdown, setShowLanguageDropdown] = useState(false);
  const [showUserDropdown, setShowUserDropdown] = useState(false);

  // State to manage selected language
  const [selectedLanguage, setSelectedLanguage] = useState("English");

  // Array for language options
  const languageOptions = ["English", "French", "British English"];
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [unreadNotifications, setUnreadNotifications] = useState(0);

  // Mapping route paths to headings
  const getHeadingForRoute = (path) => {
    if (path.startsWith("/create-report")) {
      return "Create Report";
    } else if (path === "/dashboard") {
      return "Dashboard";
    } else if (path === "/notifications") {
      return "Notifications";
    } else if (path === "/report-management") {
      return "Report Management";
    } else if (path === "/user-management") {
      return "User Management";
    } else if (path === "/lab-management") {
      return "Lab Management";
    } else if (path === "/support") {
      return "Support";
    } else if (path.startsWith("/settings")) {
      return "Settings";
    } else if (
      path.startsWith("/edit-report") ||
      path.startsWith("/report-details")
    ) {
      return `Report ID (${reportId})`;
    } else if (
      path.startsWith("/user-details") ||
      path.startsWith("/edit-user")
    ) {
      return `User ID (${userId})`;
    } else if (path.startsWith("/lab-details")) {
      return `Lab ID (${labId})`;
    } else {
      return "Dashboard"; // Default fallback
    }
  };

  const currentHeading = getHeadingForRoute(location.pathname);

  const handleOpenDialog = () => {
    setShowUserDropdown(false);
    setDialogTitle("Sure you want to logout?");
    setDialogContent("Are you sure you want to logout this?");
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    // Navigate to login page when the confirm button is clicked
    handleLogout();
    navigate("/login");
  };

  // Function to toggle dropdowns
  const toggleDropdown = (dropdownType) => {
    switch (dropdownType) {
      case "notifications":
        setShowNotificationDropdown(!showNotificationDropdown);
        setShowThemeDropdown(false);
        setShowLanguageDropdown(false);
        setShowUserDropdown(false);
        break;
      case "theme":
        setShowThemeDropdown(!showThemeDropdown);
        setShowNotificationDropdown(false);
        setShowLanguageDropdown(false);
        setShowUserDropdown(false);
        break;
      case "language":
        setShowLanguageDropdown(!showLanguageDropdown);
        setShowNotificationDropdown(false);
        setShowThemeDropdown(false);
        setShowUserDropdown(false);
        break;
      case "user":
        setShowUserDropdown(!showUserDropdown);
        setShowNotificationDropdown(false);
        setShowThemeDropdown(false);
        setShowLanguageDropdown(false);
        break;
      default:
        break;
    }
  };

  // Function to select language
  const handleLanguageSelect = (language) => {
    setSelectedLanguage(language);
    // Map the selected language to the correct i18next language code
    const languageMap = {
      English: "en", // American English
      French: "fr", // French
      "British English": "en_GB", // British English
    };

    // Change the language using the mapped value
    const langCode = languageMap[language];
    if (langCode) {
      i18n.changeLanguage(langCode); // Change language using i18n
    }

    setShowLanguageDropdown(false); // Close the dropdown after selection
  };

  const [notifications, setNotifications] = useState([]);

  // Create refs for each dropdown
  const notificationRef = useRef(null);
  const languageRef = useRef(null);
  const userRef = useRef(null);

  // UseEffect to close dropdowns when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target) &&
        languageRef.current &&
        !languageRef.current.contains(event.target) &&
        userRef.current &&
        !userRef.current.contains(event.target)
      ) {
        setShowNotificationDropdown(false);
        setShowThemeDropdown(false);
        setShowLanguageDropdown(false);
        setShowUserDropdown(false);
      }
    };

    // Attach event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleNotifications = useCallback(async (limit = null) => {
    try {
      const response = await getNotifications(limit); // Pass limit to get the notifications
      setNotifications(response.notifications);
      setUnreadNotifications(response.total_unread);
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  }, []);

  // Call handleNotifications as soon as the Navbar component loads
  useEffect(() => {
    handleNotifications("3");
  }, [handleNotifications]);

  const handleMarkAllAsRead = async (notificationIds) => {
    try {
      const notificationIds = notifications.map(
        (notification) => notification.id
      );
      await markNotificationAsRead(notificationIds); // Assuming this API marks notifications as read
      handleNotifications(); // Re-fetch notifications to reflect changes
    } catch (error) {
      console.error("Error marking notifications as read:", error);
    }
  };

  return (
    <nav className="bg-[var(--color-white)] drop-shadow-lg z-10 xxs:px-2 md:px-6 py-4 flex items-center justify-between">
      {/* Left side: showing the current section from Sidebar */}
      <div className="flex items-center xxs:space-x-2 md:space-x-4">
        {/* Hamburger menu on mobile */}
        <div className="lg:hidden">
          <Bars3Icon
            className="w-5 h-5 text-[var(--color-text1)] dark:text-white cursor-pointer"
            onClick={toggleSidebar} // Toggle the sidebar
          />
        </div>
        {/* Current section */}
        {currentHeading === "Notifications" ||
        currentHeading === `Report ID (${reportId})` ||
        currentHeading === `Lab ID (${labId})` ||
        currentHeading === `User ID (${userId})` ? (
          <ChevronLeftIcon
            className="h-4 w-4 text-[var(--color-dark-grey)] cursor-pointer"
            aria-hidden="true"
            strokeWidth={2.5}
            onClick={() => navigate(-1)}
          />
        ) : null}
        <span className="mediumLarge-text font-medium text-[var(--color-text1)]">
          {currentHeading}
        </span>
      </div>

      {/* Right side: Icons */}
      <div className="flex xxs:space-x-2 md:space-x-4 items-center relative">
        {/* Language Icon */}
        <div className="relative z-20" ref={languageRef}>
          <div
            className="rounded-full xxs:w-8 xxs:h-8 md:w-11 md:h-11 overflow-hidden bg-green-light dark:bg-white-light flex justify-center items-center cursor-pointer"
            onClick={() => toggleDropdown("language")}
          >
            <TranslateOutlinedIcon
              sx={{
                color: "#28C76F",
                fontSize: {
                  xxs: "18px",
                  md: "23px",
                },
              }}
            />
          </div>

          {/* Language Dropdown */}
          {showLanguageDropdown && (
            <div
              className="absolute top-full right-0 mt-2 bg-[var(--color-white)] w-36 p-1 rounded-lg"
              style={{ border: "1px solid #737373" }}
            >
              {languageOptions.map((language) => (
                <p
                  key={language}
                  className={`cursor-pointer rounded-lg text-start dark:text-white small-text mt-1 mb-1 hover:bg-pink-light dark:hover:bg-[#333649] p-2 ${
                    selectedLanguage === language
                      ? "font-bold ring-1 ring-primary dark:ring-[#7C81A3] bg-primary-light dark:bg-[#494D66] hover:bg-primary-light dark:hover:bg-[#494D66] hover:outline-none"
                      : ""
                  }`}
                  onClick={() => handleLanguageSelect(language)}
                >
                  <span className="flex justify-between">
                    {language}
                    {selectedLanguage === language && (
                      <CheckIcon className="h-4 w-4" />
                    )}
                  </span>
                </p>
              ))}
            </div>
          )}
        </div>

        {/* Theme Icon - toggles between Sun and Moon */}
        {!isDarkMode && (
          <div
            className="rounded-full xxs:w-8 xxs:h-8 md:w-11 md:h-11 overflow-hidden bg-orange-light flex justify-center items-center cursor-pointer"
            onClick={toggleDarkMode} // Toggle dark mode on click
          >
            <SunIcon
              className="xxs:6 xxs:h-6 md:w-8 md:h-8 text-orange"
              strokeWidth={1.5}
            />
          </div>
        )}

        {/* Moon Icon appears after activating dark mode */}
        {isDarkMode && (
          <div
            className="dark:bg-white-light rounded-full xxs:w-8 xxs:h-8 md:w-11 md:h-11 overflow-hidden flex justify-center items-center cursor-pointer"
            onClick={toggleDarkMode} // Toggle back to light mode on click
          >
            <MoonIcon
              className="xxs:6 xxs:h-6 md:w-8 md:h-8 text-[var(--color-dark-grey)]"
              strokeWidth={1.5}
            />
          </div>
        )}

        {/* Notification Icon */}
        <div className="relative z-20" ref={notificationRef}>
          <div
            className="rounded-full xxs:w-8 xxs:h-8 md:w-11 md:h-11 overflow-hidden bg-primary-light2 dark:bg-white-light flex justify-center items-center cursor-pointer"
            onClick={() => {
              toggleDropdown("notifications");
            }}
          >
            <BellIcon
              className="xxs:6 xxs:h-6 md:w-8 md:h-8 text-primary"
              strokeWidth={1.5}
            />
          </div>
          {/* Notification Dropdown */}
          {showNotificationDropdown && (
            <div className="absolute top-full right-0 mt-2 xxs:w-72 md:w-96 bg-[var(--color-white)] drop-shadow rounded-lg dark:border dark:border-[#737373]">
              <div className="border-b border-light-grey p-4 flex justify-between items-center mt-2">
                <span className="text-sm text-[var(--color-text1)]">
                  {t("navbar.notifications")}
                </span>
                <div className="flex items-center space-x-3">
                  <button
                    className="text-xs text-main3 px-2 py-1 rounded-md"
                    style={{ background: "#7C009B1A" }}
                    onClick={handleMarkAllAsRead}
                  >
                    {t("notifications.mark_all_as_read")}
                  </button>
                  <button
                    type="button"
                    className="p-1 bg-grey2 dark:bg-white-light rounded-full"
                    onClick={() => setShowNotificationDropdown(false)}
                  >
                    <XMarkIcon
                      className="h-4 w-4 text-[var(--color-text1)]"
                      aria-hidden="true"
                      strokeWidth={2}
                    />
                  </button>
                </div>
              </div>
              <div>
                <p className="small-text text-blue-text p-3">
                  Unread ({unreadNotifications})
                </p>
              </div>
              {notifications.length === 0 ? (
                <div className="p-4 flex justify-center items-center text-base">
                  No notifications available
                </div>
              ) : (
                <div>
                  <div className="space-y-2">
                    {notifications.map((notification) => (
                      <div
                        key={notification.id}
                        className="flex items-start space-x-3 py-2 border-b border-light-grey"
                      >
                        <span className="w-2 h-2 rounded-full bg-green p-1 mx-4 mt-1"></span>
                        <div>
                          <p className="text-sm text-[var(--color-text1)]">
                            {notification.title}
                          </p>
                          <p className="text-xs text-blue-text">
                            {notification.message}
                          </p>
                          <p className="text-xs text-[#524A3ED1] dark:text-text1-dark">
                            {formatDistanceToNow(
                              new Date(notification.created_at),
                              { addSuffix: true }
                            ).replace(/^about /, "")}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                  <Link to="/notifications" style={{ textDecoration: "none" }}>
                    <div
                      className="p-4 mb-2 flex justify-between cursor-pointer"
                      onClick={() => setShowNotificationDropdown(false)}
                    >
                      <span
                        className="small-text text-[var(--color-text1)]"
                        style={{ textDecoration: "none" }}
                      >
                        {t("notifications.viewAll")}
                      </span>
                      <ChevronRightIcon className="w-5 h-5 text-[var(--color-text1)]" />
                    </div>
                  </Link>
                </div>
              )}
            </div>
          )}
        </div>

        {/* User Profile */}
        <div className="relative z-20" ref={userRef}>
          <div
            className="rounded-full xxs:w-8 xxs:h-8 md:w-11 md:h-11 overflow-hidden bg-grey4 cursor-pointer"
            style={{ boxShadow: "0px 4px 8px 0px #00000029" }}
            onClick={() => toggleDropdown("user")}
          >
            <Avatar
              src={
                user?.profile_photo
                  ? user.profile_photo // If the user has an avatar, use it
                  : user?.gender === "Female" // If gender is female, use a default female avatar
                  ? femaleImg
                  : maleImg // Otherwise, use a default male avatar
              }
              alt="user"
              sx={{
                boxShadow: "0px 4px 8px 0px #00000029",
                width: {
                  xxs: "32px",
                  md: "45px",
                },
                height: {
                  xxs: "32px",
                  md: "45px",
                },
              }}
            />
          </div>
          {/* User Dropdown */}
          {showUserDropdown && (
            <div className="absolute top-full right-0 mt-2 xxs:w-56 md:w-72 bg-[var(--color-white)] drop-shadow rounded-lg dark:border dark:border-[#737373]">
              <div className="border-b border-light-grey p-4 flex">
                <Avatar
                  src={
                    user?.profile_photo
                      ? user.profile_photo // If the user has an avatar, use it
                      : user?.gender === "Female" // If gender is female, use a default female avatar
                      ? femaleImg
                      : maleImg // Otherwise, use a default male avatar
                  }
                  alt="user"
                  sx={{
                    boxShadow: "0px 4px 8px 0px #00000029",
                    width: "70px",
                    height: "70px",
                  }}
                />
                {/* </div> */}
                <div className="ml-6">
                  <p className="mt-1.5 font-medium text-xl text-[var(--color-text1)]">
                    {user?.name}
                  </p>
                  <p className="medium-text text-[var(--color-dark-grey)]">
                    {user?.role}
                  </p>
                </div>
              </div>
              {/* Menu items */}
              <div className="p-4 py-3 flex justify-between medium-text text-[var(--color-text1)] cursor-pointer">
                <div
                  className="flex items-start"
                  onClick={() => {
                    navigate("/settings/personal-details");
                    setShowUserDropdown(false);
                  }}
                >
                  <User className="w-[18px] h-[18px] mt-0.5 mr-4 text-[var(--color-text1)]" />
                  <p>View Profile</p>
                </div>
                <ChevronRightIcon className="w-5 h-5" />
              </div>
              <div
                className="p-4 py-3 border-b border-light-grey flex justify-between medium-text text-[var(--color-text1)] cursor-pointer"
                onClick={() => {
                  navigate("/settings/personal-details");
                  setShowUserDropdown(false);
                }}
              >
                <div className="flex items-start">
                  <Gear className="w-5 h-5 mt-0.5 mr-4 text-[var(--color-text1)]" />
                  <p>Settings</p>
                </div>
                <ChevronRightIcon className="w-5 h-5" />
              </div>
              {user.role !== "Admin" && (
                <div
                  className="p-4 py-3 flex justify-between medium-text text-[var(--color-text1)] cursor-pointer"
                  onClick={() => {
                    navigate("/support");
                    setShowUserDropdown(false);
                  }}
                >
                  <div className="flex items-start">
                    <Info className="w-5 h-5 mt-0.5 mr-4 text-[var(--color-text1)]" />
                    <p>Support</p>
                  </div>
                  <ChevronRightIcon className="w-5 h-5" />
                </div>
              )}
              {user.role !== "Admin" && (
                <div
                  className="p-4 py-3 flex justify-between medium-text text-[var(--color-text1)] cursor-pointer"
                  onClick={() => {
                    navigate("/support");
                    setShowUserDropdown(false);
                  }}
                >
                  <div className="flex items-start">
                    <Faq className="w-5 h-5 mt-0.5 mr-4 text-[var(--color-text1)]" />
                    <p>FAQs</p>
                  </div>
                  <ChevronRightIcon className="w-5 h-5" />
                </div>
              )}
              <div className="p-2">
                <button
                  type="button"
                  onClick={handleOpenDialog}
                  className={`bg-error w-full flex justify-center items-center text-white medium-text font-medium py-3 rounded-lg`}
                >
                  <ArrowRightOnRectangleIcon
                    className="w-6 h-6 mr-2"
                    strokeWidth={2}
                  />
                  Logout
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Pass the dynamic title and content to ConfirmDialog */}
      <ConfirmDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={dialogTitle}
        content={dialogContent}
        onConfirm={handleConfirm}
      />
    </nav>
  );
};

export default Navbar;
