import React, { useState } from "react";
import TextInput from "../../shared/TextInput";
import PrimaryButton from "../../shared/PrimaryButton";
import { ReactComponent as ClappingHands1 } from "../../assets/noto_clapping-hands (1).svg";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import CommonDialog from "../../shared/CommonDialog";
import { useNavigate } from "react-router-dom";
import { registerUser } from "../../services/authService";

// Utility functions for validation
const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
  return re.test(String(email).toLowerCase());
};

const validatePassword = (password) => {
  if (password.length < 8) {
    return "Password must be at least 8 characters long.";
  }
  if (!/[a-z]/.test(password)) {
    return "Password must contain at least one lowercase letter.";
  }
  if (!/[0-9\W\s]/.test(password)) {
    return "Password must contain at least one number, symbol, or whitespace.";
  }
  return "";
};

const validateCharacterOnly = (value) => {
  const re = /^[a-zA-Z\s]+$/;
  return re.test(value.trim());
};

const RegisterForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    labName: "",
    labLocation: "",
    password: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    email: "",
    labName: "",
    labLocation: "",
    password: [],
  });

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonLabel, setButtonLabel] = useState("");

  const handleOpenDialog = () => {
    // Set the dialog title and content dynamically based on form data
    setDialogTitle("Account Created Successfully!");
    setDialogContent(
      "Your account has been successfully created. Please log in to continue."
    );
    setButtonLabel("Back to Login");
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    // Add your confirm logic here
    navigate("/login");
  };

  // Handle input changes and validations
  const handleInputChange = (e) => {
    const { id, value } = e.target;

    // Update form data
    setFormData((prevState) => ({ ...prevState, [id]: value }));

    // Validate the input
    let fieldError = "";

    switch (id) {
      case "name":
        if (!value.trim()) {
          fieldError = "Name is required.";
        } else if (!validateCharacterOnly(value)) {
          fieldError = "Name must contain only letters.";
        } else if (value.trim().length < 2) {
          fieldError = "Name must be at least 2 characters long.";
        }
        setErrors((prevErrors) => ({ ...prevErrors, name: fieldError }));
        break;

      case "email":
        if (!value.trim()) {
          fieldError = "Email is required.";
        } else if (!validateEmail(value)) {
          fieldError = "Please enter a valid email address.";
        }
        setErrors((prevErrors) => ({ ...prevErrors, email: fieldError }));
        break;

      case "labName":
        if (!value.trim()) {
          fieldError = "Lab Name is required.";
        } else if (!validateCharacterOnly(value)) {
          fieldError = "Lab Name must contain only letters.";
        }
        setErrors((prevErrors) => ({ ...prevErrors, labName: fieldError }));
        break;

      case "labLocation":
        if (!value.trim()) {
          fieldError = "Lab Location is required.";
        } else if (!validateCharacterOnly(value)) {
          fieldError = "Lab Location must contain only letters.";
        }
        setErrors((prevErrors) => ({ ...prevErrors, labLocation: fieldError }));
        break;

        case "password":
          fieldError = validatePassword(value);
          setErrors((prevErrors) => ({ ...prevErrors, password: fieldError }));
          break;

      default:
        break;
    }
  };

  // Validate all fields before submission
  const validateForm = () => {
    const newErrors = {
      name: "",
      email: "",
      labName: "",
      labLocation: "",
      password: [],
    };
    let isValid = true;

    if (!formData.name.trim()) {
      newErrors.name = "Name is required.";
      isValid = false;
    } else if (!validateCharacterOnly(formData.name)) {
      newErrors.name = "Name must contain only letters.";
      isValid = false;
    } else if (formData.name.trim().length < 2) {
      newErrors.name = "Name must be at least 2 characters long.";
      isValid = false;
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required.";
      isValid = false;
    } else if (!validateEmail(formData.email)) {
      newErrors.email = "Please enter a valid email address.";
      isValid = false;
    }

    if (!formData.labName.trim()) {
      newErrors.labName = "Lab Name is required.";
      isValid = false;
    } else if (!validateCharacterOnly(formData.labName)) {
      newErrors.labName = "Lab Name must contain only letters.";
      isValid = false;
    }

    if (!formData.labLocation.trim()) {
      newErrors.labLocation = "Lab Location is required.";
      isValid = false;
    } else if (!validateCharacterOnly(formData.labLocation)) {
      newErrors.labLocation = "Lab Location must contain only letters.";
      isValid = false;
    }

    newErrors.password = validatePassword(formData.password);
    if (newErrors.password) {
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      // If validation fails, do not proceed
      return;
    }

    try {
      const payload = {
        name: formData.name.trim(),
        email: formData.email.trim(),
        password: formData.password,
        lab_name: formData.labName.trim(),
        lab_location: formData.labLocation.trim(),
      };

      console.log("Register Payload:", payload);
      const response = await registerUser(payload);
      console.log("User registered successfully:", response);
      handleOpenDialog(); // Open success dialog

      // Optionally, reset form after successful registration
      setFormData({
        name: "",
        email: "",
        labName: "",
        labLocation: "",
        password: "",
      });
      setErrors({
        name: "",
        email: "",
        labName: "",
        labLocation: "",
        password: [],
      });
    } catch (error) {
      console.error("Error registering user:", error);
    }
  };

  // Determine if the form has any validation errors
  const hasErrors = () => {
    return (
      errors.name ||
      errors.email ||
      errors.labName ||
      errors.labLocation ||
      errors.password.length > 0
    );
  };

  return (
    <div
      className="bg-[var(--color-white)] rounded-[20px] padding-responsive"
      style={{ boxShadow: "0px 9px 34px 0px #0000001A" }}
    >
      <form onSubmit={handleSubmit} className="px-2">
        <div className="flex items-center">
          <span className="pr-2 mb-1 text-[var(--color-dark-grey)] font-heading large-text font-semibold">
            Welcome to Emilio Muse!
          </span>
          <ClappingHands1 className="-mt-2" />
        </div>
        <div className="text-dark-grey small-text mb-6">
          Please enter name, email id, lab name, location and role to create an
          account
        </div>

        <div className="flex flex-col gap-5">
          <TextInput
            id="name"
            type="text"
            label="Name"
            placeholder="Enter User Name"
            value={formData.name}
            onChange={handleInputChange}
            error={!!errors.name}
            helperText={errors.name}
          />

          <TextInput
            id="email"
            type="email"
            label="Email"
            placeholder="Enter Email Id"
            value={formData.email}
            onChange={handleInputChange}
            error={!!errors.email}
            helperText={errors.email}
          />

          <TextInput
            id="labName"
            type="text"
            label="Lab Name"
            placeholder="Enter Lab Name"
            value={formData.labName}
            onChange={handleInputChange}
            error={!!errors.labName}
            helperText={errors.labName}
          />

          <TextInput
            id="labLocation"
            type="text"
            label="Lab Location"
            placeholder="Enter Lab Location"
            value={formData.labLocation}
            onChange={handleInputChange}
            error={!!errors.labLocation}
            helperText={errors.labLocation}
          />

          <TextInput
            id="password"
            type="password"
            label="Password"
            placeholder="Enter Password"
            value={formData.password}
            onChange={handleInputChange}
            error={!!errors.password}
            helperText={errors.password}
          />

          <div className="mt-4">
            <PrimaryButton
              type="submit"
              label="Create Account"
              className="w-full"
              onClick={handleSubmit}
              disabled={hasErrors()}
            />
          </div>
        </div>

        <div className="mt-8 text-center medium-text flex items-center justify-center">
          <button
            type="button"
            className="text-blue text-sm flex items-center"
            onClick={() => navigate("/login")}
          >
            <ChevronLeftIcon
              className="h-4 w-4 text-blue mr-3"
              aria-hidden="true"
              strokeWidth={3}
            />
            Back to Login
          </button>
        </div>
      </form>

      {/* Pass the dynamic title and content to CommonDialog */}
      <CommonDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={dialogTitle}
        content={dialogContent}
        buttonLabel={buttonLabel}
        showRightIcon={true}
        onConfirm={handleConfirm}
      />
    </div>
  );
};

export default RegisterForm;
