import React from "react";
import Divider from "@mui/material/Divider";
import Checkbox from "../../shared/Checkbox";
import { updateSettings } from "../../services/userService";

function General({ isDarkMode, toggleDarkMode, settings }) {
  const handleThemeChange = async (theme) => {
    try {
      // Call the updateSettings API
      const response = await updateSettings({ theme });

      if (response.message === "Settings updated successfully.") {
        toggleDarkMode();
      } else {
        console.error("Failed to update theme:", response.error);
      }
    } catch (error) {
      console.error("An error occurred while updating the theme:", error);
    }
  };

  return (
    <div>
      <div className="flex items-center px-6 py-4 mt-6 bg-[var(--color-white)] text-lg text-[var(--color-primary)]">
        Display
      </div>

      <div className="flex flex-col m-6 bg-[var(--color-white)] rounded-[10px]">
        <div className="flex items-center justify-between">
          <div className="py-2 px-6">
            <p className="xxs:text-sm md:text-lg text-[var(--color-text1)]">
              Light Theme
            </p>
            <span className="text-[var(--color-dark-grey)] medium-text">
              Set light theme to display
            </span>
          </div>
          <Checkbox
            checked={!isDarkMode}
            onChange={() => {
              if (isDarkMode) handleThemeChange("light"); // Switch to light mode
            }}
          />
        </div>
        <Divider orientation="horizontal" flexItem />
        <div className="flex items-center justify-between">
          <div className="py-2 px-6 mt-1">
            <p className="xxs:text-sm md:text-lg text-[var(--color-text1)]">
              Dark Theme
            </p>
            <span className="text-[var(--color-dark-grey)] medium-text">
              Set dark theme to display
            </span>
          </div>
          <Checkbox
            checked={isDarkMode}
            onChange={() => {
              if (!isDarkMode) handleThemeChange("dark"); // Switch to dark mode
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default General;
