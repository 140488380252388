import apiClient from "../constants/apiConfig";
import { toast } from "react-toastify";

// Get all labs
export const getLabs = async () => {
    try {
        const response = await apiClient.get("/lab/get-all-labs");
        return response.data;
    } catch (error) {
        toast.error(error.response?.data?.error || "Failed to fetch labs!"); // Error toast
        throw error.response.data;
    }
}

// Get lab details api
export const getLabDetails = async (labId,  startDate, endDate) => {
    try {
        const params = {};
        if (startDate) params.start_date = startDate;
        if (endDate) params.end_date = endDate;

        const response = await apiClient.get(`/lab/get-lab-details/${labId}`,  { params });
        return response.data;
    } catch (error) {
        toast.error(error.response?.data?.error || "Failed to fetch lab!"); // Error toast
        throw error.response.data;
    }
}

// Delete Lab
export const deleteLab = async (labId) => {
    try {
        const response = await apiClient.delete(`/lab/delete-lab/${labId}`);
        toast.success("Lab deleted successfully!");
        return response.data;
    } catch (error) {
        toast.error(error.response?.data?.error || "Failed to delete lab!"); // Error toast
        throw error.response.data;
    }
  };