import React from "react";
import { useNavigate, Outlet } from "react-router-dom";
import { ReactComponent as UserIcon } from "../assets/User.svg";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import { ReactComponent as BillsIcon } from "../assets/bills.svg";
import { ReactComponent as SettingsIcon } from "../assets/general.svg";
import { BellIcon } from "@heroicons/react/24/outline";
import { ReactComponent as ReportIcon } from "../assets/report.svg";
import { useUser } from "../contexts/UserContext";

function SettingsPage() {
  const navigate = useNavigate();
  const { user } = useUser();

  const tabItems = [
    {
      name: "Personal Details",
      path: "personal-details",
      icon: <UserIcon className="h-7 stroke-2" />,
    },
    { name: "Security", path: "security", icon: <GppGoodOutlinedIcon /> },
    {
      name: "Billing & Plans",
      path: "billing-plans",
      icon: <BillsIcon />,
      role: ["Lab Admin"],
    },
    {
      name: "General",
      path: "general",
      icon: (
        <SettingsIcon
          className={`w-6 ${
            window.location.pathname.includes("general")
              ? "text-white"
              : "text-blue-text"
          }`}
        />
      ),
    },
    {
      name: "Notification",
      path: "notification",
      icon: (
        <BellIcon
          className={`w-6 ${
            window.location.pathname.includes("notification")
              ? "text-white"
              : "text-blue-text"
          }`}
          strokeWidth={1.5}
        />
      ),
    },
    {
      name: "Report Templates",
      path: "report-templates",
      icon: (
        <ReportIcon
          className={`w-6 ${
            window.location.pathname.includes("report-templates")
              ? "text-white"
              : "text-blue-text"
          }`}
        />
      ),
      role: ["Lab Admin"],
    },
  ];

  const filteredTabItems = tabItems.filter((item) => {
    return (
      !item.role ||
      (user && user?.role && Array.isArray(item.role)
        ? item.role.includes(user?.role)
        : user?.role === item.role)
    );
  });

  const handleTabClick = (path) => {
    navigate(path);
  };

  return (
    <div className="flex flex-col">
      <div
        className="flex flex-wrap bg-[var(--color-white)] py-4 xxs:px-4 md:px-6 mt-4 space-x-2"
        style={{ boxShadow: "0px 2px 6px 0px #00000024" }}
      >
        {filteredTabItems.map((tab) => (
          <button
            key={tab.name}
            onClick={() => handleTabClick(tab.path)}
            className={`flex items-center p-2 px-4 font-medium text-base rounded-md ${
              window.location.pathname.includes(tab.path)
                ? "bg-primary text-white shadow-md font-semibold"
                : "text-blue-text"
            }`}
          >
            <span className="mr-2">{tab.icon}</span>
            {tab.name}
          </button>
        ))}
      </div>
      <div>
        <Outlet />
      </div>
    </div>
  );
}

export default SettingsPage;
