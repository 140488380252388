import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@mui/material/styles";
import SearchIcon from "@mui/icons-material/Search";
import { TextField, InputAdornment } from "@mui/material";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { enGB } from "date-fns/locale";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import LabDataTable from "../shared/LabDataTable";
import CircularProgress from "@mui/material/CircularProgress";
import PrimaryButton from "../shared/PrimaryButton";
import { getLabs } from "../services/labService";

function LabManagementPage() {
  const theme = useTheme();
  const dateRangeRef = useRef(null);
  const [searchInput, setSearchInput] = useState("");
  const [showDatePickerDropdown, setShowDatePickerDropdown] = useState(false);

  const initialDateRange = [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
      color: "#513ADC",
    },
  ];
  
  const [dateRange, setDateRange] = useState(initialDateRange);
  const [isDateFilterActive, setIsDateFilterActive] = useState(false);
  const [labs, setLabs] = useState([]);
  const [filteredLabs, setFilteredLabs] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchLabs = async () => {
    setIsLoading(true);
    try {
      const response = await getLabs();
      setLabs(response.labs);
      setFilteredLabs(response.labs);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLabs();
  }, []);

  // Filter labs based on search input and date range
  useEffect(() => {
    const lowerCaseSearch = searchInput.toLowerCase();

    // Extract startDate and endDate
    const { startDate, endDate } = dateRange[0];

    setFilteredLabs(
      labs.filter((lab) => {
        const nameMatch = lab.lab_name.toLowerCase().includes(lowerCaseSearch);
        const locationMatch = lab.lab_location
          .toLowerCase()
          .includes(lowerCaseSearch);

        let dateMatch = true; // Default to true if no date range is selected

        if (isDateFilterActive) {
          const createdOn = new Date(lab.created_on);
          // Set the time of startDate to the start of the day and endDate to the end of the day for accurate comparison
          const start = new Date(startDate);
          start.setHours(0, 0, 0, 0);
          const end = new Date(endDate);
          end.setHours(23, 59, 59, 999);

          dateMatch = createdOn >= start && createdOn <= end;
        }

        return (nameMatch || locationMatch) && dateMatch;
      })
    );
  }, [searchInput, labs, dateRange, isDateFilterActive]);

  const dateRangeText =
    dateRange[0].startDate && dateRange[0].endDate
      ? `${dateRange[0].startDate.toDateString()} - ${dateRange[0].endDate.toDateString()}`
      : "All Dates";

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dateRangeRef.current &&
        !dateRangeRef.current.contains(event.target)
      ) {
        setShowDatePickerDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClearDateRange = () => {
    setDateRange(initialDateRange);
    setIsDateFilterActive(false);
    setShowDatePickerDropdown(false);
  };

  return (
    <div className="p-6">
      <div className="flex xxs:flex-col sm:flex-row xxs:space-y-4 md:space-y-0 items-center justify-between">
        <div className="flex xxs:flex-col lg:flex-row xxs:space-y-4 lg:space-y-0 w-full lg:items-center justify-start lg:space-x-4">
          <div className="relative cursor-pointer" ref={dateRangeRef}>
            <div
              className="p-2 text-sm border bg-white dark:bg-transparent border-dark-grey rounded-md w-max"
              onClick={() => setShowDatePickerDropdown(!showDatePickerDropdown)}
            >
              <span className="text-[#283037B2] dark:text-grey">
                Date Range:
              </span>
              <span className="text-[var(--color-text1)]">
                {" "}
                {dateRangeText}
              </span>
              <CalendarMonthOutlinedIcon
                sx={{ color: "#A5A5A5", ml: 1, mb: 0.5, fontSize: "18px" }}
              />
            </div>

            {showDatePickerDropdown && (
              <div
                className="absolute z-10 bg-white top-full mt-1 md:left-0 rounded-lg"
                style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
              >
                <DateRangePicker
                  ranges={dateRange}
                  onChange={(item) => {
                    setDateRange([item.selection]);
                    setIsDateFilterActive(true);
                  }}
                  locale={enGB}
                />

                <div className="flex justify-end p-2">
                  <PrimaryButton
                    label="Clear"
                    className="w-32"
                    onClick={handleClearDateRange}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex xxs:w-full lg:w-1/2 sm:justify-end">
          <TextField
            variant="outlined"
            placeholder="Enter Lab Name or Lab Location..."
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            className="w-full"
            sx={{
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1px solid #5F6177",
                  borderRadius: "8px !important",
                },
                "&.Mui-focused fieldset": {
                  border: "1px solid #5F6177",
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon
                    sx={{
                      color:
                        theme.palette.mode === "light" ? "#11171999" : "grey",
                    }}
                  />
                </InputAdornment>
              ),
              style: {
                backgroundColor:
                  theme.palette.mode === "dark" ? "transparent" : "white",
                borderRadius: "8px",
                height: "45px",
                fontSize: "14px",
              },
            }}
          />
          <button className="ml-2 p-2.5 rounded-lg bg-primary">
            <SearchIcon sx={{ color: "white" }} />
          </button>
        </div>
      </div>
      <div className="mt-6">
        {isLoading ? (
          <div className="flex justify-center items-center h-64">
            <CircularProgress />
          </div>
        ) : (
          <LabDataTable
            rows={10}
            fromLabPage={true}
            labs={filteredLabs}
            setLabs={setFilteredLabs}
          />
        )}
      </div>
    </div>
  );
}

export default LabManagementPage;
