import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as EditIcon } from "../assets/edit.svg";
import { ReactComponent as DeleteIcon } from "../assets/delete.svg";
import { ReactComponent as DraftsIcon } from "../assets/drafts.svg";
import { ReactComponent as FinalizedIcon } from "../assets/finalized.svg";
import { Progress } from "rsuite";
import CommonDialog from "../shared/CommonDialog";
import femaleImg from "../assets/female-avatar.svg";
import maleImg from "../assets/male-avatar.svg";
import { deleteUser } from "../services/userService";
import { getUserById } from "../services/userService";

function UserDetailsPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const [user, setUser] = useState(location.state?.user || {});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [buttonLabel, setButtonLabel] = useState("");

  // Define month data with abbreviations and dynamic progress values
  const [monthData, setMonthData] = useState(
    useMemo(
      () => [
        { month: "Jan", progress: 0 },
        { month: "Feb", progress: 0 },
        { month: "Mar", progress: 0 },
        { month: "Apr", progress: 0 },
        { month: "May", progress: 0 },
        { month: "Jun", progress: 0 },
        { month: "Jul", progress: 0 },
        { month: "Aug", progress: 0 },
        { month: "Sep", progress: 0 },
        { month: "Oct", progress: 0 },
        { month: "Nov", progress: 0 },
        { month: "Dec", progress: 0 },
      ],
      []
    )
  );

  useEffect(() => {
    const calculateMonthlyProgress = (reports, year) => {
      const reportCountByMonth = Array(12).fill(0); // Array to hold report counts for each month

      reports.forEach((report) => {
        const reportDate = new Date(report.created_on);
        const reportYear = reportDate.getFullYear();
        const reportMonth = reportDate.getMonth(); // Get the month (0-11)

        if (reportYear === year) {
          reportCountByMonth[reportMonth] += 1;
        }
      });

      const totalReports = reports.length || 1; // Prevent division by zero
      const calculatedMonthData = monthData.map((data, index) => ({
        ...data,
        progress: (reportCountByMonth[index] / totalReports) * 100, // Calculate percentage
      }));

      setMonthData(calculatedMonthData); // Update state with new month data
    };

    const userId = user?.user_id;
    if (userId) {
      getUserById(userId)
        .then((response) => {
          setUser(response.user); // Update state with user data
          const currentYear = new Date().getFullYear();
          calculateMonthlyProgress(response.user?.reports || [], currentYear);
        })
        .catch((error) => {
          console.error("Error fetching user:", error);
        });
    }
  }, [user?.user_id, monthData]);

  const calculateAge = (dob) => {
    if (!dob) return "-"; // Handle cases where dob is missing
    const birthDate = new Date(dob);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    // Adjust age if the birthday hasn't occurred yet this year
    if (
      monthDifference < 0 ||
      (monthDifference === 0 && today.getDate() < birthDate.getDate())
    ) {
      age--;
    }
    return age;
  };

  const calculateReportCounts = (reports) => {
    let totalReports = reports.length;
    let draftReports = reports.filter(
      (report) => report.status === "Draft"
    ).length;
    let finalizedReports = reports.filter(
      (report) => report.status === "Finalized"
    ).length;

    return { totalReports, draftReports, finalizedReports };
  };

  const { totalReports, draftReports, finalizedReports } =
    calculateReportCounts(user?.reports || []);

  const handleOpenDialog = () => {
    // Set the dialog title and content dynamically based on form data
    setDialogTitle("Sure You Want To Delete Account?");
    setDialogContent(
      "The account would be deleted permanently. Are you sure you want to proceed?"
    );
    setButtonLabel("Yes");
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    try {
      // Pass the email to deleteUser API
      deleteUser(user.email);
      // Optionally, refresh the user list or remove the deleted user from the list
      navigate("/user-management", { state: { fromEditUserPage: true } });
    } catch (error) {
      console.error("Error deleting user:", error);
    }
    setDialogOpen(false);
  };

  return (
    <div className="flex flex-col">
      <div className="flex justify-end px-6 mt-6">
        <div className="flex space-x-4">
          <button
            type="button"
            className="flex bg-blue p-2 px-3 text-sm font-semibold text-white rounded-lg"
            onClick={() =>
              navigate(`/edit-user/${user.user_id}`, { state: { user } })
            }
          >
            <EditIcon className="mt-0.5 mr-2" />
            Edit
          </button>
          <button
            type="button"
            className="flex bg-error p-2 px-3 text-sm font-semibold text-white rounded-lg"
            onClick={handleOpenDialog}
          >
            <DeleteIcon className="mt-0.5 mr-2 text-white" />
            Delete
          </button>
        </div>
      </div>
      <div
        className="flex xxs:flex-col sm:flex-row xxs:space-y-4 sm:space-y-0 sm:space-x-6 bg-[var(--color-white)] p-8 rounded-lg m-6"
        style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
      >
        <div>
          <img
            src={
              user.profile_photo
                ? user.profile_photo // If the user has an avatar, use it
                : user.gender === "Female" // If gender is female, use a default female avatar
                ? femaleImg
                : maleImg // Otherwise, use a default male avatar
            }
            alt="User"
            className="w-52 h-52"
          />
        </div>
        <div className="flex flex-col space-y-2 items-start text-[var(--color-text1)] sm:text-lg">
          <div>Name: {user?.name ? user?.name : "--"}</div>
          <div>
            Role: {user?.role === "RADIOLOGIST" ? "Radiologist" : "Lab Admin"}
          </div>
          <div>Age: {user?.dob ? `${calculateAge(user?.dob)} yrs` : "--"}</div>
          <div>Contact: {user?.contact_no}</div>
          <div>Email Id: {user?.email}</div>
          <div>
            Location: {user?.address ? user?.address + ", " : ""}{" "}
            {user?.country ? user?.country : "--"}
          </div>
        </div>
      </div>
      <div
        className="bg-[var(--color-white)] p-6 rounded-lg m-6 mt-0"
        style={{ boxShadow: "0px 4px 16px 0px #00000029" }}
      >
        <div className="flex flex-col space-y-1">
          <span className="text-lg">Reports Created</span>
          <span className="text-blue-text">Monthly Report</span>
        </div>
        <div className="flex xxs:flex-col md:flex-row xxs:space-y-4 md:space-y-0 md:items-end">
          <div className="flex flex-col items-start w-2/3">
            <div className="text-[var(--color-dark-grey)] space-x-2 mt-4">
              <span className="large-text font-semibold">{totalReports}</span>
              <span>Reports</span>
            </div>
            <div className="flex xxs:space-x-4 md:space-x-8 mt-4">
              <div className="flex items-center">
                <div className="bg-blue-light p-2 rounded-full">
                  <DraftsIcon className="w-6 h-6" />
                </div>
                <div className="ml-3">
                  <p className="text-base">Drafts</p>
                  <div className="flex space-x-2">
                    <span className="text-blue2 font-semibold">
                      {draftReports}
                    </span>
                    <span className="text-grey"> Drafts</span>
                  </div>
                </div>
              </div>

              <div className="flex items-center">
                <div className="bg-error-light p-2 rounded-full">
                  <FinalizedIcon className="w-6 h-6" />
                </div>
                <div className="ml-3">
                  <p className="text-base">Finalized</p>
                  <div className="flex space-x-2">
                    <span className="text-error2 font-semibold">
                      {finalizedReports}
                    </span>
                    <span className="text-grey"> Finalized</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-end xxs:space-y-4 md:space-y-0 sm:space-x-4 xl:space-x-8">
            {monthData.map((data, index) => (
              <div key={index} className="flex flex-col items-center">
                <Progress.Line
                  vertical
                  percent={data.progress}
                  showInfo={false}
                  strokeColor="#28C76F" // Customize color if needed
                  style={{ height: "190px", width: "20px" }}
                  className="custom-progress-line"
                />
                <span className="mt-1 text-xs text-[var(--color-dark-grey)]">
                  {data.month}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <CommonDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        title={dialogTitle}
        content={dialogContent}
        buttonLabel={buttonLabel}
        showDeleteIcon={true}
        onConfirm={handleConfirm}
      />
    </div>
  );
}

export default UserDetailsPage;
